import { createSlice } from '@reduxjs/toolkit'

interface CompanyPhotoState {
	isLoading: boolean
	error: string
	modalActive: boolean
	logoModal: boolean
	refresh: boolean
}

const initialState: CompanyPhotoState = {
	isLoading: false,
	error: '',
	modalActive: false,
	logoModal: false,
	refresh: false,
}

export const CompanyPhotoSlice = createSlice({
	name: 'companyPhoto',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
			state.refresh = !state.refresh
		},
		openLogoModal: (state) => {
			state.logoModal = true
		},
		closeLogoModal: (state) => {
			state.refresh = !state.refresh
			state.logoModal = false
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, openLogoModal, closeLogoModal, setRefresh } = CompanyPhotoSlice.actions

export default CompanyPhotoSlice.reducer
