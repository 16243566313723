import { OrderCollectingStatus } from '../../_theme/helpers/enums/OrderStatus'

export const OrdersApi = (instance: any) => ({
	async getAllOrders(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/order/all?limit=${limit}&page=${page}&sortType=DESC&paid_status=paid&select=organization,organization_filial,delivery_companies,main_order,owner,user,manager,photo,worker_transaction_files,delivery_transaction_files${q}`)
		return data
	},
	async getAllOrganization(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(
			`/order/organization/all?limit=${limit}&page=${page}&sortType=DESC&paid_status=paid&select=organization,organization_filial,address,beginner_filial,last_filial,qr_code,delivery_companies,order_products,main_order,current_filial,current_courier,courier_delivered_orders,owner,product,specifications,discount,user,manager,worker_transaction_files,delivery_transaction_files${q}`
		)
		return data
	},
	async getAllOrganizationFilial(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(
			`/order/organization-filial/all?limit=${limit}&page=${page}&sortType=DESC&paid_status=paid&select=organization,organization_filial,address,beginner_filial,last_filial,qr_code,delivery_companies,order_products,main_order,current_filial,current_courier,courier_delivered_orders,owner,product,specifications,discount,user,manager,worker_transaction_files,delivery_transaction_files${q}`
		)
		return data
	},
	async getAllDelivery(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(
			`/order/delivery-company/all?limit=${limit}&page=${page}&sortType=DESC&paid_status=paid&select=organization,organization_filial,address,beginner_filial,last_filial,qr_code,delivery_companies,order_products,main_order,current_filial,current_courier,courier_delivered_orders,owner,product,specifications,discount,user,manager,worker_transaction_files,delivery_transaction_files${q}`
		)
		return data
	},
	async getAllDeliveryFilial(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(
			`order/delivery-filial/all?limit=${limit}&page=${page}&sortType=DESC&paid_status=paid&select=organization,organization_filial,address,beginner_filial,last_filial,qr_code,delivery_companies,order_products,main_order,current_filial,current_courier,courier_delivered_orders,owner,product,specifications,discount,user,manager,worker_transaction_files,delivery_transaction_files`
		)
		return data
	},
	async changeCollectingStatus(id: number | string, body: { collecting_status: OrderCollectingStatus }) {
		const { data } = await instance.patch(`/order/collecting-status/${id}`, body)
		return data
	},
	async getOneOrder(id: number | string) {
		const { data } = await instance.get(
			`/order/one?order_id=${id}&select=organization,organization_filial,address,org_filial_address,beginner_filial,last_filial,qr_code,delivery_companies,order_products,main_order,current_filial,current_courier,courier_delivered_orders,owner,product,specifications,discount,user,manager,photo,worker_transaction_files,delivery_transaction_files,country,city,village,district,region,main_picture,country2,city2,village2`
		)
		return data
	},
	async assignCourier(orderId: string | number, body: { courier_id: string | number }) {
		const { data } = await instance.post(`/order/courier/assign/${orderId}`, body)
		return data
	},
	async assignCourierForRefunds(orderId: string | number, body: { courier_id: string | number }) {
		const { data } = await instance.post(`/refund/assign-courier/${orderId}`, body)
		return data
	},
	async getAllHistoryFilialOrders(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/order-delivery-filial-history/all?limit=${limit}&page=${page}&sortType=DESC&select=visited_filial,delivery_company,order${q}`)
		return data
	},
	async getAllHistoryOrders(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/courier-delivered-order/all?limit=${limit}&page=${page}&sortType=DESC&select=order,photo,courier${q}`)
		return data
	},
})
