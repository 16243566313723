import Multiselect from 'multiselect-react-dropdown'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { useDispatch } from 'react-redux'
import { setAddProduct } from '../reducers/ProductSlice'
import clsx from 'clsx'
import useTranslate from '../../../../_theme/helpers/translate'

const DeliveryPaymant = () => {
	const translate = useTranslate()
	const { addProduct } = useAppSelector((state) => state.product)
	const dispatch = useDispatch()
	const handleSelectPeymentLimit = (e: any) => {
		dispatch(setAddProduct({ ...addProduct, limit_to_payment_delivery: e.target.value }))
	}
	const handleSelectPeymentBy = (e: any) => {
		dispatch(setAddProduct({ ...addProduct, delivery_payment_by: e.target.value }))
	}

	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.DELIVERY_PAYMENT')}</h2>
				</div>
			</div>

			<div className='card-body pt-0 scroll-x'>
				<label className='form-label'>{translate('CARD.TEXT.DDELIVERY_PAYMENT_BY')}</label>
				<select defaultValue={addProduct?.delivery_payment_by} className='form-select form-select-solid' data-control='select2' data-hide-search='true' onChange={handleSelectPeymentBy} data-placeholder='Select status' name='status'>
					<option hidden>Select</option>
					<option value={'organization'}>{translate('CARD.TEXT.ORGANIZATION')}</option>
					<option value={'user'}>{translate('CARD.TEXT.USER')}</option>
					<option value={'limited'}>{translate('CARD.TEXT.LIMITED')}</option>
				</select>
			</div>

			{addProduct.delivery_payment_by == 'limited' && (
				<div className='card-body pt-0 scroll-x'>
					<label className='form-label'>{translate('CARD.TEXT.LIMIT_TO_PAYMENT')} </label>
					<input defaultValue={addProduct?.limit_to_payment_delivery} placeholder='Limit To Payment' onChange={handleSelectPeymentLimit} type='number' name='limit_to_payment_delivery' className={clsx('form-control form-control-solid mb-3 mb-lg-0')} autoComplete='off' />
				</div>
			)}
		</div>
	)
}

export default DeliveryPaymant
