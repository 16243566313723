import React, { useEffect, useState } from 'react'
import { Api } from '../../Services/api'
import AddRefundType from './modals/AddRefundType'
import { useAppSelector } from '../../../setup/hooks/redux'
import RefundTypeItem from './components/RefundTypeItem'
import IRefundType from './models/RefundTypeModel'
import { SearchComponent } from '../../components/SearchBox'
import Pagination from '../../components/Pagination'
import { Spinner } from 'react-bootstrap'
import useTranslate from '../../../_theme/helpers/translate'

const RefundType = () => {
	const { refresh } = useAppSelector((state) => state.refresh)
	const translate = useTranslate()
	//States
	const [refundType, setRefundType] = useState<IRefundType[]>([])
	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [limit, setLimit] = useState(20)
	const [total, setToltal] = useState(1)
	const [searchKeyClick, setSearchKeyClick] = useState('')
	const [statusSelect, setStatusSelect] = useState('')
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	useEffect(() => {
		setLoading(true)
		Api()
			.refund.getAllRefundType(limit, currentPage, ``)
			.then((res) => {
				setRefundType(res?.items)
				setToltal(res?.total_count)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => setLoading(false))
		return () => {}
	}, [refresh, currentPage, searchKeyClick, statusSelect])
	return (
		<div>
			{showModal && <AddRefundType update={false} isOpen={showModal} onClose={handleCloseModal} />}
			<div className='card card-flush'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					{/* <div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>

							<SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Search'} />
						</div>
					</div> */}

					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						{/* <div className='w-100 mw-150px'>
							<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Status' data-kt-ecommerce-product-filter='status'>
								<option disabled>Status</option>
								<option value='all'>All</option>
								<option value='published'>{translate('CARD.TEXT.PUBLISHED')}</option>
								<option value='scheduled'>Scheduled</option>
								<option value='inactive'>{translate('CARD.TEXT.INACTIVE')}</option>
							</select>
						</div> */}

						<div onClick={handleOpenModal} className='btn btn-primary'>
							{translate('CARD.TEXT.ADD_REFUND_TYPE')}
						</div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px  scroll-x'>
					{loading ? (
						<div className='text-center'>
							<Spinner className='p-5 text-primary' />
						</div>
					) : (
						<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
							<thead>
								<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
									<th className='min-w-200px'>{translate('TABLE.TITLE.REFUND_TYPE')}</th>
									{/* <th className='text-end min-w-100px'>{translate('TABLE.TITLE.STATUS')}</th> */}
									<th className='text-end min-w-70px'>{translate('TABLE.TITLE.ACTION')}</th>
								</tr>
							</thead>
							<tbody className='fw-bold text-gray-600'>
								{refundType?.map((item, index) => {
									return <RefundTypeItem item={item} key={index} />
								})}
							</tbody>
						</table>
					)}
					<Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} />
				</div>
			</div>
		</div>
	)
}

export default RefundType
