import { createSlice } from '@reduxjs/toolkit'
import { IRequestModel } from '../model/RequestModel'

interface RefundsState {
	refunds: IRequestModel[]
	refund: IRequestModel
	total: number
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
}

const initialState: RefundsState = {
	refunds: [],
	refund: {} as IRequestModel,
	total: 0,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
}

export const RefundsSlice = createSlice({
	name: 'refunds',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setRefunds: (state, action) => {
			state.refunds = action.payload.data
			state.total = action.payload.total
		},
		setRefund: (state, action) => {
			state.refund = action.payload
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setRefunds, setRefresh, setRefund } = RefundsSlice.actions

export default RefundsSlice.reducer
