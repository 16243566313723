import React, { FC, useEffect, useState } from 'react'
import AddCourier from '../../../Modals/AddCourier'
import CourierItem from '../../../components/CouirerItem'
import IDeliveryBranch from '../../../models/DeliveryBranchModel'
import useTranslate from '../../../../_theme/helpers/translate'

type Props = {
	branch: IDeliveryBranch
}

const Couriers: FC<Props> = ({ branch }) => {
	const translate = useTranslate()
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)
	const [couriers, setCouriers] = useState(branch?.couriers)

	useEffect(() => {
		setCouriers(branch?.couriers)
	}, [branch])

	return (
		<div>
			<div className='card card-flush mb-6 mb-xl-9'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					<div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>

							<input type='text' data-kt-ecommerce-product-filter='search' className='form-control form-control-solid w-250px ps-14' placeholder='Search deliveryFilials' />
						</div>
					</div>

					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						<div className='w-100 mw-150px'>
							<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Status' data-kt-ecommerce-product-filter='status'>
								<option disabled>{translate('CARD.TEXT.STATUS')}</option>
								<option value='all'>All</option>
								<option value='published'>Active</option>
								<option value='scheduled'>Passive</option>
							</select>
						</div>

						<div onClick={handleOpenModal} className='btn btn-primary'>
							Add Couriers
						</div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px  scroll-x'>
					<table className='table align-middle table-row-dashed fs-6 gy-5'>
						<thead>
							<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
								<th className='min-w-70px'>{translate('TABLE.TITLE.FULL_NAME')}</th>
								<th className='min-w-70px'>{translate('TABLE.TITLE.EMAIL')}</th>
								<th className='min-w-70px'>{translate('TABLE.TITLE.PHONE')}</th>
								{/* <th className='min-w-70px'>{translate('TABLE.TITLE.STATUS')}</th> */}
							</tr>
						</thead>
						<tbody className=''>
							{couriers?.map((item: any) => {
								return <CourierItem item={item} />
							})}
						</tbody>
					</table>
				</div>
			</div>
			<AddCourier update={false} isOpen={showModal} onClose={handleCloseModal} filialId={branch?.id} />
		</div>
	)
}

export default Couriers
