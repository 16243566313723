import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { KTSVG, fileIconName } from '../../../../_theme/helpers'
import { Api } from '../../../Services/api'
import Swal from 'sweetalert2'
import { setRefresh } from '../reducers/CompanySlice'
import { Dropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import WSPGalleryDocs from './Photo/WSPGallery'
import { FileItem } from '../../../components/fileItem'
import { IDocument } from '../../organization/models/OrganizationModel'
import useTranslate from '../../../../_theme/helpers/translate'

const UpdatedCompany = () => {
	const translate = useTranslate()
	const { company } = useAppSelector((state) => state.company)
	const dispatch = useDispatch()
	const [photos, setPhotos] = useState<IDocument[]>([])
	const [docs, setDocs] = useState<IDocument[]>([])
	useEffect(() => {
		setDocs(company?.documents?.filter((item) => fileIconName(item.url) == 'pdf' || fileIconName(item.url) == 'docx'))
		setPhotos(company?.documents?.filter((item) => fileIconName(item.url) != 'pdf' && fileIconName(item.url) != 'docx'))
	}, [company])
	function accept() {
		Swal.fire({
			title: 'Will you accept!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes',
		}).then((result) => {
			if (result.isConfirmed) {
				Api()
					.organization.accept(company.id)
					.then((res) => {
						dispatch(setRefresh())
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: 'Login information sent by e-mail',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			}
		})
	}
	function reject() {
		Swal.fire({
			title: "Won't you accept this company",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes',
		}).then((result) => {
			if (result.isConfirmed) {
				Api()
					.organization.reject(company.id)
					.then((res) => {
						dispatch(setRefresh())
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: 'Company canceled',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			}
		})
	}

	return (
		<div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
			<div className='card-header cursor-pointer'>
				<div className='card-title m-0'>
					<h3 className='fw-bolder m-0'>{translate('CARD.TEXT.DETAILS')}</h3>
				</div>
				<div className='d-flex align-items-center'>
					<Link to='../setting' className='btn btn-primary align-self-center mr-2'>
						{translate('TABLE.TITLE.ACTION')}
					</Link>
					<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						<Dropdown.Toggle disabled={company.approve_status != 'pending'} variant='primary' id='dropdown-basic'>
							{translate('TABLE.TITLE.ACTION')}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item>
								{' '}
								<div className='menu-item px-3'>
									<div onClick={() => accept()} className='menu-link px-3'>
										{translate('STATUS.TEXT.ACCEPT')}
									</div>
								</div>
							</Dropdown.Item>
							<Dropdown.Item>
								<div className='menu-item px-3'>
									<div onClick={() => reject()} className='menu-link px-3'>
										{translate('STATUS.TEXT.REJECT')}
									</div>
								</div>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>

			<div className='card-body p-9'>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.FULL_NAME')}</label>

					<div className='col-lg-8'>
						<span className='fw-bolder fs-6 text-dark'>
							{company?.owner?.name} {company?.owner?.surname}
						</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.COMPANY_NAME')}</label>

					<div className='col-lg-8 fv-row'>
						<span className='fw-bold fs-6'>{company?.name}</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.COMPANY_DESCRIPTION')}</label>

					<div className='col-lg-8 fv-row'>
						<span className='fw-bold fs-6'>{company?.description}</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>
						{translate('CARD.TEXT.CONTACT_PHONE')}
						<i className='fas fa-exclamation-circle ms-1 fs-7' data-bs-toggle='tooltip' title='Phone number must be active'></i>
					</label>

					<div className='col-lg-8 d-flex align-items-center'>
						<span className='fw-bolder fs-6 me-2'>{company?.owner?.phone}</span>

						{/* <span className='badge badge-success'>Verified</span> */}
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>
						{translate('CARD.TEXT.CONTACT_EMAIL')}
						<i className='fas fa-exclamation-circle ms-1 fs-7' data-bs-toggle='tooltip' title='Phone number must be active'></i>
					</label>

					<div className='col-lg-8 d-flex align-items-center'>
						<span className='fw-bolder fs-6 me-2'>{company?.owner?.email}</span>

						{/* <span className='badge badge-success'>Verified</span> */}
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.TAX_NUMBER')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{company?.tax_number}</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.TAX_SERVICE_ADDRESS')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{company?.tax_service_address}</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.FAX_NUMBER')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{company?.fax_number}</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.BIK')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{company?.bik}</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.SETTLEMENT_ACCOUNT')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{company?.settlement_account}</span>
					</div>
				</div>

				{/* <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Country
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>Turkey</span>
          </div>
        </div> */}

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.COMMUNICATION')}</label>

					<div className='col-lg-8'>
						<span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
					</div>
				</div>

				<div className='row mb-10'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.STATUS')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>
							{translate(`STATUS.TEXT.${company?.approve_status?.toUpperCase()}`)}
							{}
						</span>
					</div>
				</div>

				{/* <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
          <KTSVG
            path='icons/duotune/general/gen044.svg'
            className='svg-icon-2tx svg-icon-warning me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
              <div className='fs-6 text-gray-600'>
                Your payment was declined. To start using tools, please
                <Link className='fw-bolder' to='/crafted/account/settings'>
                  {' '}
                  Add Payment Method
                </Link>
                .
              </div>
            </div>
          </div>
        </div> */}
			</div>
			<div className='row px-4'>{photos?.length > 0 && <WSPGalleryDocs galleryImages={photos} />}</div>
			<div className='overflow-auto pb-5'>
				<div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-200px p-7  scroll-x'>
					{docs?.map((item) => (
						<FileItem key={item.key} fileLink={item.url} name={item.url} size={''} />
					))}
				</div>
			</div>
		</div>
	)
}

export default UpdatedCompany
