export const StatisticsApi = (instance: any) => ({
	async getMonthlyOrder(year: string, month: number | string, queryType: string) {
		const { data } = await instance.get(`/order/statistics/monthly?year=${year}&month=${month}&queryType=${queryType}`)
		return data
	},
	async getYearlyOrder(year: string, queryType: string) {
		const { data } = await instance.get(`/order/statistics/yearly?year=${year}&queryType=${queryType}`)
		return data
	},
	async getMonthlyUser(year: string, month: number | string) {
		const { data } = await instance.get(`/user/statistics/monthly?year=${year}&month=${month}`)
		return data
	},
	async getYearlyUser(year: string) {
		const { data } = await instance.get(`/user/statistics/yearly?year=${year}`)
		return data
	},
	async getMonthlyOrganization(year: string, month: number | string) {
		const { data } = await instance.get(`/organization/statistics/monthly?year=${year}&month=${month}`)
		return data
	},
	async getYearlyOrganization(year: string) {
		const { data } = await instance.get(`/organization/statistics/yearly?year=${year}`)
		return data
	},
})
