import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_theme/layout/core'
import AddProduct from './AddProduct'
import Products from './Products'
import useTranslate from '../../../_theme/helpers/translate'

const ProductsPageWrapper: React.FC<{ children?: React.ReactNode }> = () => {
	const translate = useTranslate()
	return (
		<Routes>
			<Route element={<Outlet />}>
				<Route
					path='products'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.PRODUCTS')}</PageTitle>
							<Products />
						</>
					}
				/>

				<Route
					path='add-product'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.ADD_PRODUCTS')}</PageTitle>
							<AddProduct />
						</>
					}
				/>
				<Route index element={<Navigate to='products' />} />
			</Route>
		</Routes>
	)
}

export { ProductsPageWrapper }
