import React, { useCallback, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { useDispatch } from 'react-redux'
import { setAddProduct } from '../../../reducers/ProductSlice'
import { Debounce } from '../../../../../../_theme/helpers'
import useTranslate from '../../../../../../_theme/helpers/translate'

function ProductName() {
	const translate = useTranslate()
	const { addProduct } = useAppSelector((state) => state.product)
	const dispatch = useDispatch()

	const changeHandlerName = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, productName: e.target.value }))
		// dispatch(setProductName(e.target.value))
	}, 100)
	const changeHandlerDesc = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, productDesc: e.target?.getContent() }))
		// dispatch(setProductDesc(e.target.value))
	}, 100)

	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.GENERAL')}</h2>
				</div>
			</div>

			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className='mb-10 fv-row'>
					<label className='required form-label'>{translate('CARD.TEXT.PRODUCT_NAME')}</label>

					<input type='text' name='product_name' onChange={changeHandlerName} className='form-control mb-2' placeholder='Product name' defaultValue={addProduct?.productName} />

					<div className='text-muted fs-7'>{translate('CARD.TEXT.A_PRODUCT_NAME_IS_REQUIRED_AND_RECOMMENDED_TO_BE_UNIQUE')}</div>
				</div>

				<div>
					<label className='form-label'>{translate('FORM.INPUT.DESCRIPTION')}</label>

					<Editor
						apiKey='m7hjq6tixbvp8xn47kzu9axu0z02l911qmkhu1mxvanxtxki'
						initialValue={addProduct.productDesc}
						init={{
							branding: false,
							height: 400,
							menubar: true,
							plugins: 'print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern',
							toolbar: 'formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
							image_advtab: true,
						}}
						onChange={changeHandlerDesc}
					/>
					<div className='text-muted fs-7'>{translate('CARD.TEXT.LIMITEDSET_A_DESCRIPTION_TO_THE_PRODUCT_FOR_BETTER_VISIBILITY')}</div>
				</div>
			</div>
		</div>
	)
}

export default ProductName
