export enum OrderProceedStatus {
	ACTIVE = 'active',
	PENDING = 'pending',
	PAID = 'paid',
	REJECTED = 'rejected',
	CANCELLED = 'cancelled',
}

export enum OrderCollectingStatus {
	PENDING = 'pending',
	COLLECTING = 'collecting',
	READY = 'ready',
	CANCELLED = 'cancelled',
}

export enum OrderDeliveryStatus {
	PENDING = 'pending',
	DELIVERED = 'delivered',
	ON_WAY = 'on_way',
	IN_FILIAL = 'in_filial',
}

export enum OrderPaidStatus {
	PENDING = 'pending',
	PAID = 'paid',
}
