import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import Resizer from 'react-image-file-resizer'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { ModalHeader } from '../../../../../Modals/ModalHeader'
import { Api } from '../../../../../Services/api'
import { closeModal } from '../reducers/PhotoSlice'
import { setRefresh } from '../../../../../reducers/RefreshSlice'
import useTranslate from '../../../../../../_theme/helpers/translate'

export const AddPhoto = () => {
	const [errors, setErrors] = useState('')
	const translate = useTranslate()
	const [loading, setloadin] = useState(false)
	const [showFile, setShowFile] = useState<any>([])
	const dispatch = useDispatch()
	const { modalActive } = useAppSelector((state) => state.prdPhotos)
	const [userForEdit] = useState<any>({})
	const { product } = useAppSelector((state) => state.product)
	const [resizeImgs, setResizeImgs] = useState<any>([])
	const removeFile = (f: any) => () => {
		const newFiles = [...showFile]
		newFiles.splice(newFiles.indexOf(f), 1)
		setShowFile(newFiles)
	}

	const filess = showFile.map(
		(
			file: {
				path: {} | null | undefined
				size: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
			},
			index: React.Key | null | undefined
		) => (
			<li key={index} className='d-flex justify-content-between m-5'>
				<span className='w-50 ml-10'>
					<>
						{file.path} - {file.size} bytes
					</>{' '}
				</span>
				<span className='btn btn-primary mr-10' style={{ color: 'white' }} onClick={removeFile(file)}>
					Sil
				</span>
			</li>
		)
	)

	useEffect(() => {
		setShowFile([])
	}, [modalActive])

	const imageResize = async (file: any) => {
		var fileInput = false
		if (file) {
			fileInput = true
		}
		//dfkl
		if (fileInput) {
			await Resizer.imageFileResizer(
				file,
				720,
				500,
				'WEBP',
				100,
				0,
				async (uri: any) => {
					setResizeImgs((prev: any) => [...prev, uri])
					// formData.append('files', uri)
				},
				'file'
			)
		}
	}

	const uplodaToServer = async () => {
		let formData = new FormData()
		for (let i = 0; i < resizeImgs.length; i++) {
			formData.append('files', resizeImgs[i])
		}
		await Api()
			.products.imagesUpload(formData)
			.then(async (res) => {
				const body = { child_pictures_ids: [...product?.child_pictures?.map(({ id }) => id), ...res?.map(({ id }: any) => id)] }
				await Api()
					.products.updateProduct(product?.id, body)
					.then((res) => {
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: res?.message,
							showConfirmButton: false,
							timer: 1500,
						})
						dispatch(setRefresh())
						dispatch(closeModal())
						setloadin(false)
					})
					.catch((err) => {
						setloadin(false)
						Swal.fire({
							icon: 'error',
							title: err?.response?.data?.statusCode,
							text: err?.response?.data?.message,
						})
						console.log(err)
					})
			})
			.catch((err) => {
				setloadin(false)
				Swal.fire({
					icon: 'error',
					title: err?.response?.data?.statusCode,
					text: err?.response?.data?.message,
				})
				console.log(err)
			})
	}

	useEffect(() => {
		if (resizeImgs.length > 0 && showFile.length === resizeImgs.length) uplodaToServer()
	}, [resizeImgs])

	const formik = useFormik({
		initialValues: userForEdit,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				// setloadin(true)

				for (let i = 0; i < showFile.length; i++) {
					await imageResize(showFile[i])
				}
				// console.log(resizeImgs)
			} catch (ex) {
				setloadin(false)
			}
		},
	})
	return (
		<>
			<Modal show={modalActive} onHide={() => dispatch(closeModal())}>
				<Modal.Body>
					<ModalHeader titleHeader={'Resim Ekle'} handleClose={() => dispatch(closeModal())} />
					<form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
						{/* begin::Scroll */}
						<div className='d-flex flex-column scroll-y me-n7 pe-7 ' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
							<div className='fv-row mb-8'>
								<label className='fs-7 fw-bold mb-2'>Select Pictures </label>

								<div style={{ display: 'block' }} className='fv-row mb-8'>
									<div className='dropzone' id='kt_modal_create_ticket_attachments'>
										<div className='dz-message needsclick align-items-center'>
											<span className='svg-icon svg-icon-3hx svg-icon-primary' style={{ color: 'rgb(1 13 88)' }}>
												<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
													<path opacity='0.3' d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z' fill='currentColor' />
													<path d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z' fill='currentColor' />
													<path d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z' fill='currentColor' />
													<path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
												</svg>
											</span>
											{/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}

											<Dropzone
												accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
												multiple={true}
												maxFiles={30}
												onDrop={async (acceptedFiles, fileRejections) => {
													setShowFile(acceptedFiles)
													//setFiles(acceptedFile)
													fileRejections.forEach((file) => {
														file.errors.forEach((err) => {
															if (err.code === 'file-too-large') {
																setErrors(`Hata: ${err.message}`)
															}

															if (err.code === 'file-invalid-type') {
																setErrors(`Hata: ${err.message}`)
															} else {
																setErrors(`Hata: ${err.message}`)
															}
														})
													})
												}}
											>
												{({ getRootProps, getInputProps }) => (
													<section>
														<div {...getRootProps()}>
															<input {...getInputProps()} />
															<div className='ms-4'>
																<h3 className='fs-5 fw-bolder text-gray-900 mb-1'>Select Pictures</h3>
																<span className='fw-bold fs-7 text-gray-400'>You must add at least 1 images</span>
															</div>
															<p style={{ color: 'red', padding: 5, margin: 0, fontSize: 14 }}>{}</p>
														</div>
													</section>
												)}
											</Dropzone>
										</div>
									</div>
								</div>
							</div>
							<aside>
								<ul className='m-0 p-0'>{filess}</ul>
							</aside>
							<div className='text-center pt-15'>
								<div onClick={() => dispatch(closeModal())} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
									{translate('FORM.BUTTON.CENCEL')}
								</div>

								<button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={loading || formik.isSubmitting}>
									{!loading || !formik.isSubmitting ? (
										<span className='indicator-label'>{translate('FORM.BUTTON.SEND')}</span>
									) : (
										<span className='indicator-progress' style={{ display: 'block' }}>
											Loading... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									)}
								</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	)
}
