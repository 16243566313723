import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../setup/hooks/redux'
import Pricing from '../../../products/components/General/components/Pricing'
import ProductName from '../../../products/components/General/components/ProductName'
import { setAddProduct } from '../../../products/reducers/ProductSlice'
import { useEffect, useState } from 'react'
import PricingProduct from './components/Pricing'
import Inventory from '../../../products/components/Advanced/components/Inventory'
import MetaOptions from '../../../products/components/Advanced/components/MetaOptions'
import Shipping from '../../../products/components/Advanced/components/Shipping'
import Variations from '../../../products/components/Advanced/components/Variations'
import { Api } from '../../../../Services/api'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import ProductDetails from '../../../products/components/ProductDetails'
import ProductRelations from '../../../products/components/ProductRelations'
import ProductUsedCountries from '../../../products/components/ProductUsedCountries'
import { setRefresh } from '../../../../reducers/RefreshSlice'
import DeliveryPaymant from '../../../products/components/DeliveryPaymant'

export function UpdateProduct() {
	const { addProduct, product } = useAppSelector((state) => state.product)
	const [loading, setIsloading] = useState(true)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	console.log(product)

	useEffect(() => {
		setTimeout(() => {
			setIsloading(false)
		}, 1000)
		dispatch(
			setAddProduct({
				...addProduct,
				productName: product.title,
				productDesc: product.description,
				basePrice: product.base_price,
				article: product?.article,
				is_reproducible: product?.is_reproducible,
				is_wholesale: product?.is_wholesale,
				can_refund: product?.can_refund,
				selectedCategories: product.categories,
				selectBrand: [product?.brand],
				relations: product?.relations,
				limit_to_payment_delivery: product.limit_to_payment_delivery,
				delivery_payment_by: product?.delivery_payment_by,
			})
		)
	}, [product])

	const submit = () => {
		const body = {
			title: addProduct.productName,
			description: addProduct?.productDesc,
			base_price: addProduct.basePrice,
			article: addProduct?.article,
			category_ids: addProduct.selectedCategories.map(({ id }: any) => id),
			relation_ids: addProduct.relations.map(({ id }: any) => id),
			is_reproducible: addProduct?.is_reproducible,
			can_refund: addProduct?.can_refund,
			is_wholesale: addProduct?.is_wholesale,
			delivery_payment_by: addProduct.delivery_payment_by,
			limit_to_payment_delivery: addProduct.delivery_payment_by == 'limited' ? addProduct.limit_to_payment_delivery : 0,
			// countries_for_sale_ids: addProduct.counrtiesForSaleIds.map(({ id }: any) => id),
			brand_id: addProduct.selectBrand && addProduct.selectBrand[0]?.id,
			// size: {
			// 	length: addProduct.length,
			// 	width: addProduct.width,
			// 	height: addProduct.height,
			// 	weight: addProduct.weight,
			// },
			// discount:
			// 	addProduct.discountType !== '1'
			// 		? {
			// 				procent: addProduct.discount,
			// 				type: addProduct.discountType,
			// 				start_date: addProduct.discountStartDate,
			// 				end_date: addProduct.discountEndDate,
			// 		  }
			// 		: null,
			// metas: [
			// 	{
			// 		name: addProduct.metaName,
			// 		description: addProduct.metaDesc,
			// 	},
			// ],
		}
		Api()
			.products.updateProduct(product.id, body)
			.then((res) => {
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: res?.message,
					showConfirmButton: false,
					timer: 1500,
				})
				dispatch(setRefresh())
				navigate(-1)
			})
			.catch((err) => {
				Swal.fire({ title: 'Error', text: 'Error in Server', timer: 1500 })
			})
	}

	return (
		<>
			{' '}
			{!loading && (
				<div className='d-flex flex-column gap-7 gap-lg-10'>
					<ProductName />
					<PricingProduct />
					<Inventory />
					<div className='row'>
						<div className='col-12 col-md-6'>
							<ProductDetails />
						</div>
						<div className='col-12 col-md-6'>
							<DeliveryPaymant />
						</div>
					</div>
					<ProductRelations />

					{/* <Variations />
				<Shipping />
				<MetaOptions /> */}

					<div className='d-flex justify-content-center'>
						<div onClick={submit} className='btn btn-primary align-self-center'>
							Save Product
						</div>
					</div>
				</div>
			)}
		</>
	)
}
