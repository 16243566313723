import React, { useState, useEffect, FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../../setup/hooks/redux'
import { Api } from '../../Services/api'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import { Modal, Spinner } from 'react-bootstrap'
import { ModalHeader } from '../../Modals/ModalHeader'
import AddDelivery from './modals/AddDelivery'
import DeliveryItem from './components/DeliveryItem'
import { SearchComponent } from '../../components/SearchBox'
import Pagination from '../../components/Pagination'
import useTranslate from '../../../_theme/helpers/translate'

type Props = {
	req?: string
}

const DeliveryFilials: FC<Props> = ({ req = '' }) => {
	const translate = useTranslate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const { refresh } = useAppSelector((state) => state.refresh)

	// States
	const [deliveries, setDeliveries] = useState([])
	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [total, setToltal] = useState(1)
	const [searchKeyClick, setSearchKeyClick] = useState('')
	const [statusSelect, setStatusSelect] = useState('')

	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	useEffect(() => {
		setLoading(true)
		const q = user?.role === 'super_admin' ? '' : `&owner_id=${user?.id}`
		const search = searchKeyClick ? `&name=${searchKeyClick}` : ''

		Api()
			.deliveries.getAllFilial(limit, currentPage, `${search}${req}${statusSelect}`)
			.then((res) => {
				setDeliveries(res?.items)
				setToltal(res?.total_count)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => setLoading(false))

		return () => {}
	}, [refresh, currentPage, statusSelect, searchKeyClick])

	return (
		<div>
			<div className='card card-flush'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					<div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>

							<SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Search'} />
						</div>
					</div>

					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						<div className='w-100 mw-150px'>
							<select className='form-select form-select-solid' onChange={(e) => setStatusSelect(e.target.value)} data-control='select2' data-hide-search='true' data-placeholder='Status' data-kt-ecommerce-product-filter='status'>
								<option disabled>{translate('CARD.TEXT.STATUS')}</option>
								<option value=''>{translate('STATUS.TEXT.ALL')}</option>
								<option value='&status=active'>{translate('STATUS.TEXT.ACTIVE')}</option>
								<option value='&status=pending'>{translate('STATUS.TEXT.PENDING')}</option>
								<option value='&status=rejected'>{translate('STATUS.TEXT.REJECTED')}</option>
								<option value='&status=not_working'>{translate('STATUS.TEXT.NOT_WORKING')}</option>
							</select>
						</div>

						<div onClick={handleOpenModal} className='btn btn-primary'>
							{translate('CARD.TEXT.ADD_DELIVERY_FILIALS')}
						</div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px  scroll-x'>
					{loading ? (
						<div className='text-center'>
							<Spinner className='p-5 text-primary' />
						</div>
					) : (
						<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
							<thead>
								<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
									<th className='min-w-200px'>{translate('TABLE.TITLE.DELIVERY_FILIAL')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.DELIVERY_COMPANY')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.EMAIL')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.PHONE')}</th>
									<th>{translate('TABLE.TITLE.STATUS')}</th>
									<th className='text-end min-w-70px'>{translate('TABLE.TITLE.ACTION')}</th>
								</tr>
							</thead>
							<tbody className='fw-bold text-gray-600'>
								{deliveries?.map((item, index) => {
									return <DeliveryItem key={index} item={item} />
								})}
							</tbody>
						</table>
					)}
					<Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} />
				</div>
			</div>
			<Modal size={'lg'} show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={'New Delivery'} />
					<AddDelivery update={false} handleClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default DeliveryFilials
