import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestOneTimePassword, requestPassword } from '../redux/AuthCRUD'
import useTranslate from '../../../../_theme/helpers/translate'
import { successMsg } from '../../../../_theme/helpers/AlertMsg'

const initialValues = {
	email: '',
	one_time_password: '',
}

const forgotPasswordSchema = Yup.object().shape({
	email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
	one_time_password: Yup.string(),
})

export function ForgotPassword() {
	const translate = useTranslate()
	const [loading, setLoading] = useState(false)
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
	const [isCode, setIsCode] = useState(false)
	const navigate = useNavigate()

	const formik = useFormik({
		initialValues,
		validationSchema: forgotPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true)
			setHasErrors(undefined)
			if (!isCode)
				requestPassword(values.email)
					.then(({ data: { result } }) => {
						setHasErrors(false)
						setLoading(false)
						setIsCode(true)
						successMsg(translate('CARD.TEXT.SEND_ONE_TIME_PASSWORD'))
					})
					.catch((err) => {
						setHasErrors(true)
						setLoading(false)
						setSubmitting(false)
						setIsCode(false)
						setStatus(err?.response?.data?.message)
					})
			else
				requestOneTimePassword(values.email, values.one_time_password)
					.then((res: any) => {
						console.log(res)
						navigate(`/auth/new-password/${res?.data?.token}`)
						setHasErrors(false)
						setLoading(false)
					})
					.catch((err) => {
						setHasErrors(true)
						setLoading(false)
						setSubmitting(false)

						setStatus(err?.response?.data?.message)
					})
		},
	})
	const sendMail = () => {
		requestPassword(formik.values.email)
			.then((res: any) => {
				console.log(res)
				setHasErrors(false)
				setLoading(false)
				setIsCode(true)
			})
			.catch((err) => {
				setHasErrors(true)
				setLoading(false)
				formik.setSubmitting(false)
				setIsCode(false)
				formik.setStatus(err?.response?.data?.message)
			})
	}

	return (
		<>
			<form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_password_reset_form' onSubmit={formik.handleSubmit}>
				<div className='text-center mb-10'>
					{/* begin::Title */}
					<h1 className='text-dark mb-3'>{translate('AUTH.FORGOT.TITLE')}</h1>
					{/* end::Title */}

					{/* begin::Link */}
					<div className='text-gray-400 fw-bold fs-4'>{translate('AUTH.FORGOT.ENTER_EMAIL')}.</div>
					{/* end::Link */}
				</div>

				{/* begin::Title */}
				{hasErrors === true && (
					<div className='mb-lg-15 alert alert-danger'>
						<div className='alert-text font-weight-bold'>{translate('AUTH.FORGOT.ERROR')} </div>
					</div>
				)}
				{/* end::Title */}

				{/* begin::Form group */}

				<div className='fv-row mb-10'>
					<label className='form-label fw-bolder text-gray-900 fs-6'>{translate('FORM.INPUT.EMAIL')}</label>
					<input
						type='email'
						placeholder=''
						autoComplete='off'
						{...formik.getFieldProps('email')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.email && formik.errors.email },
							{
								'is-valid': formik.touched.email && !formik.errors.email,
							}
						)}
					/>
					{formik.touched.email && formik.errors.email && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.email}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				{isCode && (
					<div className='fv-row mb-10'>
						<label className='form-label fw-bolder text-gray-900 fs-6'>{translate('FORM.INPUT.ONE_TIME_PASSWORD')}</label>
						<input
							type='text'
							placeholder=''
							autoComplete='off'
							{...formik.getFieldProps('one_time_password')}
							className={clsx(
								'form-control form-control-lg form-control-solid',
								{ 'is-invalid': formik.touched.one_time_password && formik.errors.one_time_password },
								{
									'is-valid': formik.touched.one_time_password && !formik.errors.one_time_password,
								}
							)}
						/>
						{formik.touched.one_time_password && formik.errors.one_time_password && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.one_time_password}</span>
								</div>
							</div>
						)}
					</div>
				)}

				{/* end::Form group */}

				{/* begin::Form group */}
				<div className='d-flex flex-wrap justify-content-center pb-lg-0'>
					<button type='submit' id='kt_password_reset_submit' className='btn btn-lg btn-primary fw-bolder me-4'>
						<span className='indicator-label'>{translate('AUTH.GENERAL.SUBMIT_BUTTON')}</span>
						{loading && (
							<span className='indicator-progress'>
								Please wait...
								<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
					<Link to='/auth/login'>
						<button type='button' id='kt_login_password_reset_form_cancel_button' className='btn btn-lg btn-light-primary fw-bolder' disabled={formik.isSubmitting || !formik.isValid}>
							{translate('CARD.TEXT.CANCEL')}
						</button>
					</Link>{' '}
				</div>
				<div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-3'>
					<div onClick={sendMail} className='btn btn-sm btn-light fw-bolder me-4'>
						<span className='indicator-label'>{translate('AUTH.GENERAL.SEND_AGAIN')}</span>
						{loading && (
							<span className='indicator-progress'>
								Please wait...
								<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</div>
				</div>
				{/* end::Form group */}
			</form>
		</>
	)
}
