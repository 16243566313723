export const DiscountsApi = (instance: any) => ({
	async create(body: any) {
		const { data } = await instance.post(`/discount/create`, body)
		return data
	},
	async update(id: number | string, body: any) {
		const { data } = await instance.patch(`/discount/update/${id}`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/discount/delete/${id}`)
		return data
	},
	async getAll(id: string = '') {
		const { data } = await instance.get(`/discount/find/all/${id}`)
		return data
	},
})
