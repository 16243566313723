import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import IDeliveries from '../models/DeliveriesModel'
import { Api } from '../../../Services/api'
import { setRefresh } from '../../../reducers/RefreshSlice'
import DraggableMarker from '../../../../_theme/partials/content/DrawPolygon/MapDrag'
import { ICoordinate } from '../../organization/models/OrganizationModel'
import { LocationMap } from '../../../../_theme/partials/content/DrawPolygon/LocationMap'
import useTranslate from '../../../../_theme/helpers/translate'

type Props = {
	update: boolean
	handleClose: any
}

const DeliveriesSchema = Yup.object().shape({
	delivery_company_id: Yup.number().required(),
	name: Yup.string().required(),
	email: Yup.string().email().required(),
	phone: Yup.string().required(),
	surname: Yup.string().required(),
	lastname: Yup.string().required(),
	country_id: Yup.number().required(),
	city_id: Yup.number(),
	village_id: Yup.number(),
	street: Yup.string(),
	home_no: Yup.string(),
	district_id: Yup.number().required(),
	region_id: Yup.number().required(),
	address_title: Yup.string().required(),
})

const AddDelivery: FC<Props> = ({ update, handleClose }) => {
	const translate = useTranslate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const dispatch = useDispatch()
	const [deliveries, setDeliveries] = useState<IDeliveries[]>([])
	const [poligonGetterPoints, setPoligonGetterPoint] = useState([])
	const [poligonSetterPoints, setPoligonSetterPoint] = useState([])
	const [currentLocation, setCurrentLocation] = useState<ICoordinate>()

	const [countries, setCountries] = useState<any>([])
	const [selectedDistricts, setSelectedDistricts] = useState<any>([])
	const [regions, setRegions] = useState<any>([])
	const [cities, setCities] = useState<any>([])
	const [district, setDistrict] = useState<any>([])
	const [village, setVillage] = useState<any>([])

	const [userForEdit] = useState({
		name: '',
		email: '',
		phone: '',
		surname: '',
		lastname: '',
		country_id: '',
		city_id: '',
		village_id: '',
		street: '',
		home_no: '',
		district_id: '',
		region_id: '',
		address_title: '',
		delivery_company_id: '',
	})

	const formik = useFormik({
		initialValues: userForEdit,
		validationSchema: DeliveriesSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			const body = {
				name: values.name,
				delivery_company_id: Number(values.delivery_company_id),
				address: {
					country_id: Number(values.country_id),
					region_id: Number(values.region_id),
					district_id: Number(values.district_id),

					coordinate: {
						longitude: currentLocation?.longitude,
						latitude: currentLocation?.latitude,
					},
					title: values.address_title,
					city_id: values.city_id ? Number(values.city_id) : null,
					village_id: values.village_id ? Number(values.village_id) : null,
					street: values.street,
					home_no: values.home_no,
				},
				receive_coordinates: poligonGetterPoints,
				delivery_coordinates: poligonSetterPoints,
				manager: {
					email: values.email,
					phone: values.phone,
					name: values.surname + ' ' + values.lastname,
					surname: values.surname,
					lastname: values.lastname,
				},
			}
			Api()
				.deliveries.createFilial(body)
				.then((res) => {
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Successfuly Added!',
						showConfirmButton: false,
						timer: 1500,
					})
					dispatch(setRefresh())
					handleClose()
				})
				.catch((err) => {
					Swal.fire({
						icon: 'error',
						title: err?.response?.data?.statusCode,
						text: err?.response?.data?.message,
					})
				})
			try {
			} catch (ex) {
				console.error(ex)
			} finally {
				setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		const q = user?.role === 'super_admin' ? '' : `&manager_id=${user?.id}`

		Api()
			.deliveries.getAll(100, 1, q)
			.then((res) => {
				setDeliveries(res?.items)
			})
			.catch((err) => {})
		Api()
			.address.countries()
			.then((res) => {
				setCountries(res?.items)
			})
		Api()
			.address.city()
			.then((res) => {
				setCities(res?.items)
			})
		Api()
			.address.province()
			.then((res) => {
				setRegions(res?.items)
			})
		Api()
			.address.regions()
			.then((res) => {
				setDistrict(res?.items)
			})
		Api()
			.address.villages()
			.then((res) => {
				setVillage(res?.items)
			})

		return () => {}
	}, [])

	return (
		<form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
			<div className='d-flex flex-column scroll-y me-n7 pe-7 ' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DELIVERY_FILIAL_NAME')}</label>

						<input
							placeholder='Deliveries Name'
							{...formik.getFieldProps('name')}
							type='text'
							name='name'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.name && formik.errors.name },
								{
									'is-valid': formik.touched.name && !formik.errors.name,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.name && formik.errors.name && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.name}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DELIVERY_FILIAL_NAME')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select Country' {...formik.getFieldProps('delivery_company_id')} name='delivery_company_id'>
							<option hidden>Select</option>
							{deliveries?.map((item, index: any) => {
								return (
									<option key={index} value={item?.id}>
										{item?.name}
									</option>
								)
							})}
						</select>
						{formik.touched.delivery_company_id && formik.errors.delivery_company_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.delivery_company_id}</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.USER_NAME')}</label>

						<input
							placeholder='surname'
							{...formik.getFieldProps('surname')}
							type='text'
							name='surname'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.surname && formik.errors.surname },
								{
									'is-valid': formik.touched.surname && !formik.errors.surname,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.surname && formik.errors.surname && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.surname}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.USER_SURNAME')}</label>

						<input
							placeholder='User Lastname'
							{...formik.getFieldProps('lastname')}
							type='text'
							name='lastname'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.lastname && formik.errors.lastname },
								{
									'is-valid': formik.touched.lastname && !formik.errors.lastname,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.lastname && formik.errors.lastname && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.lastname}</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>User Phone</label>

						<input
							placeholder='User Phone'
							{...formik.getFieldProps('phone')}
							type='text'
							name='phone'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.phone && formik.errors.phone },
								{
									'is-valid': formik.touched.phone && !formik.errors.phone,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.phone && formik.errors.phone && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.phone}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.EMAIL')}</label>

						<input
							placeholder='Email'
							{...formik.getFieldProps('email')}
							type='text'
							name='email'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.email && formik.errors.email },
								{
									'is-valid': formik.touched.email && !formik.errors.email,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.email && formik.errors.email && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.email}</span>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.COUNTRY')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select Country' {...formik.getFieldProps('country_id')} name='country_id'>
							<option hidden>Select</option>
							{countries?.map((item: any, index: any) => {
								return (
									<option key={index} value={item?.id}>
										{item?.title}
									</option>
								)
							})}
						</select>
						{formik.touched.country_id && formik.errors.country_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.country_id}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.REGION')}</label>
						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select City' {...formik.getFieldProps('region_id')} name='region_id'>
							<option hidden>Select</option>
							{regions
								?.filter((item: any) => item?.country?.id == formik.values.country_id)
								?.map((item: any, index: any) => {
									return (
										<option key={index} value={item?.id}>
											{item?.title}
										</option>
									)
								})}
						</select>
						{formik.touched.region_id && formik.errors.region_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.region_id}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.CITY')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select City' {...formik.getFieldProps('city_id')} name='city_id'>
							<option hidden>Select</option>
							<option value={''}>Null</option>
							{cities
								// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
								?.map((item: any, index: any) => {
									return (
										<option key={index} value={item?.id}>
											{item?.title}
										</option>
									)
								})}
						</select>
						{formik.touched.city_id && formik.errors.city_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.city_id}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DISTRICT')}</label>
						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select district_id' {...formik.getFieldProps('district_id')} name='district_id'>
							<option hidden>Select</option>
							{district
								// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
								?.map((item: any, index: any) => {
									return (
										<option key={index} value={item?.id}>
											{item?.title}
										</option>
									)
								})}
						</select>
						{formik.touched.district_id && formik.errors.district_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.district_id}</span>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className='row'>
					<div className='col mb-7'>
						<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.VILLAGE')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select village_id' {...formik.getFieldProps('village_id')} name='village_id'>
							<option hidden>Select</option>
							<option value={''}>Null</option>
							{village
								// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
								?.map((item: any, index: any) => {
									return (
										<option key={index} value={item?.id}>
											{item?.title}
										</option>
									)
								})}
						</select>
						{formik.touched.district_id && formik.errors.district_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.district_id}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.STREET')}</label>

						<input
							placeholder='Street'
							{...formik.getFieldProps('street')}
							type='text'
							name='street'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.street && formik.errors.street },
								{
									'is-valid': formik.touched.street && !formik.errors.street,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.street && formik.errors.street && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.street}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.HOME_NUMBER')}</label>

						<input
							placeholder='Home Number'
							{...formik.getFieldProps('home_no')}
							type='text'
							name='home_no'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.home_no && formik.errors.home_no },
								{
									'is-valid': formik.touched.home_no && !formik.errors.home_no,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.home_no && formik.errors.home_no && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.home_no}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col-12 mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.ADDRESS')}</label>

						<input
							placeholder='Address'
							{...formik.getFieldProps('address_title')}
							type='text'
							name='address_title'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.address_title && formik.errors.address_title },
								{
									'is-valid': formik.touched.address_title && !formik.errors.address_title,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.address_title && formik.errors.address_title && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.address_title}</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div>
					<label className='form-label'>{translate('FORM.INPUT.RECEIVE_COORDINATES')}</label>
					<LocationMap setData={setPoligonGetterPoint} data={null} key={'receive_coordinates'} />
				</div>

				<div>
					<label className='form-label'>{translate('FORM.INPUT.DELIVERY_COORDINATES')}</label>
					<LocationMap setData={setPoligonSetterPoint} data={null} key={'delivery_coordinates'} />
				</div>

				<div>
					<label className='form-label'>{translate('FORM.INPUT.CURRENT_LOCATION')}</label>
					<DraggableMarker setData={setCurrentLocation} />
				</div>

				<div className='text-center pt-15'>
					<div onClick={handleClose} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
						{translate('FORM.BUTTON.CENCEL')}
					</div>

					<button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>
						<span className='indicator-label'>{translate('FORM.BUTTON.SEND')}</span>
						{formik.isSubmitting && (
							<span className='indicator-progress'>
								Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
				</div>
			</div>
		</form>
	)
}

export default AddDelivery
