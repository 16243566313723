import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_theme/layout/core'
import { Default } from './Default'
import { Marketing } from './Marketing'
import { Social } from './Social'
import { ECommerce } from './eCommerce'
import useTranslate from '../../../_theme/helpers/translate'

const dashboardBreadCrumbs: Array<PageLink> = [
	{
		title: 'Dashboards',
		path: '/dashboards',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
]

const DashboardsPageWrapper: React.FC<{ children?: React.ReactNode }> = () => {
	const translate = useTranslate()
	return (
		<Routes>
			<Route element={<Outlet />}>
				<Route
					path='default'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.DASHBOARD')}</PageTitle>
							<ECommerce />
						</>
					}
				/>
				<Route index element={<Navigate to='/dashboards/default' />} />
			</Route>
		</Routes>
	)
}

export { DashboardsPageWrapper }
