export const BalanceApi = (instance: any) => ({
	async cashOutOrderBalance(body: { order_id: number; check_ids: number[] }) {
		const { data } = await instance.patch(`/balance/cash-out-org`, body)
		return data
	},
	async cashOutOrderBalanceForDelivery(body: { order_id: number; check_ids: number[] }) {
		const { data } = await instance.patch(`/balance/cash-out-delivery`, body)
		return data
	},
	async cashOutRefundBalance(body: { order_id: number; check_ids: number[] }) {
		const { data } = await instance.patch(`/balance/cash-out-refund-org`, body)
		return data
	},
	async cashOutRefundBalanceForDelivery(body: { order_id: number; check_ids: number[] }) {
		const { data } = await instance.patch(`/balance/cash-out-refund-delivery`, body)
		return data
	},
	async cashOutRefundBalanceForUser(body: { order_id: number; check_ids: number[] }) {
		const { data } = await instance.patch(`/balance/cash-out-refund-user`, body)
		return data
	},
	async addDepositeOrg(body: { organization_id: number; deposite_amount: number; deposite_files_ids: number[] }) {
		const { data } = await instance.post(`/balance/add-deposite/organization`, body)
		return data
	},
	async addDepositeDelivery(body: { delivery_company_id: number; deposite_amount: number; deposite_files_ids: number[] }) {
		const { data } = await instance.post(`/balance/add-deposite/delivery-company`, body)
		return data
	},
})
