import React, { FC, useState } from 'react'
import { toAbsoluteUrl } from '../../../_theme/helpers'
import { Modal } from 'react-bootstrap'
import { ModalHeader } from '../../Modals/ModalHeader'
import UpdateDeliveryFilial from '../../Modals/UpdateDeliveryFilial'
import IDeliveryBranch from '../../models/DeliveryBranchModel'
import useTranslate from '../../../_theme/helpers/translate'
type Props = {
	item: IDeliveryBranch
}
const BranchInfo: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)
	return (
		<div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10 ml-0'>
			<div className='card mb-5 mb-xl-8'>
				<div className='card-body '>
					<div className='d-flex flex-center flex-column py-5'>
						<div className='symbol symbol-100px symbol-circle mb-7'>
							{/* <span
                className='symbol-label'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
                }}
              ></span> */}
							<div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-1 h-100px w-100px rounded-circle'>
								{item?.name?.substring(0, 1)} {item?.name?.substring(1, 2).toUpperCase()}
							</div>
						</div>

						<span className='fs-3 text-gray-800 fw-bolder mb-3'>{item?.name}</span>

						<div className='mb-9'>
							<div className='badge badge-lg badge-light-primary d-inline'>{item?.manager?.name}</div>
						</div>
					</div>

					<div className='separator'></div>

					<div id='kt_user_view_details' className='collapse show'>
						<div className='pb-5 fs-6'>
							<div className='fw-bolder mt-5'>{translate('FORM.INPUT.PHONE')}</div>
							<div className='text-gray-600'>{item?.manager?.phone}</div>

							<div className='fw-bolder mt-5'>{translate('FORM.INPUT.EMAIL')}</div>
							<div className='text-gray-600'>
								<a href='#' className='text-gray-600 text-hover-primary'>
									{item.email}
								</a>
							</div>
							<div className='fw-bolder mt-5'>{translate('FORM.INPUT.ADDRESS')}</div>
							<div className='text-gray-600'>{item?.address?.title}</div>
							<div className='d-flex align-items-center mt-4'>
								<div onClick={handleOpenModal} className='btn btn-primary align-self-center w-100'>
									Edit
								</div>
							</div>

							{/* <div className='fw-bolder mt-5'>Telefon Numarası</div>
            <div className='text-gray-600'>{item.phone}</div>

            <div className='fw-bolder mt-5'>Tedarikçi Firma Adres Bilgisi</div>
            <div className='text-gray-600'>{item.address}</div>

            <div className='fw-bolder mt-5'>Üretici Firma Ülke</div>
            <div className='text-gray-600'>{item.country.name}</div>

            <div className='fw-bolder mt-5'>Vergi Numarası </div>
            <div className='text-gray-600'>{item.tax_number}</div>
            {item?.irtibat_bilgisi != null && (
              <>
                <div className='fw-bolder mt-5'>İrtibat Bilgisi </div>
                <div className='text-gray-600'>{item.irtibat_bilgisi}</div>
              </>
            )} */}
						</div>
					</div>
				</div>
			</div>
			<Modal size={'lg'} show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={'New Delivery Filial'} />
					<UpdateDeliveryFilial item={item} update={true} handleClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default BranchInfo
