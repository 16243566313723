/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_theme/helpers'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { useAppSelector } from '../../../setup/hooks/redux'
import { openLogoModal } from './components/photos/reducers/PhotoSlice'
import { useDispatch } from 'react-redux'
import { AddCover } from './components/photos/components/AddCover'
import useTranslate from '../../../_theme/helpers/translate'
const ratingArray = [1, 2, 3, 4, 5]
const ProductHeader: React.FC<{ children?: React.ReactNode }> = () => {
	const location = useLocation()
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { product } = useAppSelector((state) => state.product)

	return (
		<div className='card mb-5 mb-xl-10'>
			<div className='card-body pt-9 pb-0'>
				<div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
					<div className='me-7 mb-4'>
						<div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
							<img style={{ objectFit: 'contain' }} src={product?.main_picture ? product?.main_picture.url : toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Good' />
							<div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>

							<div onClick={() => dispatch(openLogoModal())} className='position-absolute translate-middle cursor-pointer  mb-6  bg-light rounded-circle border border-4 border-white bg-light rounded-circle border border-4 border-white d-flex justify-content-center' style={{ width: '35px', height: '35px', top: 0, left: 160, cursor: 'pointer' }}>
								<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-4 fs-1 svg-icon-gray-700' />
								{/* <FontAwesomeIcon icon={faPenCircle} style={{fontSize:'20px', color:'grey', cursor:'pointer'}}/> */}
							</div>
						</div>
					</div>

					<div className='flex-grow-1'>
						<div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
							<div className='d-flex flex-column'>
								<div className='d-flex align-items-center mb-2'>
									<div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>{product?.title}</div>
								</div>

								<div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
									<div className='d-flex align-items-center mb-1'>
										{ratingArray.map((item) => (
											<div className={`rating-label me-1 ${item <= product?.average_rating ? 'checked' : ''} `}>
												<i className='bi bi-star-fill fs-6'></i>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>

						<div className='d-flex flex-wrap flex-stack'>
							<div className='d-flex flex-column flex-grow-1 pe-8'>
								<div className='d-flex flex-wrap'>
									<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
										<div className='d-flex align-items-center'>
											<div className='fs-2 fw-bolder'>{product?.amount}</div>
										</div>

										<div className='fw-bold fs-6 text-gray-400'>Productst</div>
									</div>

									<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
										<div className='d-flex align-items-center'>
											<div className='fs-2 fw-bolder'>0</div>
										</div>

										<div className='fw-bold fs-6 text-gray-400'>{translate('CARD.TEXT.PRODUCTS')}</div>
									</div>

									<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
										<div className='d-flex align-items-center'>
											<div className='fs-2 fw-bolder'>0</div>
										</div>

										<div className='fw-bold fs-6 text-gray-400'>Panding</div>
									</div>
								</div>
							</div>

							<div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
								<div className='d-flex justify-content-between w-100 mt-auto mb-2'>
									<span className='fw-bold fs-6 text-gray-400'>Sell</span>
									<span className='fw-bolder fs-6'>50%</span>
								</div>
								<div className='h-5px mx-3 w-100 bg-light mb-3'>
									<div className='bg-success rounded h-5px' role='progressbar' style={{ width: '50%' }}></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='d-flex overflow-auto h-55px'>
					<ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'detail' && 'active')} to={`detail`}>
								{translate('CARD.TEXT.ABOUT')}
							</Link>
						</li>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'photos' && 'active')} to={`photos`}>
								{translate('TABLE.TITLE.PHOTOS')}
							</Link>
						</li>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'orders' && 'active')} to={`orders`}>
								{translate('CARD.TEXT.ORDERS')}
							</Link>
						</li>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'review' && 'active')} to={`review`}>
								{translate('TABLE.TITLE.COMMENTS')}
							</Link>
						</li>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'discount' && 'active')} to={`discount`}>
								{translate('TABLE.TITLE.DISCOUNT')}
							</Link>
						</li>
						{/* <li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'customers' && 'active')} to={`customers`}>
								Customers
							</Link>
						</li> */}
					</ul>
				</div>
			</div>
			<AddCover />
		</div>
	)
}

export { ProductHeader }
