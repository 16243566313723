import Swal from 'sweetalert2'

const successMsg = (text: string) => {
	Swal.fire({
		position: 'center',
		icon: 'success',
		title: text,
		showConfirmButton: false,
		timer: 2000,
	})
}
const errorMsg = (title: string, text: string) => {
	Swal.fire({
		icon: 'error',
		title: `#${title}`,
		text: text,
	})
}

export { successMsg, errorMsg }
