import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../setup/hooks/redux'
import WSPGallery from './components/WSPGallery'
import { openModal } from './reducers/PhotoSlice'
import { IProduct } from '../../../../models/ProductModel'

export const Photos = () => {
	const { product } = useAppSelector((state) => state.product)
	return (
		<>
			<WSPGallery galleryImages={product?.child_pictures} productId={product?.id} />
		</>
	)
}
