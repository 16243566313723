import { FC, useEffect, useState } from 'react'
import { CardsWidget5, CardsWidget6, CardsWidget7, ChartsWidget3, ChartsWidget4, ChartsWidget5, EngageWidget1, ListsWidget5, TablesWidget2, TablesWidget4, TablesWidget5 } from '../../../_theme/partials/widgets'
import { ChartsOrderMonthly } from './components/ChartsOrderMonthly'
import { ChartsOrderYearly } from './components/ChartsOrderYearly'
import { ChartsOrderYearlyCount } from './components/ChartsOrderYearlyCount'
import { ChartsOrderMonthlyCount } from './components/ChartsOrderMonthlyCount'
import { ChartsUserYearly } from './components/ChartsUserYearly'
import { ChartsOrganizationsYearly } from './components/ChartsOrganizationsYearly'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { UserRoles } from '../../../_theme/helpers'
import { Spinner } from 'react-bootstrap'
import OrganizationsStatistics from './components/OrganizationsStatistics'
import DeliveriesStatistics from './components/DeliveriesStatistics'

export const ECommerce: FC<{ children?: React.ReactNode }> = () => {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const [queryType, setQueryType] = useState('')

	useEffect(() => {
		if (user.role === UserRoles.DELIVERY_COMPANY_MANAGER || user.role === UserRoles.DELIVERY_FILIAL_MANAGER) setQueryType('delivery_price_without_tax')
		else if (user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) setQueryType('price_for_organization')
		else setQueryType('price')
	}, [])

	return (
		<>
			{!!user ? (
				<>
					<div className='row g-5 g-xl-10 mb-xl-10'>
						<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>{queryType && <ChartsOrderYearly key='price' className={'h-md-100'} chartHeight={300} queryType={queryType} label={'GRAPHIC.TITLE.YEARLY_ORDERS'} />}</div>
						{user?.role === UserRoles.SUPER_ADMIN && (
							<>
								<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
									<ChartsOrderYearly key='price_ta' className={'h-md-100'} chartHeight={300} queryType='price_for_ta' label={'GRAPHIC.TITLE.YEARLY_ORDERS_EARNING'} />
								</div>
								<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
									<ChartsOrderYearly key='delivety_ta' className={'h-md-100'} chartHeight={300} queryType='delivery_price - o.delivery_price_without_tax' label={'GRAPHIC.TITLE.YEARLY_DELIVERY_EARNING'} />
								</div>
							</>
						)}
						<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>{queryType && <ChartsOrderMonthly key='price_month' className={'h-md-100'} chartHeight={300} queryType={queryType} label={'GRAPHIC.TITLE.MONTHLY_ORDERS'} />}</div>
						{user?.role === UserRoles.SUPER_ADMIN && (
							<>
								<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
									<ChartsOrderMonthly key='price_ta_months' className={'h-md-100'} chartHeight={300} queryType='price_for_ta' label={'GRAPHIC.TITLE.MONTHLY_ORDERS_EARNING'} />
								</div>
								<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
									<ChartsOrderMonthly key='delivety_ta_month' className={'h-md-100'} chartHeight={300} queryType='delivery_price - o.delivery_price_without_tax' label={'GRAPHIC.TITLE.MONTHLY_DELIVERY_EARNING'} />
								</div>
							</>
						)}
						<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>{queryType && <ChartsOrderYearlyCount className={'h-md-100'} chartHeight={300} queryType={queryType} />}</div>
						<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>{queryType && <ChartsOrderMonthlyCount className={'h-md-100'} chartHeight={300} queryType={queryType} />}</div>
						{user?.role === UserRoles.SUPER_ADMIN && (
							<>
								<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
									<ChartsUserYearly className={'h-md-100'} chartHeight={300} />
								</div>
								<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
									<ChartsOrganizationsYearly className={'h-md-100'} chartHeight={300} />
								</div>
								<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
									<OrganizationsStatistics />
								</div>
								<div className='col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0'>
									<DeliveriesStatistics />
								</div>
							</>
						)}
					</div>
				</>
			) : (
				<Spinner />
			)}
		</>
	)
}
