import IUpdateDeliveries from '../models/UpdateDeliveriesModel'
import IAddDeliveries from '../pages/deliveries/models/AddDeliveriesModel'

export const DeliveriesApi = (instance: any) => ({
	async create(body: IAddDeliveries) {
		const { data } = await instance.post(`/delivery-company/create`, body)
		return data
	},
	async createFilial(body: any) {
		const { data } = await instance.post(`/delivery-filial/create`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/delivery-company/delete`)
		return data
	},
	async accept(id: number) {
		const { data } = await instance.patch(`/delivery-company/approve/${id}`)
		return data
	},
	async reject(id: number) {
		const { data } = await instance.patch(`/delivery-company/reject/${id}`)
		return data
	},
	async getAll(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/delivery-company/all?limit=${limit}&page=${page}&select=country,shipping_restriction,manager${q}`)
		return data
	},
	async getAllForStatistics(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/delivery-company/all?limit=${limit}&page=${page}&select=country,balance${q}`)
		return data
	},
	async getAllFilial(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/delivery-filial/all?limit=${limit}&page=${page}&select=delivery_company,manager${q}`)
		return data
	},
	async getOne(id: string | undefined) {
		const { data } = await instance.get(`/delivery-company/find/one/${id}`)
		return data
	},
	async imageUpload(body: any) {
		const { data } = await instance.post(`/delivery-company/files/upload`, body)
		return data
	},
	async updateDelivery(body: IUpdateDeliveries) {
		const { data } = await instance.patch(`/delivery-company/update`, body)
		return data
	},
	async updatedFilial(id: number, body: any) {
		const { data } = await instance.patch(`/delivery-filial/update/${id}`, body)
		return data
	},
	async createCourier(body: any) {
		const { data } = await instance.post(`/delivery-filial/create/courier`, body)
		return data
	},
	async getOneFilial(id: string | undefined) {
		const { data } = await instance.get(`/delivery-filial/find/one/${id}`)
		return data
	},
	async getAllCouriers(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/delivery-filial/admin/couriers/all?limit=${limit}&page=${page}&sortType=DESC&select=avatar,organization,manage_delivery_filial,courier_filial,delivery_company,manage_organization_filial${q}`)
		return data
	},
	async getCouriers(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/delivery-filial/couriers/all?limit=${limit}&page=${page}&sortType=DESC&select=avatar,organization,manage_delivery_filial,courier_filial,delivery_company,manage_organization_filial${q}`)
		return data
	},
	async filialStatusChange(id: number | string, body: any) {
		const { data } = await instance.patch(`/delivery-filial/change-status/${id}`, body)
		return data
	},
})
