import { useEffect } from 'react'
import { Api } from '../../Services/api'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import useTranslate from '../../../_theme/helpers/translate'
import { useAppSelector } from '../../../setup/hooks/redux'
import FirstFilialDetailCard from './components/FirstFilialCard'
import RefundDetailsCard from './components/OrderDetailsCard'
import { setRefund } from './reducers/RefundSlice'
import CustomerDetailsCard from './components/CustomerDetailsCard'
import DocumentsCard from './components/DocumentsCard'
import DeliveryCompanyCard from './components/DeliveryCompanyCard'
import CourierDetailCard from './components/CourierDetailCard'
import CustomerComment from './components/CustomerComment'

const RefundDetail = () => {
	const translate = useTranslate()
	const { id } = useParams()
	const dispatch = useDispatch()
	const { refund } = useAppSelector((state) => state.refunds)

	useEffect(() => {
		Api()
			.refund.getOneRefund(id ? id : '')
			.then((res) => {
				dispatch(setRefund(res))
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	return (
		<div>
			<div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
				<RefundDetailsCard />
				<CustomerDetailsCard />
				<DocumentsCard />
			</div>
			<div className='mt-5 d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
				<div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
					<div className='card-header'>
						<div className='card-title'>
							<h2>{translate('CARD.TEXT.FILIAL_ADDESS')}</h2>
						</div>
					</div>
					<div className='card-body pt-0'>
						{refund?.organization_filial?.org_filial_address?.title}
						<br />
						{refund?.organization_filial?.org_filial_address?.district?.region?.title}/ {refund?.organization_filial?.org_filial_address?.city?.title} / {refund?.organization_filial?.org_filial_address?.district?.title}/{refund?.organization_filial?.org_filial_address?.street}/ {refund?.organization_filial?.org_filial_address?.home_no}
					</div>
				</div>
			</div>
			<div className='mt-5 d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
				<DeliveryCompanyCard />
				<FirstFilialDetailCard />
				<CourierDetailCard />
			</div>
			<div className='mt-5 d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
				<CustomerComment />
			</div>
			<div className='mt-5'>
				<div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
					<div className='card-header d-flex justify-content-between align-items-center'>
						<div className='card-title '>
							<h2>Refund #{refund?.id}</h2>
						</div>
					</div>
					<div className='card-body pt-0 scroll-x'>
						<div className='table-responsive'>
							<table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
								<thead>
									<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
										<th className='min-w-175px'>{translate('TABLE.TITLE.PRODUCT')}</th>
										<th className='min-w-100px text-end'>{translate('TABLE.TITLE.SKU')}</th>
										<th className='min-w-70px text-end'>{translate('TABLE.TITLE.QTY')}</th>
										<th className='min-w-70px text-end'>{translate('TABLE.TITLE.SPECIFICATIONS')}</th>
										<th className='min-w-100px text-end'>{translate('TABLE.TITLE.UNIT_PRICE')}</th>
										<th className='text-end min-w-100px'>{translate('TABLE.TITLE.TAX_FOR_TURKISHASIA')}</th>
										<th className='min-w-100px text-end'>{translate('TABLE.TITLE.TOTAL')}</th>
									</tr>
								</thead>
								<tbody className='fw-bold text-gray-600'>
									<tr>
										<td>
											<div className='d-flex align-items-center'>
												<div className='symbol symbol-50px'>
													<span className='symbol-label' style={{ backgroundImage: `url(${refund?.order_product?.product?.main_picture?.url})` }}></span>
												</div>
												<div className='ms-5'>
													<div className='fw-bolder text-gray-600 text-hover-primary'>{refund?.order_product?.product?.title}</div>
													{/* <div className='fs-7 text-muted'>Delivery Date: 04.11.2023</div> */}
												</div>
											</div>
										</td>
										<td className='text-end'>{refund?.order_product?.product?.article}</td>
										<td className='text-end'>{refund?.amount}</td>
										<td className='text-end'>{refund?.order_product?.specifications?.map(({ value }) => `${value},`)}</td>
										<td className='text-end'>{refund?.order_product?.product?.base_price}</td>
										<td className='text-end'>{refund?.order_product?.product?.price_with_tax - refund?.order_product?.product?.base_price}</td>
										<td className='text-end'>{refund?.amount * refund?.order_product?.product?.price_with_tax}</td>
										<td> </td>
									</tr>
									<tr>
										<td colSpan={6} className='text-end'>
											Subtotal
										</td>
										<td className='text-end'>{refund?.amount * refund?.order_product?.product?.price_with_tax}</td>
									</tr>
									<tr>
										<td colSpan={6} className='text-end'>
											Shipping Rate
										</td>
										<td className='text-end'>{refund?.order?.delivery_price}</td>
									</tr>
									<tr>
										<td colSpan={6} className='text-end text-danger'>
											Fine
										</td>
										<td className='text-end text-danger'>{refund?.fine}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default RefundDetail
