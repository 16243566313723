import React from 'react'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { OrderCollectingStatus, OrderDeliveryStatus, OrderProceedStatus } from '../../../../_theme/helpers/enums/OrderStatus'
import useTranslate from '../../../../_theme/helpers/translate'

const DocumentsCard = () => {
	const translate = useTranslate()
	const { order } = useAppSelector((state) => state.orders)

	return (
		<div className='card card-flush py-4 flex-row-fluid'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.STATUS')}</h2>
				</div>
			</div>
			<div className='card-body pt-0'>
				<div className='table-responsive'>
					<table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
						<tbody className='fw-bold text-gray-600'>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
												<path opacity='0.3' d='M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z' fill='currentColor' />
												<path
													d='M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z'
													fill='currentColor'
												/>
												<path d='M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z' fill='currentColor' />
											</svg>
										</span>
										{translate('CARD.TEXT.PAID_STATUS')}
									</div>
								</td>
								<td className='fw-bolder text-end'>
									{order?.paid_status === OrderProceedStatus.PAID ? (
										<div className='badge badge-light-success '>{translate(`STATUS.TEXT.${order.paid_status?.toUpperCase()}`)}</div>
									) : order?.paid_status === OrderProceedStatus.CANCELLED ? (
										<div className='badge badge-light-danger '>{translate(`STATUS.TEXT.${order.paid_status?.toUpperCase()}`)}</div>
									) : order?.paid_status === OrderProceedStatus.ACTIVE ? (
										<div className='badge badge-light-warning '>{translate(`STATUS.TEXT.${order.paid_status?.toUpperCase()}`)}</div>
									) : (
										<div className='badge badge-light-dark '>{translate(`STATUS.TEXT.${order.paid_status?.toUpperCase()}`)}</div>
									)}
								</td>
							</tr>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
												<path opacity='0.3' d='M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z' fill='currentColor' />
												<path
													d='M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z'
													fill='currentColor'
												/>
												<path d='M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z' fill='currentColor' />
											</svg>
										</span>
										{translate('CARD.TEXT.COLLECTION_STATUS')}
									</div>
								</td>
								<td className='fw-bolder text-end'>
									{order.collecting_status === OrderCollectingStatus.READY ? (
										<div className='badge badge-light-success '>{translate(`STATUS.TEXT.${order.collecting_status?.toUpperCase()}`)}</div>
									) : order.collecting_status === OrderCollectingStatus.PENDING ? (
										<div className='badge badge-light-danger '>{translate(`STATUS.TEXT.${order.collecting_status?.toUpperCase()}`)}</div>
									) : order.collecting_status === OrderCollectingStatus.COLLECTING ? (
										<div className='badge badge-light-warning '>{translate(`STATUS.TEXT.${order.collecting_status?.toUpperCase()}`)}</div>
									) : (
										<div className='badge badge-light-dark '>{translate(`STATUS.TEXT.${order.collecting_status?.toUpperCase()}`)}</div>
									)}
								</td>
							</tr>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
												<path d='M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z' fill='currentColor' />
												<path opacity='0.3' d='M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z' fill='currentColor' />
											</svg>
										</span>
										{translate('TABLE.TITLE.DELIVERY_STATUS')}
									</div>
								</td>
								<td className='fw-bolder text-end'>
									{order?.delivery_status === OrderDeliveryStatus?.DELIVERED ? (
										<div className='badge badge-light-success '>{translate(`STATUS.TEXT.${order.delivery_status?.toUpperCase()}`)}</div>
									) : order?.delivery_status === OrderDeliveryStatus.ON_WAY ? (
										<div className='badge badge-light-danger '>{translate(`STATUS.TEXT.${order.delivery_status?.toUpperCase()}`)}</div>
									) : order?.delivery_status === OrderDeliveryStatus.IN_FILIAL ? (
										<div className='badge badge-light-warning '>{translate(`STATUS.TEXT.${order.delivery_status?.toUpperCase()}`)}</div>
									) : (
										<div className='badge badge-light-dark '>{translate(`STATUS.TEXT.${order.delivery_status?.toUpperCase()}`)}</div>
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default DocumentsCard
