import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword, requestResetPassword } from '../redux/AuthCRUD'
import useTranslate from '../../../../_theme/helpers/translate'
import { useDispatch } from 'react-redux'
import * as auth from '../redux/AuthRedux'
import { errorMsg } from '../../../../_theme/helpers/AlertMsg'

const initialValues = {
	new_password: '',
	password: '',
}

export function NewPassword() {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { token } = useParams()
	console.log(token)

	const [loading, setLoading] = useState(false)
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
	const newPasswordSchema = Yup.object().shape({
		password: Yup.string().required('Password is required'),
		new_password: Yup.string()
			.required('Password is required')
			.oneOf([Yup.ref('password')], translate('AUTH.INPUT.NEW_PASSWORD_WRONG')),
	})
	const formik = useFormik({
		initialValues,
		validationSchema: newPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true)
			setHasErrors(undefined)
			setTimeout(() => {
				requestResetPassword(token || '', values.new_password)
					.then((res: any) => {
						setHasErrors(false)
						setLoading(false)
						dispatch(auth.actions.login(res?.data?.access_token, res?.data?.user))
					})
					.catch((err) => {
						setHasErrors(true)
						setLoading(false)
						setSubmitting(false)
						setStatus(err?.response?.data?.message)
						errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
					})
			}, 1000)
		},
	})

	return (
		<>
			<form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_password_reset_form' onSubmit={formik.handleSubmit}>
				<div className='text-center mb-10'>
					{/* begin::Title */}
					<h1 className='text-dark mb-3'>{translate('AUTH.FORGOT.TITLE')}</h1>
					{/* end::Title */}

					{/* begin::Link */}
					<div className='text-gray-400 fw-bold fs-4'>{translate('AUTH.INPUT.PASSWORD')}.</div>
					{/* end::Link */}
				</div>

				{/* begin::Form group */}
				<div className='fv-row mb-10'>
					<label className='form-label fw-bolder text-gray-900 fs-6'>{translate('AUTH.INPUT.PASSWORD')}</label>
					<input
						type='password'
						placeholder=''
						autoComplete='off'
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.password && formik.errors.password },
							{
								'is-valid': formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					{formik.touched.password && formik.errors.password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.password}</span>
							</div>
						</div>
					)}
				</div>
				<div className='fv-row mb-10'>
					<label className='form-label fw-bolder text-gray-900 fs-6'>{translate('AUTH.INPUT.AGAING_PASSWORD')}</label>
					<input
						type='password'
						placeholder=''
						autoComplete='off'
						{...formik.getFieldProps('new_password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.new_password && formik.errors.new_password },
							{
								'is-valid': formik.touched.new_password && !formik.errors.new_password,
							}
						)}
					/>
					{formik.touched.new_password && formik.errors.new_password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.new_password}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className='d-flex flex-wrap justify-content-center pb-lg-0'>
					<button type='submit' id='kt_password_reset_submit' className='btn btn-lg btn-primary fw-bolder me-4'>
						<span className='indicator-label'>{translate('AUTH.GENERAL.SUBMIT_BUTTON')}</span>
						{loading && (
							<span className='indicator-progress'>
								Please wait...
								<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
					<Link to='/auth/login'>
						<button type='button' id='kt_login_password_reset_form_cancel_button' className='btn btn-lg btn-light-primary fw-bolder' disabled={formik.isSubmitting || !formik.isValid}>
							{translate('CARD.TEXT.CANCEL')}
						</button>
					</Link>{' '}
				</div>
				{/* end::Form group */}
			</form>
		</>
	)
}
