import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { KTSVG } from '../../../../_theme/helpers'
import { Api } from '../../../Services/api'
import Swal from 'sweetalert2'
import { setRefresh } from '../reducers/BranchesSlice'
import { Dropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import useTranslate from '../../../../_theme/helpers/translate'

const UpdatedBranches = () => {
	const translate = useTranslate()
	const { branches } = useAppSelector((state) => state.branches)

	return (
		<div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
			<div className='card-header cursor-pointer'>
				<div className='card-title m-0'>
					<h3 className='fw-bolder m-0'>Brnach Details</h3>
				</div>
			</div>

			<div className='card-body p-9'>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.FULL_NAME')}</label>

					<div className='col-lg-8'>
						<span className='fw-bolder fs-6 text-dark'>
							{branches?.manager?.name} {branches?.manager?.surname}
						</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.COMPANY_NAME')} </label>

					<div className='col-lg-8 fv-row'>
						<span className='fw-bold fs-6'>{branches?.name}</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.COMPANY_DESCRIPTION')}</label>

					<div className='col-lg-8 fv-row'>
						<span className='fw-bold fs-6'>{branches?.organization?.description}</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>
						{translate('CARD.TEXT.CONTACT_PHONE')}
						<i className='fas fa-exclamation-circle ms-1 fs-7' data-bs-toggle='tooltip' title='Phone number must be active'></i>
					</label>

					<div className='col-lg-8 d-flex align-items-center'>
						<span className='fw-bolder fs-6 me-2'>{branches?.manager?.phone}</span>

						{/* <span className='badge badge-success'>Verified</span> */}
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>
						{translate('CARD.TEXT.CONTACT_EMAIL')}
						<i className='fas fa-exclamation-circle ms-1 fs-7' data-bs-toggle='tooltip' title='Phone number must be active'></i>
					</label>

					<div className='col-lg-8 d-flex align-items-center'>
						<span className='fw-bolder fs-6 me-2'>{branches?.manager?.email}</span>

						{/* <span className='badge badge-success'>Verified</span> */}
					</div>
				</div>

				{/* <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Country
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>Turkey</span>
          </div>
        </div> */}

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.COMMUNICATION')}</label>

					<div className='col-lg-8'>
						<span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
					</div>
				</div>

				<div className='row mb-10'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.STATUS')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{translate(`STATUS.TEXT.${branches?.status?.toUpperCase()}`)}</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UpdatedBranches
