/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { setLanguage, useLang } from '../../../i18n/Goodi18n'
import useTranslate from '../../../helpers/translate'
import { Dropdown } from 'react-bootstrap'

const languagesDrop = [
	{
		lang: 'en',
		name: 'English',
		flag: toAbsoluteUrl('/media/flags/united-states.svg'),
	},
	{
		lang: 'kg',
		name: 'Kyrgyz',
		flag: toAbsoluteUrl('/media/flags/kyrgyzstan.svg'),
	},
	{
		lang: 'ru',
		name: 'Russion',
		flag: toAbsoluteUrl('/media/flags/russia.svg'),
	},
]

type Props = {
	languageMenuPlacement?: 'lert-start' | 'right-end'
}

const LanguagesDrop: FC<Props> = ({ languageMenuPlacement = 'lert-start' }) => {
	const translate = useTranslate()
	const lang = useLang()
	const currentLanguage = languagesDrop.find((x) => x.lang === lang)
	return (
		<Dropdown data-kt-menu-trigger='click'>
			<Dropdown.Toggle variant='none' className='w-50'>
				<div className='d-flex justify-content-between position-relative'>
					<div>{translate('CARD.TEXT.LANGUAGE')}</div>
					<span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
						{currentLanguage?.name} <img className='w-15px h-15px rounded-1 ms-2' src={currentLanguage?.flag} alt='good' />
					</span>
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{languagesDrop.map((l) => (
					<Dropdown.Item>
						<div
							className='menu-item px-3'
							key={l.lang}
							onClick={() => {
								setLanguage(l.lang)
							}}
						>
							<a href='#' className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}>
								<span className='symbol symbol-20px me-4'>
									<img className='rounded-1' src={l.flag} alt='good' />
								</span>
								{l.name}
							</a>
						</div>
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export { LanguagesDrop }
