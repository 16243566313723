import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { setAddProduct } from '../../../reducers/ProductSlice'
import { Debounce } from '../../../../../../_theme/helpers'
import useTranslate from '../../../../../../_theme/helpers/translate'

function Pricing() {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)

	const changeHandlerDicsountedPrice = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, discount: e.target.value }))
		// dispatch(setProductName(e.target.value))
	}, 100)
	const changeHandlerBasePrice = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, basePrice: e.target.value }))
		// dispatch(setProductDesc(e.target.value))
	}, 100)
	const changeHandlerTaxDiscount = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, taxProcent: e.target.value }))
		// dispatch(setProductDesc(e.target.value))
	}, 100)

	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.PRICING')}</h2>
				</div>
			</div>

			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className='mb-10 fv-row'>
					<label className='required form-label'>{translate('CARD.TEXT.BASE_PRICE')}</label>

					<input type='text' name='price' onChange={changeHandlerBasePrice} className='form-control mb-2' placeholder='Product price' />

					<div className='text-muted fs-7'>{translate('CARD.TEXT.SET_THE_PRODUCT_PRICE')}</div>
				</div>

				<div className='fv-row mb-10'>
					<label className='fs-6 fw-bold mb-2'>
						{translate('CARD.TEXT.PRODUCT_RELATIONS')}
						<i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title='Select a discount type that will be applied to this product'></i>
					</label>

					<div className='row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9' data-kt-buttons='true' data-kt-buttons-target="[data-kt-button='true']">
						<div className='col'>
							<label className='btn btn-outline btn-outline-dashed btn-outline-default  d-flex text-start p-6' data-kt-button='true'>
								<span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
									<input
										className='form-check-input'
										type='radio'
										onChange={(e) => {
											dispatch(setAddProduct({ ...addProduct, discountType: e.target.value }))
										}}
										defaultChecked
										name='discount_option'
										value='1'
									/>
								</span>

								<span className='ms-5'>
									<span className='fs-4 fw-bolder text-gray-800 d-block'>{translate('CARD.TEXT.NO_DISCOUNT')}</span>
								</span>
							</label>
						</div>

						<div className='col'>
							<label className='btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6' data-kt-button='true'>
								<span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
									<input
										className='form-check-input'
										type='radio'
										onChange={(e) => {
											dispatch(setAddProduct({ ...addProduct, discountType: e.target.value }))
										}}
										name='discount_option'
										value='percentage'
									/>
								</span>

								<span className='ms-5'>
									<span className='fs-4 fw-bolder text-gray-800 d-block'>{translate('CARD.TEXT.PERCENTAGE')} %</span>
								</span>
							</label>
						</div>

						<div className='col'>
							<label className='btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6' data-kt-button='true'>
								<span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
									<input
										className='form-check-input'
										type='radio'
										onChange={(e) => {
											dispatch(setAddProduct({ ...addProduct, discountType: e.target.value }))
										}}
										name='discount_option'
										value='fixed'
									/>
								</span>

								<span className='ms-5'>
									<span className='fs-4 fw-bolder text-gray-800 d-block'>{translate('CARD.TEXT.RIXED_PRICE')} </span>
								</span>
							</label>
						</div>
					</div>
				</div>

				{addProduct.discountType !== '1' && (
					<>
						<div className='d-block mb-5 fv-row' id='kt_ecommerce_add_product_discount_fixed'>
							<label className='form-label'>Discounted</label>

							<input type='number' name='dicsounted_price' onChange={changeHandlerDicsountedPrice} className='form-control mb-2' placeholder='Discounted price' />

							<div className='text-muted fs-7'>Set the discounted product price. The product will be reduced at the determined fixed price</div>
						</div>
						<div className='d-flex flex-wrap gap-5'>
							<div className='fv-row w-100 flex-md-root'>
								<label className='form-label'>Start Date</label>

								<input onChange={(e) => dispatch(setAddProduct({ ...addProduct, discountStartDate: e.target.value }))} lang='tr-TR' type='date' className='form-control mb-2' />

								<div className='text-muted fs-7'>Set discount start date</div>
							</div>
							<div className='fv-row w-100 flex-md-root'>
								<label className='form-label'>End Date </label>

								<input onChange={(e) => dispatch(setAddProduct({ ...addProduct, discountEndDate: e.target.value }))} type='date' min={addProduct.discountStartDate} className='form-control mb-2' />

								<div className='text-muted fs-7'>Set the discount end date</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default Pricing
