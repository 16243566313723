import { all_users, only_del_filial_manager, only_del_owner, only_filial_manager, only_org_owner, only_superadmin } from '../../../helpers/roles/roles'

export interface TMenuItem {
	key: string
	title: string
	to: string
	fontIcon?: string
	icon?: string
	hasRole: string[]
	children?: TMenuItem[]
}

export const MENU: TMenuItem[] = [
	{
		key: 'dashboards',
		to: '/dashboards/',
		title: 'MENU.DASHBOARD',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: all_users,
	},
	{
		key: 'organization',
		to: '/organization',
		title: 'MENU.ORGANIZATION',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: only_org_owner,
	},
	{
		key: 'branches',
		to: '/branches',
		title: 'MENU.BRANCHES',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: only_org_owner,
	},
	{
		key: 'products',
		to: '/products',
		title: 'MENU.PRODUCTS',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: [...only_org_owner, ...only_filial_manager],
	},
	{
		key: 'deliveries',
		to: '/deliveries',
		title: 'MENU.DELIVERIES',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: only_del_owner,
	},
	{
		key: 'delivery-filials',
		to: '/delivery-filials',
		title: 'MENU.DELIVERY_FILIALS',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: [...only_del_owner, ...only_del_filial_manager],
	},
	{
		key: 'couriers',
		to: '/couriers',
		title: 'MENU.COURIERS',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: [...only_del_owner, ...only_del_filial_manager],
	},
	{
		key: 'orders',
		to: '/orders',
		title: 'MENU.ORDERS',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: all_users,
	},
	{
		key: 'brands',
		to: '/brands',
		title: 'MENU.BRANDS',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: only_org_owner,
	},
	{
		key: 'returns',
		to: '/returns',
		title: 'MENU.RETURNS',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: [...only_del_owner, ...only_org_owner, ...only_del_filial_manager],
	},
	{
		key: 'categories',
		to: '/categories',
		title: 'MENU.CATEGORIES',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: only_superadmin,
	},
	{
		key: 'refund-type',
		to: '/refund-type',
		title: 'MENU.RETURN_TYPES',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: only_superadmin,
	},
	{
		key: 'address',
		to: '/address',
		title: 'MENU.ADDRESS',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: only_superadmin,
	},
	{
		key: 'docs',
		to: '/docs',
		title: 'MENU.DOCS',
		fontIcon: 'bi-chat-left',
		icon: '/media/icons/duotune/arrows/arr001.svg',
		hasRole: only_superadmin,
	},
]
