/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLayout } from '../core'

const Footer: React.FC<{ children?: React.ReactNode }> = () => {
	const { classes } = useLayout()
	return (
		<div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
			<div className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}>
				<div className='text-dark order-2 order-md-1'>
					<span className='text-muted fw-bold me-1'>{new Date().getFullYear()} &copy;</span>
					<a href='/' target='_blank' className='text-gray-800 text-hover-primary'>
						TurkishAsia
					</a>
				</div>
			</div>
		</div>
	)
}

export default Footer
