import { FC, useState } from 'react'
import AddOrganization from '../modals/AddDelivery'
import { useNavigate } from 'react-router-dom'
import { ModalHeader } from '../../../Modals/ModalHeader'
import { Dropdown, Modal } from 'react-bootstrap'
import useTranslate from '../../../../_theme/helpers/translate'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { useSelector, shallowEqual } from 'react-redux'
import { UserRoles } from '../../../../_theme/helpers'
import { RootState } from '../../../../setup'
import ChangeDeliveryFilialStatus from '../../../Modals/ChangeDeliveryFilialStatus'

type Props = {
	item: any
}

const DeliveryItem: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

	const nav = () => {
		navigate(`/delivery-branch/${item.id}`, { replace: true })
	}

	return (
		<>
			<tr>
				<td onClick={nav}>
					<div onClick={nav} className='d-flex align-items-center cursor-pointer'>
						<div className='symbol symbol-50px'>
							{/* <span
								className='symbol-label'
								style={{
									backgroundImage: `url(${item.documents ? item.documents[0].url : toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
								}}
							></span> */}
							<div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-5 h-40px w-40px rounded'>
								{item?.name?.substring(0, 1)} {item?.name?.substring(1, 2).toUpperCase()}
							</div>
						</div>

						<div className='ms-5'>
							<div className='text-gray-800 text-hover-primary fs-5 fw-bolder' data-kt-ecommerce-product-filter='product_name'>
								{item?.name}
							</div>
						</div>
					</div>
				</td>
				<td onClick={nav} className='text-end pe-0 cursor-pointer' data-order='Published'>
					{item?.delivery_company?.name}
				</td>
				<td onClick={nav} className='text-end pe-0 cursor-pointer' data-order='Published'>
					{item?.email}
				</td>
				<td onClick={nav} className='text-end pe-0 cursor-pointer' data-order='Published'>
					{item?.phone}
				</td>
				<td className=' pe-0' data-order='Published'>
					<div className={`badge badge-${item.status == 'pending' ? 'light-info' : item.status == 'active' ? 'light-success' : 'light-danger'} `}>{translate(`STATUS.TEXT.${item?.status.toUpperCase()}`)}</div>
				</td>
				<td className='text-end pe-0'>
					<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						<Dropdown.Toggle variant='primary' id='dropdown-basic'>
							{translate('TABLE.TITLE.ACTION')}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && (
								<Dropdown.Item>
									<div onClick={handleOpenModal} className='menu-link px-3 my-1'>
										{translate('CARD.TEXT.CHANGE_STATUS')}
									</div>
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={translate('CARD.TEXT.CHANGE_STATUS')} />
					<ChangeDeliveryFilialStatus filialId={item.id} handleClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
		</>
	)
}

export default DeliveryItem
