export const BrandsApi = (instance: any) => ({
	async create(body: any) {
		const { data } = await instance.post(`/brand/create`, body)
		return data
	},
	async update(id: number | string, body: any) {
		const { data } = await instance.patch(`/brand/update/${id}`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/brand/delete/${id}`)
		return data
	},
	async getAll(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/brand/all?limit=${limit}&page=${page}&select=logo,user${q}`)
		return data
	},
	async imageUpload(body: any) {
		const { data } = await instance.post(`/brand/files/upload`, body)
		return data
	},
})
