import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_theme/helpers'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { useAppSelector } from '../../../setup/hooks/redux'
import { Api } from '../../Services/api'
import CourierItem from '../../components/CouirerItem'
import AddCourier from '../../Modals/AddCourier'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import { UserRoles } from '../../../_theme/helpers/enums/UserRoles'
import { ICourier } from '../../models/CourierModal'
import useTranslate from '../../../_theme/helpers/translate'
import Pagination from '../../components/Pagination'
import { SearchComponent } from '../../components/SearchBox'

const Courier = () => {
	const dispatch = useDispatch()
	const translate = useTranslate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const { refresh } = useAppSelector((state) => state.refresh)

	const [couriers, setCouriers] = useState<ICourier[]>([])
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [total, setToltal] = useState(1)
	const [searchKeyClick, setSearchKeyClick] = useState('')
	const [statusSelect, setStatusSelect] = useState('')

	useEffect(() => {
		setLoading(true)
		const search = searchKeyClick ? `&name=${searchKeyClick}` : ''
		if (user.role === UserRoles.SUPER_ADMIN) {
			Api()
				.deliveries.getAllCouriers(limit, currentPage, `${search}${statusSelect}`)
				.then((res) => {
					setCouriers(res?.items)
				})
				.catch((err) => {
					console.log(err)
				})
		}
		if (user.role === UserRoles.DELIVERY_COMPANY_MANAGER || user.role === UserRoles.DELIVERY_FILIAL_MANAGER) {
			Api()
				.deliveries.getCouriers(limit, currentPage, `${search}${statusSelect}`)
				.then((res) => {
					setCouriers(res?.items)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [refresh, searchKeyClick, statusSelect, currentPage])
	return (
		<div>
			<div className='card card-flush mb-6 mb-xl-9'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					<div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>

							<SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Search'} />
						</div>
					</div>

					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						<div className='w-100 mw-150px'>
							<select className='form-select form-select-solid' data-control='select2' onChange={(e) => setStatusSelect(e.target.value)} data-hide-search='true' data-placeholder='Status' data-kt-ecommerce-product-filter='status'>
								<option disabled>{translate('CARD.TEXT.STATUS')}</option>
								<option value=''>{translate('STATUS.TEXT.ALL')}</option>
								<option value='&status=active'>{translate('STATUS.TEXT.ACTIVE')}</option>
								<option value='&status=pending'>{translate('STATUS.TEXT.PASSIVE')}</option>
							</select>
						</div>

						<div onClick={handleOpenModal} className='btn btn-primary'>
							{translate('CARD.TEXT.ADD_COURIERS')}
						</div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px  scroll-x'>
					<table className='table align-middle table-row-dashed fs-6 gy-5'>
						<thead>
							<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
								<th className='min-w-70px'>{translate('TABLE.TITLE.FULL_NAME')}</th>
								<th className='min-w-70px'>{translate('TABLE.TITLE.EMAIL')}</th>
								<th className='min-w-70px'>{translate('TABLE.TITLE.PHONE')}</th>
								{/* <th className='min-w-70px'>{translate('TABLE.TITLE.STATUS')}</th> */}
							</tr>
						</thead>
						<tbody className=''>
							{couriers?.map((item: any) => {
								return <CourierItem item={item} />
							})}
						</tbody>
					</table>
					<Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} />
				</div>
			</div>
			<AddCourier update={false} isOpen={showModal} onClose={handleCloseModal} filialId={-1} />
		</div>
	)
}

export default Courier
