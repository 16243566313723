import { createSlice } from '@reduxjs/toolkit'

interface RefreshState {
	refresh: boolean
}

const initialState: RefreshState = {
	refresh: false,
}

export const RefreshSlice = createSlice({
	name: 'refresh',
	initialState,
	reducers: {
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { setRefresh } = RefreshSlice.actions

export default RefreshSlice.reducer
