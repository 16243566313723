import { FC, useState } from 'react'
import { toAbsoluteUrl } from '../../../../_theme/helpers'
import { useNavigate } from 'react-router-dom'
import { IProduct } from '../../../models/ProductModel'
import { Dropdown } from 'react-bootstrap'
import { Api } from '../../../Services/api'
import { errorMsg, successMsg } from '../../../../_theme/helpers/AlertMsg'
import { useDispatch } from 'react-redux'
import { setRefresh } from '../../../reducers/RefreshSlice'
import Swal from 'sweetalert2'
import useTranslate from '../../../../_theme/helpers/translate'

type Props = {
	item: IProduct
}

const ProductItem: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)
	const nav = () => {
		navigate(`/product/${item.id}`, { replace: true })
	}
	const changeStatus = () => {
		const body = {
			product_id: item.id,
			status: item.status === 'unpublished' ? 'published' : 'unpublished',
		}
		Api()
			.products.changeProductStatus(body)
			.then(() => {
				successMsg('Successfuly Changed')
				dispatch(setRefresh())
			})
			.catch((err) => {
				errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
			})
	}
	function swalDelete() {
		Swal.fire({
			title: 'The product will be deleted!',
			text: 'Do you want to delete the product?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cencel',
			confirmButtonText: 'Delete',
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				Api()
					.products.delete(item?.id)
					.then((res) => {
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: res?.message,
							showConfirmButton: false,
							timer: 1500,
						})
						dispatch(setRefresh())
					})
					.catch((err) => {
						Swal.fire({
							icon: 'error',
							title: err?.response?.data?.statusCode,
							text: err?.response?.data?.message,
						})
						console.log(err)
					})
			} else if (result.isDenied) {
			}
		})
	}
	return (
		<>
			<tr>
				<td>
					<div className='d-flex align-items-center'>
						<div onClick={nav} className='symbol symbol-50px'>
							<span
								className='symbol-label'
								style={{
									objectFit: 'contain',
									backgroundImage: `url(${item.main_picture ? item.main_picture.url : toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
								}}
							></span>
						</div>

						<div className='ms-5'>
							<div onClick={nav} className='text-gray-800 text-hover-primary fs-5 fw-bolder' data-kt-ecommerce-product-filter='product_name'>
								{item?.title} <br />
								<small>{item?.organization?.name}</small>
							</div>
						</div>
					</div>
				</td>

				<td className='text-end pe-0'>
					<span className='fw-bolder'> {item?.article}</span>
				</td>

				<td className='text-end pe-0' data-order='35'>
					<span className='fw-bolder ms-3'>{item?.amount}</span>
				</td>

				<td className='text-end pe-0'>
					<span className='fw-bolder text-dark'>{item?.base_price}</span>
				</td>
				<td className='text-end pe-0'>
					<span className='fw-bolder text-dark'>{item?.price_with_tax}</span>
				</td>

				<td className='text-end pe-0' data-order='Published'>
					{item?.status == 'published' ? <div className='badge badge-light-success '>{translate('STATUS.TEXT.PUBLISHED')}</div> : <div className='badge badge-light-danger '>{translate('STATUS.TEXT.UNPUBLISHED')}</div>}
				</td>

				<td className='text-end'>
					<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						<Dropdown.Toggle variant='primary' id='dropdown-basic'>
							{translate('TABLE.TITLE.ACTION')}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item>
								<div onClick={swalDelete} className='menu-item px-3'>
									<div className='menu-link px-3'>{translate('CARD.TEXT.DELETE')}</div>
								</div>
							</Dropdown.Item>
							<Dropdown.Item>
								<div className='menu-item px-3'>
									<div onClick={handleOpenModal} className='menu-link px-3'>
										{translate('CARD.TEXT.DIVIDE')}
									</div>
								</div>
							</Dropdown.Item>
							<Dropdown.Item>
								<div className='menu-item px-3'>
									<div onClick={changeStatus} className='menu-link px-3'>
										{item?.status === 'unpublished' ? translate('STATUS.TEXT.PUBLISHED') : translate('STATUS.TEXT.UNPUBLISHED')}
									</div>
								</div>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
			{/* <Modal size={'lg'} show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <ModalHeader handleClose={handleCloseModal} titleHeader={'New Variations'} />
          <DivideProduct update={false} handleClose={handleCloseModal} product={item} product_id={item.id} />
        </Modal.Body>
      </Modal> */}
		</>
	)
}

export default ProductItem
