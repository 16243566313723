import React, { FC, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import RequestItem from './components/RequestItem'
import { IRequestModel } from './model/RequestModel'
import { Api } from '../../Services/api'
import { useAppSelector } from '../../../setup/hooks/redux'
import Pagination from '../../components/Pagination'
import { Modal, Spinner } from 'react-bootstrap'
import useTranslate from '../../../_theme/helpers/translate'
import { ModalHeader } from '../../Modals/ModalHeader'
import { AddInvoiceFromRefundForOrg } from '../../Modals/AddInvoiceFromRefundForOrg'
import { AddInvoiceFromRefundForDelivery } from '../../Modals/AddInvoiceFromRefundForDelivery'
import { AddInvoiceFromRefundForUser } from '../../Modals/AddInvoiceFromRefundForUser'
import { UserModel } from '../../modules/auth/models/UserModel'
import { UserRoles } from '../../../_theme/helpers'
import { RootState } from '../../../setup'

type Props = {
	req?: string
}

const CancellationRequest: FC<Props> = ({ req = '' }) => {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const dispatch = useDispatch()
	const translate = useTranslate()
	const { refresh } = useAppSelector((state) => state.refresh)

	//States
	const [cancellationRequests, setCancellationRequests] = useState<IRequestModel[]>([])
	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [total, setToltal] = useState(1)
	const [searchKeyClick, setSearchKeyClick] = useState('')
	const [statusSelect, setStatusSelect] = useState('')
	const [selectedOrderId, setSelectedOrderId] = useState(null)

	const [show, setShow] = useState(false)
	const [showDelivery, setShowDelivery] = useState(false)
	const [showUser, setShowUser] = useState(false)
	const openModal = (orderId: any, type: string) => {
		if (type === 'org') setShow(true)
		else if (type === 'user') setShowUser(true)
		else setShowDelivery(true)
		setSelectedOrderId(orderId)
	}
	const closeModal = () => {
		setShowDelivery(false)
		setShow(false)
		setShowUser(false)
	}

	useEffect(() => {
		setLoading(true)
		Api()
			.refund.getAllRefunds(limit, currentPage, req)
			.then((res) => {
				setCancellationRequests(res?.items)
				setToltal(res?.total_count)
			})
			.finally(() => setLoading(false))
	}, [refresh, currentPage])

	return (
		<div>
			<div className='card card-flush'>
				{/* <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
      

            </div>
          </div>

          <div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
            <div className='w-100 mw-200px'>
              <select
                className='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Status'
                data-kt-ecommerce-product-filter='status'
                onChange={(e)=>setStatus(e.target.value)}
              >
                {/* <option disabled>Durum Seç</option> */}
				{/* <option value=''>Tümü</option>
                <option value='0'>Ödeme Bekleniyor</option>
                <option value='1'>Ödeme Alındı</option>
                <option value='2'>İptal Edildi</option>
                <option value='4'>Konakladı</option>
              </select>
            </div>

            {/* <div className='btn btn-primary'>Yeni Reservasyon</div> */}
				{/* </div>
        </div> */}
				<div className='card-body pt-0 mt-5 min-h-500px  scroll-x'>
					{loading ? (
						<div className='text-center'>
							<Spinner className='p-5 text-primary' />
						</div>
					) : (
						<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
							<thead>
								<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
									<th className='w-200px'>{translate('TABLE.TITLE.PRODUCT')}</th>
									<th className=''>{translate('TABLE.TITLE.ORGANIZATION')}</th>
									<th className=''>{translate('TABLE.TITLE.ORG_FILIAL')}</th>
									<th className=''>{translate('TABLE.TITLE.ORG_FILIAL')}</th>
									<th className=''>{translate('TABLE.TITLE.DESCRIPTION')}</th>

									{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && <th className='text-end min-w-100px'>{translate('TABLE.TITLE.PAID_TO_ORG')}</th>}
									{(user.role === UserRoles.SUPER_ADMIN || user?.role == UserRoles.DELIVERY_COMPANY_MANAGER || user?.role == UserRoles.DELIVERY_FILIAL_MANAGER) && <th className='text-end min-w-100px'>{translate('TABLE.TITLE.PAID_TO_DELIVERY')}</th>}
									{user.role === UserRoles.SUPER_ADMIN && <th className='text-end min-w-100px'>{translate('TABLE.TITLE.PAID_TO_USER')}</th>}
									<th className=''>{translate('TABLE.TITLE.SPECIFICATION')}</th>
									<th>{translate('TABLE.TITLE.RESPONSIBLE')}</th>
									<th>{translate('TABLE.TITLE.DELIVERY_STATUS')}</th>
									<th className='text-end '>{translate('TABLE.TITLE.ACTION')}</th>
								</tr>
							</thead>
							<tbody className='fw-bold text-gray-600'>
								{cancellationRequests.map((request: IRequestModel) => {
									return <RequestItem key={request.id} request={request} openModal={openModal} />
								})}
							</tbody>
						</table>
					)}
					<Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} />
				</div>
			</div>
			<Modal show={show} onHide={closeModal}>
				<Modal.Body>
					<ModalHeader handleClose={closeModal} titleHeader={'Add Invoice For Org'} />
					<AddInvoiceFromRefundForOrg orderId={selectedOrderId} handleClose={closeModal} />
				</Modal.Body>
			</Modal>
			<Modal show={showDelivery} onHide={closeModal}>
				<Modal.Body>
					<ModalHeader handleClose={closeModal} titleHeader={'Add Invoice For Delivery'} />
					<AddInvoiceFromRefundForDelivery orderId={selectedOrderId} handleClose={closeModal} />
				</Modal.Body>
			</Modal>
			<Modal show={showUser} onHide={closeModal}>
				<Modal.Body>
					<ModalHeader handleClose={closeModal} titleHeader={'Add Invoice For User'} />
					<AddInvoiceFromRefundForUser orderId={selectedOrderId} handleClose={closeModal} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default CancellationRequest
