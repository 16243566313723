export const ProductType = (instance: any) => ({
	async updateType(id: any, body: { type: string }) {
		const { data } = await instance.patch(`/product-type/update/${id}`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/product-type/delete/${id}`)
		return data
	},
	async changeStatus(body: { product_id: string; type_id: number; status: string }) {
		const { data } = await instance.patch(`/product-type/change-status`, body)
		return data
	},
})
