import { FC, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ModalHeader } from '../../Modals/ModalHeader'
import useTranslate from '../../../_theme/helpers/translate'

type Props = {
	active: number
	setActive: any
}
export const Slider: FC<Props> = ({ active, setActive }) => {
	const translate = useTranslate()
	return (
		<>
			<div className='w-200'>
				<ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
					<li className='nav-item cursor-pointer'>
						<a
							className={`nav-link text-active-primary pb-4 ${active == 1 ? 'active' : ''}`}
							data-bs-toggle='tab'
							// href='#kt_user_view_overview_tab'
							onClick={() => setActive(1)}
						>
							{translate('CARD.TEXT.ORDERS')}
						</a>
					</li>

					<li className='nav-item cursor-pointer'>
						<a
							className={`nav-link text-active-primary pb-4 ${active == 2 ? 'active' : ''}`}
							data-kt-countup-tabs='true'
							data-bs-toggle='tab'
							// href='#kt_user_view_overview_security'
							onClick={() => setActive(2)}
						>
							{translate('CARD.TEXT.HISTORY')}
						</a>
					</li>

					<li className='nav-item cursor-pointer'>
						<a
							className={`nav-link text-active-primary pb-4 me-5 ${active == 3 ? 'active' : ''}`}
							data-bs-toggle='tab'
							// href='#kt_user_view_overview_events_and_logs_tab'
							onClick={() => setActive(3)}
						>
							{translate('CARD.TEXT.REFUNDS')}
						</a>
					</li>
				</ul>
			</div>
		</>
	)
}
