import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import BranchIem from '../../../../components/BranchItem'
import AddBranch from './modals/AddBranch'
import { useAppSelector } from '../../../../../setup/hooks/redux'
import { Api } from '../../../../Services/api'
import { IOrgonizationFilial } from '../../../organization/models/OrganizationFilialModel'
import useTranslate from '../../../../../_theme/helpers/translate'
import { SearchComponent } from '../../../../components/SearchBox'
import Pagination from '../../../../components/Pagination'

const Branches = () => {
	const { id } = useParams()
	const { refresh } = useAppSelector((state) => state.refresh)
	const translate = useTranslate()
	const [filials, setFilials] = useState<IOrgonizationFilial[]>([])
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [total, setToltal] = useState(1)
	const [searchKeyClick, setSearchKeyClick] = useState('')
	const [statusSelect, setStatusSelect] = useState('')

	useEffect(() => {
		const q = `&organization_id=${id}`
		const search = searchKeyClick ? `&name=${searchKeyClick}` : ''
		Api()
			.organization.getAllFilials(100, 1, `${q}${search}${statusSelect}`)
			.then((res) => {
				setFilials(res?.items)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [refresh, searchKeyClick, statusSelect, currentPage])

	// useEffect(() => {
	//   Api()
	//     .organization.getAll('')
	//     .then((res) => {
	//       setBranches(res?.items)
	//     })
	//     .catch((err) => {})
	// }, [])

	return (
		<div>
			<div className='card card-flush'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					<div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>
							<SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Search'} />
						</div>
					</div>
					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						<div className='w-100 mw-150px'>
							<select className='form-select form-select-solid' onChange={(e) => setStatusSelect(e.target.value)} data-control='select2' data-hide-search='true' data-placeholder='Status' data-kt-ecommerce-product-filter='status'>
								<option disabled>{translate('CARD.TEXT.STATUS')}</option>
								<option value=''>{translate('STATUS.TEXT.ALL')}</option>
								<option value='&status=active'>{translate('STATUS.TEXT.ACTIVE')}</option>
								<option value='&status=pending'>{translate('STATUS.TEXT.PENDING')}</option>
								<option value='&status=rejected'>{translate('STATUS.TEXT.REJECTED')}</option>
								<option value='&status=not_working'>{translate('STATUS.TEXT.NOT_WORKING')}</option>
							</select>
						</div>
						<div onClick={handleOpenModal} className='btn btn-primary'>
							{translate('CARD.TEXT.ADD_BRANCH')}
						</div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px  scroll-x'>
					<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_branches_table'>
						<thead>
							<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
								<th className='min-w-200px'>{translate('TABLE.TITLE.FILIAL')}</th>
								<th className='min-w-100px'>{translate('TABLE.TITLE.ORGANIZATION')}</th>
								<th className='min-w-100px'>{translate('TABLE.TITLE.MANAGER_NAME')}</th>
								<th className='min-w-70px'>{translate('TABLE.TITLE.MANAGER_PHONE')}</th>
								<th className='min-w-100px'>{translate('TABLE.TITLE.ADDRESS')}</th>
								<th className='min-w-100px'>{translate('TABLE.TITLE.STATUS')}</th>
								<th className='min-w-70px'>{translate('TABLE.TITLE.ACTION')}</th>
							</tr>
						</thead>
						<tbody className='fw-bold text-gray-600'>
							{filials?.map((item, index) => {
								return <BranchIem key={index} item={item} />
							})}
						</tbody>
					</table>
					<Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} />
				</div>
			</div>
			<AddBranch update={false} isOpen={showModal} onClose={handleCloseModal} />
		</div>
	)
}

export default Branches
