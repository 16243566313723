import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IOrderModel } from '../../models/OrderModel'
import moment from 'moment'
import { OrderCollectingStatus, OrderDeliveryStatus } from '../../../_theme/helpers/enums/OrderStatus'
import { setOrder } from '../../pages/orders/reducers/OrdersSlice'
import { Modal } from 'react-bootstrap'
import { ModalHeader } from '../../Modals/ModalHeader'
import AssignCourierModal from '../../Modals/AssignCourier'
import useTranslate from '../../../_theme/helpers/translate'

type Props = {
	item: IOrderModel
}

const OrderItem: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const handleCloseModal = () => setShowModal(false)
	const handleOpenModal = () => setShowModal(true)
	const nav = () => {
		navigate(`/orders/${item.id}`)
	}
	return (
		<>
			<tr>
				<td onClick={nav} className='cursor-pointer'>
					{' '}
					<div className=''>{item?.id}</div>
				</td>

				<td className='text-end pe-0'>
					<div className=''>
						{item?.main_order?.user?.surname} {item?.main_order?.user?.name}
					</div>
				</td>
				<td className='text-end pe-0'>{item?.current_courier?.name}</td>
				<td className='text-end pe-0'>{item?.current_filial?.name}</td>
				<td className='text-end pe-0'>
					<div className=''> {moment(item?.created).format('DD.MM.YYYY')}</div>
				</td>
				<td className='text-end pe-0'>{moment(item?.updated).format('DD.MM.YYYY')}</td>
				<td className='text-end pe-0'>
					{item.delivery_status === OrderDeliveryStatus.DELIVERED ? (
						<div className='badge badge-light-success '>{item.delivery_status}</div>
					) : item.delivery_status === OrderDeliveryStatus.ON_WAY ? (
						<div className='badge badge-light-danger '>{item.delivery_status}</div>
					) : item.delivery_status === OrderDeliveryStatus.IN_FILIAL ? (
						<div className='badge badge-light-warning '>{item.delivery_status}</div>
					) : (
						<div className='badge badge-light-dark '>{item.delivery_status}</div>
					)}
				</td>
				<td className='text-end pe-0'>
					{item.collecting_status === OrderCollectingStatus.READY ? (
						<div className='badge badge-light-success '>{item.collecting_status}</div>
					) : item.collecting_status === OrderCollectingStatus.PENDING ? (
						<div className='badge badge-light-danger '>{item.collecting_status}</div>
					) : item.collecting_status === OrderCollectingStatus.COLLECTING ? (
						<div className='badge badge-light-warning '>{item.collecting_status}</div>
					) : (
						<div className='badge badge-light-dark '>{item.collecting_status}</div>
					)}
				</td>
				<td className='text-end pe-0'>
					<div className='btn btn-sm btn-light btn-active-light-primary' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						{translate('TABLE.TITLE.ACTION')}
						<span className='svg-icon svg-icon-5 m-0'>
							<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
								<path
									d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
									fill='currentColor'
								/>
							</svg>
						</span>
					</div>

					<div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
						<div className='menu-item ps-3'>
							<div onClick={handleOpenModal} className='menu-link px-3' data-kt-ecommerce-product-filter='delete_row'>
								Assign Cuorier
							</div>
						</div>
						<div className='menu-item px-3'>
							<div className='menu-link px-3'>Edit</div>
						</div>
						<div className='menu-item px-3'>
							<div className='menu-link px-3' data-kt-ecommerce-product-filter='delete_row'>
								Delete
							</div>
						</div>
					</div>
				</td>
			</tr>
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={'Assign Cuorier'} />
					<AssignCourierModal orderId={item.id} handleClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
		</>
	)
}

export default OrderItem
