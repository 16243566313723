import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { setAddProduct } from '../../../reducers/ProductSlice'
import { Debounce } from '../../../../../../_theme/helpers'
import useTranslate from '../../../../../../_theme/helpers/translate'

function Inventory() {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)

	const changeHandlerArticle = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, article: e.target.value }))
	}, 1000)
	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.MINVENTORY')} </h2>
				</div>
			</div>

			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className='mb-10 fv-row'>
					<label className='required form-label'>SKU</label>

					<input onChange={changeHandlerArticle} defaultValue={addProduct.article} type='text' name='sku' className='form-control mb-2' placeholder='SKU Number' />

					<div className='text-muted fs-7'>{translate('CARD.TEXT.ENTER_THE_PRODUCT_SKU')} </div>
				</div>
				<div className='fv-row'>
					<label className='form-label'>{translate('CARD.TEXT.ALLOW_BACKORDERS')} </label>

					<div className='form-check form-check-custom form-check-solid mb-2'>
						<input name='AllowBackorders' defaultChecked={addProduct.is_reproducible} onChange={(e) => dispatch(setAddProduct({ ...addProduct, is_reproducible: e.target.checked }))} className='form-check-input' type='checkbox' />
						<label className='form-check-label'>{translate('CARD.TEXT.YES')}</label>
					</div>

					<div className='text-muted fs-7'>{translate('CARD.TEXT.ALLOW_CUSTOMERS_TO_PURCHASE_PRODUCTS_THAT_ARE_OUT_OF_STOCK')}</div>
				</div>
				<div className='fv-row my-3'>
					<label className='form-label'>{translate('CARD.TEXT.IS_WHOLESALE')} </label>

					<div className='form-check form-check-custom form-check-solid mb-2'>
						<input defaultChecked={addProduct.is_wholesale} onChange={(e) => dispatch(setAddProduct({ ...addProduct, is_wholesale: e.target.checked }))} name='is_wholesale' className='form-check-input' type='checkbox' />
						<label className='form-check-label'>{translate('CARD.TEXT.YES')}</label>
					</div>

					<div className='text-muted fs-7'>{translate('CARD.TEXT.WHOLESALE_TERMS')}</div>
				</div>
				<div className='fv-row my-3'>
					<label className='form-label'>{translate('CARD.TEXT.CAN_NOT_REFUND')}</label>

					<div className='form-check form-check-custom form-check-solid mb-2'>
						<input defaultChecked={!addProduct.can_refund} onChange={(e) => dispatch(setAddProduct({ ...addProduct, can_refund: !e.target.checked }))} name='can_refund' className='form-check-input' type='checkbox' />
						<label className='form-check-label'>{translate('CARD.TEXT.YES')}</label>
					</div>

					<div className='text-muted fs-7'>{translate('CARD.TEXT.THE_ORDER_IS_NON-REFUNDABLE')}</div>
				</div>
			</div>
		</div>
	)
}

export default Inventory
