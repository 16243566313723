import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import { OrderDeliveryStatus } from '../../../../_theme/helpers/enums/OrderStatus'
import { IHistoryOrder } from '../../../models/HistoryOrderModel'
import useTranslate from '../../../../_theme/helpers/translate'

type Props = {
	item: IHistoryOrder
}

const HistoryOrderItem: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	const navigate = useNavigate()

	const nav = () => {
		navigate(`/orders/${item?.order?.id}`)
	}
	return (
		<>
			<tr>
				<td onClick={nav} className='cursor-pointer'>
					{' '}
					<div className=''>{item?.order?.id}</div>
				</td>

				<td className='text-end pe-0'>
					<div className=''>{item?.order?.amount}</div>
				</td>
				<td className='text-end pe-0'>
					<div className=''> {moment(item?.created).format('DD.MM.YYYY')}</div>
				</td>
				<td className='text-end pe-0'>{moment(item?.updated).format('DD.MM.YYYY')}</td>
				<td className='text-end pe-0'>
					{item.delivery_status === OrderDeliveryStatus.DELIVERED ? (
						<div className='badge badge-light-success '>{item.delivery_status}</div>
					) : item.delivery_status === OrderDeliveryStatus.ON_WAY ? (
						<div className='badge badge-light-danger '>{item.delivery_status}</div>
					) : item.delivery_status === OrderDeliveryStatus.IN_FILIAL ? (
						<div className='badge badge-light-warning '>{item.delivery_status}</div>
					) : (
						<div className='badge badge-light-dark '>{item.delivery_status}</div>
					)}
				</td>
				<td className='text-end pe-0'>
					<div className='btn btn-sm btn-light btn-active-light-primary' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						{translate('TABLE.TITLE.ACTION')}
						<span className='svg-icon svg-icon-5 m-0'>
							<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
								<path
									d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
									fill='currentColor'
								/>
							</svg>
						</span>
					</div>
				</td>
			</tr>
		</>
	)
}

export default HistoryOrderItem
