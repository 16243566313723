import React from 'react'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { OrderDeliveryStatus } from '../../../../_theme/helpers/enums/OrderStatus'
import useTranslate from '../../../../_theme/helpers/translate'

const DocumentsCard = () => {
	const translate = useTranslate()
	const { refund } = useAppSelector((state) => state.refunds)
	return (
		<div className='card card-flush py-4 flex-row-fluid'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.STATUS')}</h2>
				</div>
			</div>
			<div className='card-body pt-0'>
				<div className='table-responsive'>
					<table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
						<tbody className='fw-bold text-gray-600'>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
												<path d='M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z' fill='currentColor' />
												<path opacity='0.3' d='M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z' fill='currentColor' />
											</svg>
										</span>
										{translate('TABLE.TITLE.DELIVERY_STATUS')}
									</div>
								</td>
								<td className='fw-bolder text-end'>
									{refund?.delivery_status === OrderDeliveryStatus?.DELIVERED ? (
										<div className='badge badge-light-success '>{translate(`STATUS.TEXT.${refund.delivery_status?.toUpperCase()}`)}</div>
									) : refund?.delivery_status === OrderDeliveryStatus.ON_WAY ? (
										<div className='badge badge-light-danger '>{translate(`STATUS.TEXT.${refund.delivery_status?.toUpperCase()}`)}</div>
									) : refund?.delivery_status === OrderDeliveryStatus.IN_FILIAL ? (
										<div className='badge badge-light-warning '>{translate(`STATUS.TEXT.${refund.delivery_status?.toUpperCase()}`)}</div>
									) : (
										<div className='badge badge-light-dark '>{translate(`STATUS.TEXT.${refund.delivery_status?.toUpperCase()}`)}</div>
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default DocumentsCard
