import React, { FC, useCallback, useEffect, useRef } from 'react'
import { IOrderModel } from '../models/OrderModel'
import { toAbsoluteUrl } from '../../_theme/helpers'
import { toJpeg } from 'html-to-image'
import jsPDF from 'jspdf'
import QRCode from 'react-qr-code'
import Barcode from 'react-barcode'
import useTranslate from '../../_theme/helpers/translate'

type Props = {
	order: IOrderModel
}

const pixel_to_mm = (pixelValue: number, dpi: number) => {
	const inchValue = pixelValue / dpi
	const mmValue = inchValue * 25.4
	return mmValue
}

const InfoPrintForDelivery: FC<Props> = ({ order }) => {
	const modalRef = useRef<HTMLDivElement>(null)
	const translate = useTranslate()
	const filter = (node: HTMLElement) => {
		const exclusionClasses = ['remove-me']
		return !exclusionClasses.some((classname) => node.classList?.contains(classname))
	}
	const handleDownloadPdf = useCallback(() => {
		if (modalRef.current === null) {
			return
		}
		toJpeg(modalRef.current, { filter: filter, backgroundColor: '#fff' })
			.then((dataUrl: any) => {
				const pdf = new jsPDF()
				const img = new Image()
				img.src = dataUrl
				img.onload = () => {
					const imgWidth = 210 // A4 genişlik (mm)
					const imgHeight = (297 * img.height) / img.width

					pdf.addImage(img.src, 'PNG', 0, 0, imgWidth, imgHeight)
					pdf.save(`${'test'}.pdf`)
				}
			})
			.catch((error) => console.error('Error converting to PDF:', error))
	}, [modalRef])

	return (
		<div ref={modalRef} id='element-to-capture'>
			<div className='mw-lg-950px mx-auto w-100'>
				<div className=' mt-4 d-flex justify-content-between align-items-center'>
					<div>
						<img src={toAbsoluteUrl('/media/turkishasia.png')} className='h-100px' alt='TurkishAsia Logo' />
					</div>
					<div>
						<h5 className='fw-bold display-5'>{order?.delivery_companies && order?.delivery_companies[0].name}</h5>
					</div>
				</div>
				<div className=' mt-4 d-flex justify-content-between'>
					<div className='card w-50 mx-2'>
						<div className='card-header'>
							<div className='card-title'>
								<h2>{translate('CARD.TEXT.CUSTOMER_DETAILS')}</h2>
							</div>
						</div>
						<div className='card-body pt-0 min-h-300px  scroll-x'>
							<div className='table-responsive'>
								<table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
									<tbody className='fw-bold text-gray-600'>
										<tr>
											<td className='text-muted'>
												<div className='d-flex align-items-center'>Order No</div>
											</td>
											<td className='fw-bolder text-end'>{order?.id}</td>
										</tr>
										<tr>
											<td className='text-muted'>
												<div className='d-flex align-items-center'>
													{' '}
													<h2>{translate('CARD.TEXT.CUSTOMER')}</h2>
												</div>
											</td>
											<td className='fw-bolder text-end'>
												<div className='d-flex align-items-center justify-content-end'>
													<div className='text-gray-600 text-hover-primary'>
														{order?.main_order?.user.surname} {order?.main_order?.user?.name}
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td className='text-muted'>
												<div className='d-flex align-items-center'>{translate('FORM.INPUT.ADDRESS')}</div>
											</td>
											<td className='fw-bolder text-end'>{order?.address?.title}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className='card w-50 p-5 mx-2'>
						<h3>Delivery QR code</h3>
						<div className='mb-3'>
							<div style={{ height: 'auto', maxWidth: 130, width: '100%' }}>
								<QRCode size={256} style={{ height: 'auto', maxWidth: '100%', width: '100%' }} value={order.id.toString()} viewBox={`0 0 256 256`} />
							</div>
						</div>
						<h3 className='mb-0'>Delivery Barcode code</h3>
						<div>
							<div style={{ height: 'auto', maxWidth: 130, width: '100%' }}>
								<Barcode value={order.id.toString()} />
							</div>
						</div>
					</div>
				</div>

				<div className='mt-5 mx-2'>
					<div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
						<div className='card-body pt-0 min-h-300px  scroll-x'>
							<div className='table-responsive'>
								<table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
									<thead>
										<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
											<th className='min-w-175px'>{translate('TABLE.TITLE.PRODUCT')}</th>
											<th className='min-w-100px text-end'>{translate('TABLE.TITLE.SKU')}</th>
											<th className='min-w-70px text-end'>{translate('TABLE.TITLE.QTY')}</th>
										</tr>
									</thead>
									<tbody className='fw-bold text-gray-600'>
										{order?.order_products?.map((item, index) => {
											return (
												<tr key={index}>
													<td>
														<div className='d-flex align-items-center'>
															<div className='ms-5'>
																<div className='fw-bolder text-gray-600 text-hover-primary'>{item?.product?.title}</div>
															</div>
														</div>
													</td>
													<td className='text-end'>{item?.product?.article}</td>
													<td className='text-end'>{item?.amount}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className='d-flex remove-me justify-content-center mt-5'>
					<button type='button' onClick={handleDownloadPdf} className='btn remove-me btn-primary my-1'>
						Print
					</button>
				</div>
			</div>
		</div>
	)
}

export default InfoPrintForDelivery
