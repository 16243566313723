import React, { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_theme/layout/core'
import { CompanyHeader } from './CompanyHeader'
import UpdatedCompany from './components/Details'
import Reviews from './components/reviews/Reviews'
import AddProduct from '../products/AddProduct'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../setup/hooks/redux'
import { Api } from '../../Services/api'
import { setCompany } from './reducers/CompanySlice'
import { Settings } from './components/settings/Settings'
import Branches from './components/Branches/Branches'
import Orders from '../orders/Orders'
import Products from '../products/Products'
import useTranslate from '../../../_theme/helpers/translate'
import CancellationRequest from '../cancellationRequest/cancellationRequest'

const companyBreadCrumbs: Array<PageLink> = [
	{
		title: 'Organizations',
		path: '/organizations',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
]

const CompanyPage: React.FC<{ children?: React.ReactNode }> = () => {
	const { id } = useParams()
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { refresh } = useAppSelector((state) => state.refresh)

	useEffect(() => {
		Api()
			.organization.getOne(id)
			.then((res) => {
				dispatch(setCompany(res))
			})
			.catch((err) => {
				console.log(err)
			})
	}, [refresh])
	return (
		<Routes>
			<Route
				element={
					<>
						<CompanyHeader />
						<Outlet />
					</>
				}
			>
				<Route
					path='detail'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.ABOUT')}</PageTitle>
							<UpdatedCompany />
						</>
					}
				/>
				<Route
					path='reservations'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.CUSTOMERS')}</PageTitle>
						</>
					}
				/>
				<Route
					path='products'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.PRODUCTS')}</PageTitle>
							<Products req={`&organization_ids=${id}`} />
						</>
					}
				/>

				<Route
					path='branches'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.PRODUCTS')}</PageTitle>
							<Branches />
						</>
					}
				/>
				<Route
					path='add-product'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>Add Products</PageTitle>
							<AddProduct />
						</>
					}
				/>
				<Route
					path='reviews'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.REVIEWS')}</PageTitle>
							<Reviews />
						</>
					}
				/>
				<Route
					path='orders'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.PRODUCTS')}</PageTitle>
							<Orders req={`&organization_id=${id}`} />
						</>
					}
				/>
				<Route
					path='returns'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.REFUNDS')}</PageTitle>
							<CancellationRequest req={`&organization_id=${id}`} />
						</>
					}
				/>
				<Route
					path='setting'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.ABOUT')}</PageTitle>
							<Settings />
						</>
					}
				/>
				<Route index element={<Navigate to={`./detail`} />} />
			</Route>
		</Routes>
	)
}

export default CompanyPage
