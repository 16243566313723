import React, { FC } from 'react'
import { fileIconName, fileName, toAbsoluteUrl } from '../../_theme/helpers'

type Props = {
	fileLink: string
	name: string
	size: string
}

export const FileItem: FC<Props> = ({ fileLink, name, size }) => {
	return (
		<div className='overlay me-10'>
			<div className='overlay-wrapper d-flex justify-content-center  text-start flex-column p-5'>
				<a href={fileLink} className='text-gray-800 text-hover-primary d-flex flex-row'>
					<div className='symbol symbol-60px mb-6 mr-6'>
						<img src={toAbsoluteUrl(`/media/svg/files/${fileIconName(fileLink)}.svg`)} alt='' />
					</div>
					<div className='pl-10'>
						<div
							style={{
								width: '200px',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
							className='fs-5 fw-bolder mb-1 ml-4'
						>
							{fileName(name)}
						</div>
					</div>
				</a>
			</div>
		</div>
	)
}
