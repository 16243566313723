import IAddCategory from '../pages/categories/models/AddCategoryModel'

export const CategoriesApi = (instance: any) => ({
	async create(body: IAddCategory) {
		const { data } = await instance.post(`/category/create`, body)
		return data
	},
	async update(id: number, body: any) {
		const { data } = await instance.patch(`/category/update/${id}`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/category/delete/${id}`)
		return data
	},
	async trees() {
		const { data } = await instance.get(`/category/all/trees`)
		return data
	},
	async getAll(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/category/all?limit=${limit}&page=${page}${q}`)
		return data
	},
})
