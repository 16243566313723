export const UserApi = (instance: any) => ({
	async getCouriers(id: any) {
		const { data } = await instance.get(`/user/all?id=${id}&select=avatar,organization,manage_delivery_filial,courier_filial,delivery_company,delivery_company2,manage_organization_filial`)
		return data
	},
	async lockUnlock(link: string, body: any) {
		const { data } = await instance.patch(`/user/${link}`, body)
		return data
	},
	async resetPassword(body: any) {
		const { data } = await instance.patch(`/auth/password/reset`, body)
		return data
	},

	// async getLock(id: any) {
	// 	const { data } = await instance.get(`/user/unlock`)
	// 	return data
	// },
})
