import React, { FC, useState } from 'react'
import { Api } from '../Services/api'
import { useDispatch } from 'react-redux'
import { setRefresh } from '../reducers/RefreshSlice'
import { errorMsg, successMsg } from '../../_theme/helpers/AlertMsg'
import useTranslate from '../../_theme/helpers/translate'

type Props = {
	filialId: string | number
	handleClose: any
}

export const OrganizationFilialStatus = ['rejected', 'not_working', 'active', 'pending']

const ChangeDeliveryFilialStatus: FC<Props> = ({ filialId, handleClose }) => {
	const [selectedStatus, setSelectedStatus] = useState<string>('')
	const dispatch = useDispatch()
	const translate = useTranslate()

	const handleSubmit = () => {
		const body = {
			status: selectedStatus,
		}
		Api()
			.deliveries.filialStatusChange(filialId, body)
			.then((res) => {
				successMsg('Success')
				dispatch(setRefresh())
				handleClose()
			})
			.catch((err) => {
				errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
			})
	}
	return (
		<div className='card card-flush py-4'>
			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className=''>
					<div className='mb-4 pt-0'>
						<label className='form-label'>{translate('CARD.TEXT.STATUS')}</label>
						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' onChange={(e: any) => setSelectedStatus(e.target.value)} defaultValue={selectedStatus} data-placeholder='Select Product' name='status'>
							<option disabled>{translate('CARD.TEXT.STATUS')}</option>
							{OrganizationFilialStatus?.map((item: any) => {
								return <option value={item}>{translate(`STATUS.TEXT.${item.toUpperCase()}`)}</option>
							})}
						</select>
					</div>
				</div>
				<div className='mt-5 w-100 text-center'>
					<div onClick={handleClose} className='btn btn-light me-3 me-1'>
						{translate(`FORM.BUTTON.CENCEL`)}
					</div>
					<div onClick={handleSubmit} className='btn btn-primary'>
						{translate(`FORM.BUTTON.SEND`)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ChangeDeliveryFilialStatus
