export const fileIconName = (param: string): string => {
	const icon = param.split('.')
	const img = icon[Number(icon?.length) - 1]
	return img
}
export const fileName = (param: string): string => {
	const icon = param.split('/')
	const img = icon[Number(icon?.length) - 1]
	return img
}
