import React, { useEffect, useState } from 'react'
import CourierItem from '../../components/CouirerItem'
import CourierInfo from './CourierInfo'
import { Slider } from './Slider'
import { useParams } from 'react-router-dom'
import { Api } from '../../Services/api'

import { useAppSelector } from '../../../setup/hooks/redux'
import Orders from '../orders/Orders'
import OrdersComponent from '../../components/Orders/Orders'
import { LocationMap } from '../../../_theme/partials/content/DrawPolygon/LocationMap'
import { ICourier } from '../../models/CourierModal'
import HistoryOrders from './components/HistoryOrders'
import CancellationRequest from '../cancellationRequest/cancellationRequest'

const Detail = () => {
	const { id } = useParams()
	const { refresh } = useAppSelector((state) => state.refresh)
	const [active, setActive] = useState(1)
	const [courier, setCourier] = useState<ICourier>({} as ICourier)
	const [show, setShow] = useState(false)

	useEffect(() => {
		Api()
			.user.getCouriers(id)
			.then((res) => {
				console.log(res)

				setCourier(res?.items[0])
				setShow(true)
			})
	}, [refresh])

	return (
		<div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
			<div className='d-flex flex-column flex-column-fluid'>
				<div id='kt_app_content' className='app-content flex-column-fluid'>
					<div id='kt_app_content_container' className='app-container container-fluid p-0'>
						<div className='d-flex flex-column flex-lg-row '>
							<CourierInfo item={courier} />
							<div className='w-100 pt-0 p-10 pe-0 overflow-hidden'>
								<Slider active={active} setActive={setActive} />
								<div className='tab-content' id='myTabContent'>
									<div className='tab-pane fade show active' id='kt_user_view_overview_tab' role='tabpanel'>
										{active === 1 && <OrdersComponent status={'active'} />}
										{active === 2 && <HistoryOrders />}
										{active === 3 && <CancellationRequest req={`&current_courier_id=${id}`} />}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Detail
