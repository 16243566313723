export const RefundApi = (instance: any) => ({
	async createRefundType(body: { type: any }) {
		const { data } = await instance.post(`/refund-type/create`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/brand/delete`)
		return data
	},
	async getAllRefundType(limit: number = 100, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/refund-type/all?limit=${limit}&page=${page}&sortType=DESC`)
		return data
	},
	async getAllRefunds(limit: number = 100, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/refund/find/all?limit=${limit}&page=${page}&sortType=DESC${q}&select=qr_code,type,current_courier,current_filial,order,order_product,main_order,user,product,specifications,delivery_companies,organization,organization_filial,worker_transaction_files,delivery_transaction_files,user_transaction_files`)
		return data
	},
	async getOneRefund(id: number | string) {
		const { data } = await instance.get(`/refund/one/${id}`)
		return data
	},
	async imageUpload(body: any) {
		const { data } = await instance.post(`/brand/files/upload`, body)
		return data
	},
	async selectResponsible(id: any, body: { responsible: any }) {
		const { data } = await instance.put(`/refund/check/${id}`, body)
		return data
	},
})
