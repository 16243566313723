export const CommentApi = (instance: any) => ({
	async createComment(body: any) {
		const { data } = await instance.post(`/comment/product/create`, body)
		return data
	},
	async createCommentForParent(body: any) {
		const { data } = await instance.post(`/comment/parent/create`, body)
		return data
	},
	async createCommentForOrgonization(body: any) {
		const { data } = await instance.post(`/comment/organization/create`, body)
		return data
	},
	async getComment(limit: string | number = 100, page: string | number = 1, id: string | number) {
		const { data } = await instance.get(`/comment/all?limit=${limit}&page=${page}&sortType=DESC&product_id=${id}&select=user`)
		return data
	},
	async getOrganizationComment(limit: string | number = 100, page: string | number = 1, id: string | number) {
		const { data } = await instance.get(`/comment/all?limit=${limit}&page=${page}&sortType=DESC&organization_id=${id}&select=user`)
		return data
	},
})
