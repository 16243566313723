import React, { ChangeEvent, FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Specification } from '../../../models/ProductModel'
import { Api } from '../../../Services/api'
import Swal from 'sweetalert2'
import { setRefresh } from '../../../reducers/RefreshSlice'
import { useAppSelector } from '../../../../setup/hooks/redux'

type Props = {
	spec: Specification
	handleClose: any
}

const Variations: FC<Props> = ({ spec, handleClose }) => {
	const dispatch = useDispatch()
	const { product } = useAppSelector((state) => state.product)
	const [specData, setSpecData] = useState(spec.value)
	const [loading, setLoading] = useState(false)

	const submit = () => {
		const body = {
			value: specData,
		}
		Api()
			.productSpec.updateValue(spec.id, body)
			.then((res) => {
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: res?.message,
					showConfirmButton: false,
					timer: 1500,
				})
				dispatch(setRefresh())
				handleClose()
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				Swal.fire({
					icon: 'error',
					title: err?.response?.data?.statusCode,
					text: err?.response?.data?.message,
				})
			})
	}
	const onChangeStatus = () => {
		Api()
			.productSpec.changeStatus({ product_id: product.id, specification_id: spec.id, status: spec.status == 'published' ? 'unpublished' : 'published' })
			.then((res) => {
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: res?.message,
					showConfirmButton: false,
					timer: 1500,
				})
				dispatch(setRefresh())
				handleClose()
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				Swal.fire({
					icon: 'error',
					title: err?.response?.data?.statusCode,
					text: err?.response?.data?.message,
				})
			})
	}
	function remove() {
		Swal.fire({
			title: 'The specification will be deleted!',
			text: 'Do you want to delete the specification?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cencel',
			confirmButtonText: 'Delete',
		}).then(async (result) => {
			if (result.isConfirmed) {
				await Api()
					.productSpec.delete(spec.id)
					.then((res) => {
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: res?.message,
							showConfirmButton: false,
							timer: 1500,
						})
						dispatch(setRefresh())
					})
					.catch((err) => {
						Swal.fire({
							icon: 'error',
							title: err?.response?.data?.statusCode,
							text: err?.response?.data?.message,
						})
						console.log(err)
					})
			}
		})
	}

	return (
		<div className='card card-flush py-4'>
			<div className='card-body pt-0 scroll-x'>
				<div>
					<div className='my-3 mb-5'>
						<h3 className='h3'>Specifications</h3>
						<div className='mb-3 w-100 d-flex align-items-center justify-content-start'>
							<input
								defaultValue={specData}
								type='text'
								className='form-control mw-100 w-200px me-3'
								name='product_option_value'
								placeholder='Variation'
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									setSpecData(e.target.value)
								}}
							/>
						</div>
						<div className='form-group mt-5'>
							<button disabled={loading} onClick={submit} type='button' data-repeater-create='' className='btn btn-sm btn-light-primary me-3'>
								Update
							</button>
							{/* <button onClick={remove} disabled={loading} type='button' data-repeater-create='' className='btn btn-sm btn-light-danger me-3'>
								Delete
							</button>
							<button onClick={onChangeStatus} disabled={loading} type='button' data-repeater-create='' className='btn btn-sm btn-light'>
								{spec.status == 'published' ? 'unpublished' : 'published'}
							</button> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Variations
