import React, { useEffect, useState } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { useDispatch } from 'react-redux'
import { Api } from '../../../Services/api'
import { setAddProduct } from '../reducers/ProductSlice'
import AddBrnad from '../../brands/modals/AddBrand'
import useTranslate from '../../../../_theme/helpers/translate'

const ProductDetails = () => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)
	const { refresh } = useAppSelector((state) => state.brand)

	// States
	const [categories, setCategories] = useState([])
	const [brands, setBrands] = useState([])
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	const onSelectCategory = (selectedList: any, selectedItem: any) => {
		// dispatch(setCategory(selectedList.map(({id}: any) => id)))
		dispatch(setAddProduct({ ...addProduct, selectedCategories: selectedList }))
	}
	const onRemoveCategory = (selectedList: any, removedItem: any) => {
		dispatch(setAddProduct({ ...addProduct, selectedCategories: selectedList }))
	}
	const onSelectBrnad = (selectedList: any, selectedItem: any) => {
		// dispatch(setCategory(selectedList.map(({id}: any) => id)))
		dispatch(setAddProduct({ ...addProduct, selectBrand: selectedList }))
	}
	const onRemoveBrand = (selectedList: any, removedItem: any) => {
		dispatch(setAddProduct({ ...addProduct, selectBrand: selectedList }))
	}

	useEffect(() => {
		Api()
			.categories.getAll()
			.then((res) => {
				setCategories(res?.items)
			})
		Api()
			.brands.getAll(100, 1, '&status=published')
			.then((res) => {
				setBrands(res?.items)
			})
			.catch((err) => {
				console.log(err)
			})
		return () => {}
	}, [refresh])

	return (
		<div className='card py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.PRODUCT_DETAILS')}</h2>
				</div>
			</div>
			{showModal && <AddBrnad update={false} isOpen={showModal} onClose={handleCloseModal} />}
			<div className='card-body pt-0'>
				<label className='form-label'>Categories</label>

				<Multiselect
					options={categories} // Options to display in the dropdown
					selectedValues={addProduct.selectedCategories}
					avoidHighlightFirstOption={true}
					onSelect={onSelectCategory} // Function will trigger on select event
					onRemove={onRemoveCategory} // Function will trigger on remove event
					displayValue='title'
					// onSearch={function noRefCheck() {}}
					// showCheckbox // Property name to display in the dropdown options
					placeholder='Select'
					style={{
						chips: {
							background: '#4fc9da',
						},
						searchBox: {
							borderRadius: '10px',
						},
					}}
				/>
			</div>

			<div className='card-body pt-0'>
				<div className='d-flex justify-content-between'>
					<label className='form-label'>{translate('FORM.INPUT.BRAND')}</label>

					<i className='fa fa-plus fs-4 cursor-pointer' onClick={handleOpenModal}></i>
				</div>

				<Multiselect
					options={brands} // Options to display in the dropdown
					selectedValues={addProduct.selectBrand}
					avoidHighlightFirstOption={true}
					onSelect={onSelectBrnad} // Function will trigger on select event
					onRemove={onRemoveBrand} // Function will trigger on remove event
					displayValue='name'
					singleSelect={true}
					// onSearch={function noRefCheck() {}}
					// showCheckbox // Property name to display in the dropdown options
					placeholder='Select'
					style={{
						chips: {
							background: '#4fc9da',
						},
						searchBox: {
							borderRadius: '10px',
						},
					}}
				/>
			</div>
		</div>
	)
}

export default ProductDetails
