import React from 'react'
import ProductDetails from './components/ProductDetails'
import Status from './components/Status'
import Thumbnail from './components/Thumbnail'
import General from './components/General/General'
import Advanced from './components/Advanced/Advanced'
import { useAppSelector } from '../../../setup/hooks/redux'
import { Api } from '../../Services/api'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import ProductUsedCountries from './components/ProductUsedCountries'
import ProductRelations from './components/ProductRelations'
import DeliveryPaymant from './components/DeliveryPaymant'
import useTranslate from '../../../_theme/helpers/translate'

const AddProduct = () => {
	const translate = useTranslate()
	const navigate = useNavigate()
	const { addProduct } = useAppSelector((state) => state.product)
	const submit = () => {
		const body = {
			title: addProduct.productName,
			description: addProduct?.productDesc,
			base_price: addProduct.basePrice,
			article: addProduct?.article,
			main_picture: addProduct.mainPicture,
			child_pictures: addProduct.child_pictures,
			category_ids: addProduct.selectedCategories.map(({ id }: any) => id),
			relations: addProduct.relations.map(({ id }: any) => id),
			types: addProduct.types,
			// is_reproducible: addProduct?.is_reproducible,
			can_refund: addProduct?.can_refund,
			is_wholesale: addProduct?.is_wholesale,
			// from_country_id: addProduct.fromCountryId && Number(addProduct.fromCountryId[0]?.id),
			countries_for_sale_ids: addProduct.counrtiesForSaleIds.map(({ id }: any) => id),
			delivery_payment_by: addProduct.delivery_payment_by,
			limit_to_payment_delivery: addProduct.limit_to_payment_delivery,
			// bind_delivery: addProduct.bindDelivery,
			brand_id: addProduct.selectBrand && addProduct.selectBrand[0]?.id,
			size: {
				length: addProduct.length,
				width: addProduct.width,
				height: addProduct.height,
				weight: addProduct.weight,
			},
			discount:
				addProduct.discountType !== '1'
					? {
							procent: addProduct.discount,
							type: addProduct.discountType,
							start_date: addProduct.discountStartDate,
							end_date: addProduct.discountEndDate,
					  }
					: null,
			metas: [
				{
					name: addProduct.metaName,
					description: addProduct.metaDesc,
				},
			],
		}
		Api()
			.products.create(body)
			.then((res) => {
				navigate('../products')
			})
			.catch((err) => {
				Swal.fire({ title: 'Error', text: 'Error in Server', timer: 1500 })
			})
	}

	return (
		<div className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'>
			<div className='d-flex flex-column gap-7 gap-lg-10 max-w-300px mb-7 me-lg-10'>
				<Thumbnail />
				<Status />
				<ProductDetails />
				<ProductUsedCountries />
				<DeliveryPaymant />
				<ProductRelations />
				{/* <WeeklySales />
        <ProductTemplate /> */}
			</div>
			<div className='d-flex flex-column flex-row-fluid w-100 gap-7 gap-lg-10'>
				<div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
					<General />
					<Advanced />

					<div onClick={submit} className={`btn  btn-primary cursor-pointer `}>
						{translate('CARD.TEXT.SAVE')}
					</div>
				</div>
			</div>
		</div>
	)
}

export default AddProduct
