import { ChangeEvent, FC, useEffect, useState } from 'react'
import { IProduct, Specification, Type } from '../models/ProductModel'
import { Api } from '../Services/api'
import { IFilial } from '../pages/organization/models/OrganizationModel'
import { IDivedeProductType } from '../models/DivideProductModal'
import clsx from 'clsx'
import { errorMsg, successMsg } from '../../_theme/helpers/AlertMsg'
import { IOrgProdduct, ISpecificationElement } from '../models/OrgProductModel'
import { useDispatch } from 'react-redux'
import { setRefresh } from '../reducers/RefreshSlice'

type Props = {
	product_id: string | number
	product: IOrgProdduct
	update: boolean
	handleClose: any
}
function transformForSubmit(prdType: ISpecificationElement[]) {
	const groupedByTypeId: { [key: number]: IDivedeProductType } = {}

	prdType.forEach((spec) => {
		const typeId = spec.specification?.type_id
		if (typeId !== undefined) {
			if (!groupedByTypeId[typeId]) {
				groupedByTypeId[typeId] = {
					type_id: typeId,
					specifications: [],
				}
			}
			groupedByTypeId[typeId].specifications?.push({
				specification_id: spec.specification_id,
				amount: spec.amount,
			})
		}
	})
	const result: IDivedeProductType[] = Object.values(groupedByTypeId)
	return result
}

const DivideProduct: FC<Props> = ({ product_id, update, handleClose, product }) => {
	const dispatch = useDispatch()
	const [specifications, setSpecifications] = useState<ISpecificationElement[]>(product?.specifications)

	const handleInputChange = (specId: number = 0, value: string | number) => {
		const updatedSpecs = specifications.map((spec, i) => {
			if (spec.id == specId) {
				return {
					...spec,
					amount: Number(value),
				}
			}
			return spec
		})

		setSpecifications(updatedSpecs)
	}

	const handleSubmit = () => {
		const body = {
			product_id: product_id,
			divide_specifications: [
				{
					filial_id: product?.organization_filial_id,
					types: transformForSubmit(specifications),
				},
			],
		}
		Api()
			.organization.divideProduct(body)
			.then((res) => {
				successMsg('Success divided')
				dispatch(setRefresh())
				handleClose()
			})
			.catch((err) => {
				errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
			})
	}

	const renderSpecifications = () => {
		return specifications?.map((spec, specIndex) => (
			<div key={specIndex} className='my-3 mb-5'>
				<div className='mb-3 w-100 d-flex align-items-center justify-content-start'>
					<input type='text' className='form-control mw-100 w-200px me-3' name='product_option_name' placeholder='Variation' value={spec.specification.value} disabled />
					<input
						type='number'
						className='form-control mw-100 w-200px me-3'
						name={`product_option_value_${spec.id}`}
						id={`product_option_value_${spec.id}`}
						placeholder='Amount'
						defaultValue={spec.amount}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							handleInputChange(spec.id, e.target.value)
						}}
					/>
				</div>
			</div>
		))
	}

	return (
		<div className='card card-flush py-4'>
			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className=''>
					<div className='mb-4 pt-0'>
						<label className='form-label'>Orgonization Filials</label>
						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' defaultValue={''} data-placeholder='Select Product' name='status' disabled>
							<option value={''}>{product.organization_filial.name}</option>
						</select>
					</div>
					{/* <div className='col mb-7'>
                        <label className='required fw-bold fs-8 mb-2'>Total Amount</label>
                        <input
                            placeholder='Total Amount'
                            type='number'
                            name='amount'
                            onChange={handlerSetAmoubt}
                            className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                            autoComplete='off'
                        />
                    </div> */}
					<h3 className='h3'>Specifications</h3>
					{renderSpecifications()}
				</div>
				<div className='mt-5 w-100 text-center'>
					<div onClick={handleClose} className='btn  me-1'>
						Cancel
					</div>
					<div onClick={handleSubmit} className='btn btn-primary'>
						Save
					</div>
				</div>
			</div>
		</div>
	)
}

export default DivideProduct
