import { FC, lazy, Suspense } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_theme/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardsPageWrapper } from '../pages/dashboards/DashboardsPageWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_theme/assets/ts/_utils'
import { PageLink, PageTitle } from '../../_theme/layout/core'

import CompanyPage from '../pages/company-detail/CompanyPage'
import ProductPage from '../pages/product-detail/ProductPage'
import { ProductsPageWrapper } from '../pages/products/ProductsPageWrapper'
import CancellationRequest from '../pages/cancellationRequest/cancellationRequest'
import Branches from '../pages/ branches/ Branches'
import BranchesPage from '../pages/branches-detail/BranchPage'
import Orders from '../pages/orders/Orders'
import OrderDetail from '../pages/orders/OrderDetail'
import RefundType from '../pages/refund-type/RefundType'
import DeliveryBranchPage from '../pages/delivery-detail/components/delivery-branches/DeliveryBranchPage'
import DeliveryPage from '../pages/delivery-detail/DeliveryPage'
import CourierPage from '../pages/courier/CourierPage'
import Couriers from '../pages/courier/Couriers'
import useTranslate from '../../_theme/helpers/translate'
import RefundDetail from '../pages/cancellationRequest/RefundDetail'

const PrivateRoutes = () => {
	const translate = useTranslate()
	const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
	const Categories = lazy(() => import('../pages/categories/Categories'))
	const Brands = lazy(() => import('../pages/brands/Brands'))
	const Docs = lazy(() => import('../pages/docs/AgreeDocs'))
	const Organizations = lazy(() => import('../pages/organization/Organizations'))
	const Deliveries = lazy(() => import('../pages/deliveries/Deliveries'))
	const Address = lazy(() => import('../pages/address/Address'))
	const DeliveryFilials = lazy(() => import('../pages/delivery-filials/Deliveries'))

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{/* Redirect to Dashboard after success login/registration */}
				<Route path='auth/*' element={<Navigate to='/dashboards' />} />
				{/* Pages */}
				<Route path='dashboards/*' element={<DashboardsPageWrapper />} />

				<Route
					path='product/:id/*'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.PRODUCTS')}</PageTitle>
							<ProductPage />
						</>
					}
				/>
				<Route
					path='branches'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.BRANCHES')}</PageTitle>
							<Branches />
						</>
					}
				/>
				<Route
					path='products/*'
					element={
						<SuspensedView>
							<ProductsPageWrapper />
						</SuspensedView>
					}
				/>

				<Route
					path='categories'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.CATEGORIES')}</PageTitle>
							<Categories />
						</>
					}
				></Route>
				<Route
					path='orders'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.ORDERS')}</PageTitle>
							<Orders />
						</>
					}
				></Route>
				<Route
					path='orders/:id'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.ORDERS_DETAILS')}</PageTitle>
							<OrderDetail />
						</>
					}
				></Route>
				<Route
					path='refund/:id'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.ORDERS_DETAILS')}</PageTitle>
							<RefundDetail />
						</>
					}
				></Route>
				<Route
					path='returns'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.RETURNS')}</PageTitle>
							<CancellationRequest />
						</>
					}
				></Route>
				<Route
					path='brands'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.BRANDS')}</PageTitle>
							<Brands />
						</>
					}
				></Route>

				<Route
					path='docs'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.DOCS')}</PageTitle>
							<Docs />
						</>
					}
				></Route>

				<Route
					path='refund-type'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.RETURN_TYPES')}</PageTitle>
							<RefundType />
						</>
					}
				></Route>

				<Route
					path='company/:id/*'
					element={
						<SuspensedView>
							<CompanyPage />
						</SuspensedView>
					}
				/>
				<Route
					path='branches/:id/*'
					element={
						<SuspensedView>
							<BranchesPage />
						</SuspensedView>
					}
				/>
				<Route
					path='organization'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.ORGANIZATION')}</PageTitle>
							<Organizations />
						</>
					}
				></Route>

				<Route
					path='deliveries'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.DELIVERIES')}</PageTitle>
							<Deliveries />
						</>
					}
				></Route>
				<Route
					path='delivery-filials'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.DELIVERY_FILIALS')}</PageTitle>
							<DeliveryFilials />
						</>
					}
				></Route>
				<Route
					path='delivery/:id/*'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.DELIVERY_DETAIL')}</PageTitle>
							<DeliveryPage />
						</>
					}
				/>
				<Route
					path='delivery-branch/:id/*'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.DELIVERY_FILIALS_DETAIL')}</PageTitle>
							<DeliveryBranchPage />
						</>
					}
				/>
				<Route
					path='couriers'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.COURIERS')}</PageTitle>
							<Couriers />
						</>
					}
				></Route>
				<Route
					path='courier/:id/*'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.COURIER_DETAIL')}</PageTitle>
							<CourierPage />
						</>
					}
				/>

				<Route
					path='address'
					element={
						<>
							<PageTitle>{translate('PAGE_TITLE.ADDRESS')}</PageTitle>
							<Address />
						</>
					}
				></Route>

				<Route
					path='builder'
					element={
						<SuspensedView>
							<BuilderPageWrapper />
						</SuspensedView>
					}
				/>
				<Route path='menu-test' element={<MenuTestPage />} />
				{/* Page Not Found */}
				<Route path='*' element={<Navigate to='/error/404' />} />
			</Route>
		</Routes>
	)
}

const SuspensedView: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
