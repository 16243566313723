import { FC } from 'react'
import { useLang } from './Goodi18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import ruMessages from './messages/ru.json'
import enMessages from './messages/en.json'
import kgMessages from './messages/kg.json'
import frMessages from './messages/fr.json'
import jaMessages from './messages/ja.json'
import zhMessages from './messages/zh.json'

const allMessages = {
	ru: ruMessages,
	en: enMessages,
	kg: kgMessages,
	fr: frMessages,
	ja: jaMessages,
	zh: zhMessages,
}

const I18nProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const locale = useLang()
	const messages = allMessages[locale]

	return (
		<IntlProvider locale={locale} messages={messages}>
			{children}
		</IntlProvider>
	)
}

export { I18nProvider }
