import axios from 'axios'
import { UserModel } from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/password/forgot`
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}/auth/password/reset-forgotten`
export const REQUEST_ONE_TIME_PASSWORD_URL = `${API_URL}/auth/password/one-time`

// Server should return AuthModel
export function login(email: string, password: string) {
	return axios.post(LOGIN_URL, {
		email,
		password,
	})
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
	return axios.post(REGISTER_URL, {
		email,
		first_name: firstname,
		last_name: lastname,
		password,
		password_confirmation,
	})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
	return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
		email,
	})
}

export function requestOneTimePassword(email: string, oneTimePassword: string) {
	return axios.post<{ result: boolean }>(REQUEST_ONE_TIME_PASSWORD_URL, {
		user_email: email,
		one_time_password: oneTimePassword,
	})
}

export function requestResetPassword(token: string, new_password: string) {
	return axios.post(REQUEST_RESET_PASSWORD_URL, {
		token,
		new_password,
	})
}

export function getUserByToken(token: string) {
	return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
		api_token: token,
	})
}
