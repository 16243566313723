import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'
import clsx from 'clsx'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ModalHeader } from './ModalHeader'
import { Api } from '../Services/api'
import { setRefresh } from '../reducers/RefreshSlice'
import { UserModel } from '../modules/auth/models/UserModel'
import { RootState } from '../../setup'
import { UserRoles } from '../../_theme/helpers/enums/UserRoles'
import { IDeliveryFilial } from '../models/DeliveryFilialModal'
import { errorMsg, successMsg } from '../../_theme/helpers/AlertMsg'
import useTranslate from '../../_theme/helpers/translate'

type Props = {
	courier?: any
	update: boolean
	isOpen: boolean
	onClose: any
	filialId: number
}

const CourierSchema = Yup.object().shape({
	email: Yup.string().email().required(),
	surname: Yup.string().required(),
	lastname: Yup.string().required(),
	phone: Yup.string().required(),
	filial_id: Yup.number().required(),
})

const AddCourier: FC<Props> = ({ courier, update, isOpen, onClose, filialId }) => {
	const dispatch = useDispatch()
	const translate = useTranslate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const [filials, setFilials] = useState<IDeliveryFilial[]>([])
	const [userForEdit] = useState({
		email: '',
		filial_id: '',
		phone: '',
		name: '',
		surname: '',
		lastname: '',
	})

	const formik = useFormik({
		initialValues: userForEdit,
		validationSchema: CourierSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			const body = {
				email: values.email,
				filial_id: values.filial_id,
				phone: values.phone,
				name: values.surname + ' ' + values.lastname,
				surname: values.surname,
				lastname: values.lastname,
			}
			try {
				Api()
					.deliveries.createCourier(body)
					.then((res) => {
						successMsg('Success')
						dispatch(setRefresh())
						onClose()
					})
					.catch((err) => {
						errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
					})
			} catch (ex) {
				console.error(ex)
			} finally {
			}
		},
	})

	useEffect(() => {
		let q = ''
		if (user.role === UserRoles.DELIVERY_FILIAL_MANAGER) q = `&email=${user?.email}`
		Api()
			.deliveries.getAllFilial()
			.then((res) => {
				setFilials(res.items)
			})
	}, [])

	return (
		<Modal show={isOpen} onHide={() => onClose()}>
			<Modal.Body>
				<ModalHeader handleClose={onClose} titleHeader={'New Courier'} />
				<form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
					{/* begin::Scroll */}
					<div className='d-flex flex-column scroll-y me-n7 pe-7 ' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
						<div className='row'>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.USER_NAME')}</label>

								<input
									placeholder='Courier Name'
									{...formik.getFieldProps('surname')}
									type='text'
									name='surname'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.surname && formik.errors.surname },
										{
											'is-valid': formik.touched.surname && !formik.errors.surname,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.surname && formik.errors.surname && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.surname}</span>
										</div>
									</div>
								)}
								{/* end::Input */}
							</div>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.USER_SURNAME')}</label>

								<input
									placeholder='Courier Name'
									{...formik.getFieldProps('lastname')}
									type='text'
									name='lastname'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.lastname && formik.errors.lastname },
										{
											'is-valid': formik.touched.lastname && !formik.errors.lastname,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.lastname && formik.errors.lastname && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.lastname}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='row'>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.PHONE')}</label>

								<input
									placeholder='User Phone'
									{...formik.getFieldProps('phone')}
									type='text'
									name='phone'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.phone && formik.errors.phone },
										{
											'is-valid': formik.touched.phone && !formik.errors.phone,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.phone && formik.errors.phone && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.phone}</span>
										</div>
									</div>
								)}
							</div>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.EMAIL')}</label>

								<input
									placeholder='Email'
									{...formik.getFieldProps('email')}
									type='text'
									name='email'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.email && formik.errors.email },
										{
											'is-valid': formik.touched.email && !formik.errors.email,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.email && formik.errors.email && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.email}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='row'>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.FILIAL')}</label>
								<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select filial' {...formik.getFieldProps('filial_id')} name='filial_id'>
									<option hidden>Select</option>
									{filials?.map((item, index: any) => {
										return (
											<option key={index} value={item?.id}>
												{item?.name}
											</option>
										)
									})}
								</select>
								{formik.touched.filial_id && formik.errors.filial_id && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.filial_id}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='text-center pt-15'>
							<div onClick={onClose} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
								{translate('FORM.BUTTON.CENCEL')}
							</div>

							<button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>
								<span className='indicator-label'>{translate('FORM.BUTTON.SEND')}</span>
								{formik.isSubmitting && (
									<span className='indicator-progress'>
										Loading... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								)}
							</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	)
}

export default AddCourier
