import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { Debounce } from '../../../../../../_theme/helpers'
import { setAddProduct } from '../../../reducers/ProductSlice'
import useTranslate from '../../../../../../_theme/helpers/translate'

function MetaOptions() {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)

	const changeHandlerMetaTitle = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, metaName: e.target.value }))
	}, 1000)
	const changeHandlerMetaDesc = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, metaDesc: e.target.value }))
	}, 1000)
	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.META_OPTIONS')} </h2>
				</div>
			</div>

			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className='mb-10'>
					<label className='form-label'>{translate('CARD.TEXT.META_TAG_TITLE')} </label>

					<input type='text' onChange={changeHandlerMetaTitle} className='form-control mb-2' name='meta_title' placeholder='Meta tag name' />

					<div className='text-muted fs-7'>{translate('CARD.TEXT.META')}</div>
				</div>

				<div className='mb-10'>
					<label className='form-label'>{translate('CARD.TEXT.META_TAG_DISCRIPTION')}</label>

					<textarea onChange={changeHandlerMetaDesc} className='form-control mb-2' name='desc' id='' cols={30} rows={5}></textarea>

					<div className='text-muted fs-7'>{translate('CARD.TEXT.META_TAG_DISCRIPTION_2')}</div>
				</div>
			</div>
		</div>
	)
}

export default MetaOptions
