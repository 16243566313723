import React, { useEffect, useState } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { useDispatch } from 'react-redux'
import { Api } from '../../../Services/api'
import { setAddProduct } from '../reducers/ProductSlice'
import useTranslate from '../../../../_theme/helpers/translate'

const ProductUsedCountries = () => {
	const translate = useTranslate()
	const [selesCountries, setselesCountries] = useState([])

	const { addProduct } = useAppSelector((state) => state.product)
	const dispatch = useDispatch()
	const onSelectCategory = (selectedList: any, selectedItem: any) => {
		// dispatch(setCategory(selectedList.map(({id}: any) => id)))
		dispatch(setAddProduct({ ...addProduct, counrtiesForSaleIds: selectedList }))
	}
	const onRemoveCategory = (selectedList: any, removedItem: any) => {
		dispatch(setAddProduct({ ...addProduct, counrtiesForSaleIds: selectedList }))
	}
	const onSelectBrnad = (selectedList: any, selectedItem: any) => {
		// dispatch(setCategory(selectedList.map(({id}: any) => id)))
		dispatch(setAddProduct({ ...addProduct, fromCountryId: selectedList }))
	}
	const onRemoveBrand = (selectedList: any, removedItem: any) => {
		dispatch(setAddProduct({ ...addProduct, fromCountryId: selectedList }))
	}

	useEffect(() => {
		Api()
			.address.countries()
			.then((res) => {
				setselesCountries(res?.items)
			})
		return () => {}
	}, [])

	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>Countries</h2>
				</div>
			</div>
			<div className='card-body pt-0 scroll-x'>
				<label className='form-label'>{translate('CARD.TEXT.SELES_COUNTRIES')}</label>

				<Multiselect
					options={selesCountries} // Options to display in the dropdown
					selectedValues={addProduct.counrtiesForSaleIds}
					avoidHighlightFirstOption={true}
					onSelect={onSelectCategory} // Function will trigger on select event
					onRemove={onRemoveCategory} // Function will trigger on remove event
					displayValue='title'
					// onSearch={function noRefCheck() {}}
					// showCheckbox // Property name to display in the dropdown options
					placeholder='Select'
					style={{
						chips: {
							background: '#4fc9da',
						},
						searchBox: {
							borderRadius: '10px',
						},
					}}
				/>
			</div>
		</div>
	)
}

export default ProductUsedCountries
