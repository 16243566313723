import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import useTranslate from '../../../../../../_theme/helpers/translate'
import { Discount } from '../../../../../models/ProductModel'
import { Api } from '../../../../../Services/api'
import { setRefresh } from '../../../../../reducers/RefreshSlice'
import { errorMsg } from '../../../../../../_theme/helpers/AlertMsg'
import { ModalHeader } from '../../../../../Modals/ModalHeader'
import moment from 'moment'

type Props = {
	discount?: Discount | null
	update: boolean
	isOpen: boolean
	onClose: any
	productId: string | number
}

const DiscountSchema = Yup.object().shape({
	procent: Yup.string().required(),
	start_date: Yup.string().required(),
	end_date: Yup.string().required(),
	status: Yup.string(),
})

const AddDiscount: FC<Props> = ({ discount, update, isOpen, onClose, productId }) => {
	const dispatch = useDispatch()
	const translate = useTranslate()

	// State
	const [discountType, setDiscountType] = useState(discount?.type || 'percentage')
	const [userForEdit] = useState({
		procent: discount?.procent,
		start_date: discount?.start_date && moment(discount?.start_date).format('YYYY-MM-DD'),
		status: discount?.status,
		end_date: discount?.end_date && moment(discount?.end_date).format('YYYY-MM-DD'),
	})

	const formik = useFormik({
		initialValues: userForEdit,
		validationSchema: DiscountSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			try {
				if (discount) {
					Api()
						.discount.update(discount.id, { ...values, type: discountType, product_id: productId })
						.then(() => {
							dispatch(setRefresh())
							onClose()
						})
						.catch((err) => {
							errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
						})
				} else {
					Api()
						.discount.create({ ...values, product_id: productId, type: discountType })
						.then(() => {
							dispatch(setRefresh())
							onClose()
						})
						.catch((err) => {
							errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
						})
				}
			} catch (ex) {
				console.error(ex)
			} finally {
			}
		},
	})

	return (
		<Modal show={isOpen} onHide={() => onClose()}>
			<Modal.Body>
				<ModalHeader handleClose={onClose} titleHeader={'New Discount'} />
				<form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
					{/* begin::Scroll */}
					<div className='d-flex flex-column scroll-y me-n7 pe-7 ' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
						<div className='fv-row mb-10'>
							<label className='fs-6 fw-bold mb-2'>
								{translate('CARD.TEXT.PRODUCT_RELATIONS')}
								<i className='fas fa-exclamation-circle ms-2 fs-7' data-bs-toggle='tooltip' title='Select a discount type that will be applied to this product'></i>
							</label>

							<div className='row '>
								<div className='col'>
									<label className='btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6' data-kt-button='true'>
										<span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
											<input
												className='form-check-input'
												type='radio'
												onChange={(e) => {
													setDiscountType(e.target.value)
												}}
												defaultChecked
												name='discount_option'
												value='percentage'
											/>
										</span>

										<span className='ms-5'>
											<span className='fs-4 fw-bolder text-gray-800 d-block'>{translate('CARD.TEXT.PERCENTAGE')} %</span>
										</span>
									</label>
								</div>

								<div className='col'>
									<label className='btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6' data-kt-button='true'>
										<span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
											<input
												className='form-check-input'
												type='radio'
												onChange={(e) => {
													setDiscountType(e.target.value)
												}}
												name='discount_option'
												value='fixed'
											/>
										</span>

										<span className='ms-5'>
											<span className='fs-4 fw-bolder text-gray-800 d-block'>{translate('CARD.TEXT.RIXED_PRICE')} </span>
										</span>
									</label>
								</div>
							</div>
						</div>
						<>
							<div className='d-block mb-5 fv-row'>
								<label className='form-label'>Discounted</label>

								<input
									type='number'
									{...formik.getFieldProps('procent')}
									name='procent'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.procent && formik.errors.procent },
										{
											'is-valid': formik.touched.procent && !formik.errors.procent,
										}
									)}
									placeholder='Discounted price'
								/>
								<div className='text-muted fs-7'>Set the discounted product price. The product will be reduced at the determined fixed price</div>
							</div>
							<div className='d-flex flex-wrap gap-5'>
								<div className='fv-row w-100 flex-md-root'>
									<label className='form-label'>Start Date</label>
									<input
										type='date'
										{...formik.getFieldProps('start_date')}
										name='start_date'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.start_date && formik.errors.start_date },
											{
												'is-valid': formik.touched.start_date && !formik.errors.start_date,
											}
										)}
									/>
									<div className='text-muted fs-7'>Set discount start date</div>
								</div>
								<div className='fv-row w-100 flex-md-root'>
									<label className='form-label'>End Date </label>
									<input
										type='date'
										{...formik.getFieldProps('end_date')}
										name='end_date'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.end_date && formik.errors.end_date },
											{
												'is-valid': formik.touched.end_date && !formik.errors.end_date,
											}
										)}
									/>

									<div className='text-muted fs-7'>Set the discount end date</div>
								</div>
							</div>
						</>

						<div className='fv-row mb-7 mt-5'>
							<label className='fw-bold fs-6 mb-2'>{translate('FORM.INPUT.VISIBILITY')}</label>
							<select className='form-select form-select-solid' defaultValue={discount?.type} data-control='select2' data-hide-search='true' data-placeholder='Visibility' {...formik.getFieldProps('status')} name='status'>
								<option hidden>Select</option>

								<option value={'active'}>Active</option>
								<option value={'inactive'}>Passife</option>
							</select>
							{formik.touched.status && formik.errors.status && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block'>
										<span role='alert'>{formik.errors.status}</span>
									</div>
								</div>
							)}
						</div>
						<div className='text-center pt-15'>
							<div onClick={onClose} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
								{translate('FORM.BUTTON.CENCEL')}
							</div>

							<button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>
								<span className='indicator-label'>{translate('FORM.BUTTON.SEND')}</span>
								{formik.isSubmitting && (
									<span className='indicator-progress'>
										Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								)}
							</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	)
}

export default AddDiscount
