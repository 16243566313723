import React from 'react'
import { useAppSelector } from '../../../../setup/hooks/redux'
import moment from 'moment'
import useTranslate from '../../../../_theme/helpers/translate'

const RefundDetailsCard = () => {
	const { refund } = useAppSelector((state) => state.refunds)
	const translate = useTranslate()
	return (
		<div className='card card-flush py-4 flex-row-fluid'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>
						{translate('CARD.TEXT.ORDERS')}(#{refund.id})
					</h2>
				</div>
			</div>
			<div className='card-body pt-0'>
				<div className='table-responsive'>
					<table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
						<tbody className='fw-bold text-gray-600'>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
												<path
													opacity='0.3'
													d='M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z'
													fill='currentColor'
												/>
												<path d='M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z' fill='currentColor' />
											</svg>
										</span>
										{translate('CARD.TEXT.DATE_ADDED')}
									</div>
								</td>
								<td className='fw-bolder text-end'>{moment(refund?.created).format('DD.MM.YYYY')}</td>
							</tr>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
												<path d='M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z' fill='currentColor' />
												<path opacity='0.3' d='M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z' fill='currentColor' />
											</svg>
										</span>
										{translate('CARD.TEXT.SHIPPING_OPTION')}
									</div>
								</td>
								<td className='fw-bolder text-end'>{translate(`STATUS.TEXT.${refund?.delivery_option?.toUpperCase()}`)}</td>
							</tr>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
												<path d='M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z' fill='currentColor' />
												<path opacity='0.3' d='M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z' fill='currentColor' />
											</svg>
										</span>
										{translate('CARD.TEXT.SHIPPING_PAYMENT')}
									</div>
								</td>
								<td className='fw-bolder text-end'>{translate(`STATUS.TEXT.${refund?.responsible?.toUpperCase()}`)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default RefundDetailsCard
