/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { KTSVG } from '../../../helpers'
import { PageTitle } from './page-title/PageTitle'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from '../../../partials'
import { useLayout } from '../../core'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

const HeaderTopbar: React.FC<{ children?: React.ReactNode }> = () => {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const { classes } = useLayout()
	return (
		<div className={`${classes.headerContainer} py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between`}>
			<PageTitle />

			<div className='d-flex align-items-center overflow-auto pt-3 pt-sm-0'>
				<div className='d-flex'>
					{/* <div className='d-flex align-items-center me-4'>
            <a
              href='#'
              className='btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
            >
              <KTSVG path='/media/icons/duotune/general/gen025.svg' className='svg-icon-1' />
            </a>
            <QuickLinks />
          </div> */}
					<div className='d-flex align-items-center'>
						{/* eslint-disable-next-line */}
						<div className='btn btn-icon btn-primary' data-kt-menu-attach='parent' data-kt-menu-flip='bottom' data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-overflow='false' data-kt-menu-placement='bottom-start'>
							{/* <img style={{width: '100%'}} className='rounded' src={user.avatar} alt='' /> */}

							<div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-3 '>
								{user?.surname?.slice(0, 1)}
								{user?.lastname?.slice(0, 1)}
							</div>
						</div>

						<HeaderUserMenu />
					</div>
				</div>
			</div>
		</div>
	)
}

export default HeaderTopbar
