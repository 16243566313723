export const ProductSpec = (instance: any) => ({
	async updateValue(id: any, body: { value: string }) {
		const { data } = await instance.patch(`/product-specification/update/${id}`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/product-specification/delete/${id}`)
		return data
	},
	async changeStatus(body: { product_id: number; specification_id: number; status: string }) {
		const { data } = await instance.patch(`/product-specification/change-status`, body)
		return data
	},
})
