import React from 'react'
import Inventory from './components/Inventory'
import Variations from './components/Variations'
import Shipping from './components/Shipping'
import MetaOptions from './components/MetaOptions'
// import ProductUsedDeliveries from './components/ProductUsedDeliveries'

function Advanced() {
	return (
		<div className='tab-pane fade show' id='kt_ecommerce_add_product_advanced' role='tab-panel'>
			<div className='d-flex flex-column gap-7 gap-lg-10'>
				<Inventory />

				<Variations />
				{/* <ProductUsedDeliveries/> */}

				<Shipping />
				<MetaOptions />
			</div>
		</div>
	)
}

export default Advanced
