import { createSlice } from '@reduxjs/toolkit'
import { IOrderModel } from '../../../models/OrderModel'

interface OrdersState {
	orders: IOrderModel[]
	order: IOrderModel
	total: number
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
}

const initialState: OrdersState = {
	orders: [],
	order: {} as IOrderModel,
	total: 0,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
}

export const OrdersSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setOrders: (state, action) => {
			state.orders = action.payload.data
			state.total = action.payload.total
		},
		setOrder: (state, action) => {
			state.order = action.payload
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setOrders, setRefresh, setOrder } = OrdersSlice.actions

export default OrdersSlice.reducer
