import moment from 'moment'
import { FC } from 'react'
import { KTSVG } from '../../../../../../_theme/helpers'
import IReview from '../../../../../models/ReviewModel'

type Props = {
	review: IReview
}

const ReviewItem: FC<Props> = ({ review }) => {
	const renderedStars: JSX.Element[] = []
	for (let i = 0; i < review.rating; i++) {
		renderedStars.push(
			<div className='rating-label checked'>
				<KTSVG path='/media/icons/duotune/general/gen029.svg' className='svg-icon svg-icon-1' />
			</div>
		)
	}
	return (
		<tr>
			<td>
				<div className='d-flex align-items-center'>
					<div className=''>
						<div className='text-gray-800 fs-8 ' data-kt-ecommerce-product-filter='product_name'>
							{' '}
							{review?.text}
						</div>
					</div>
				</div>
			</td>
			<td>
				<div className='rating'>{renderedStars}</div>
			</td>

			<td className='text-gray-800 fs-8 '>
				<div className=''>
					{review?.user?.name} {review?.user?.surname}
				</div>
			</td>
			<td className='text-gray-800 fs-8 '>
				<div className=''>{moment(review.created).format('DD.MM.YYYY H:mm')}</div>
			</td>
		</tr>
	)
}

export default ReviewItem
