import { createSlice } from '@reduxjs/toolkit'
import IDeliveries from '../models/DeliveriesModel'

interface DeliveriesState {
	deliveries: IDeliveries[]
	total: number
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
}

const initialState: DeliveriesState = {
	deliveries: [],
	total: 0,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
}

export const DeliveriesSlice = createSlice({
	name: 'deliveries',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setDeliveries: (state, action) => {
			state.deliveries = action.payload.data
			state.total = action.payload.total
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setDeliveries, setRefresh } = DeliveriesSlice.actions

export default DeliveriesSlice.reducer
