import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IOrgonizationFilial } from '../pages/organization/models/OrganizationFilialModel'
import { Dropdown, Modal } from 'react-bootstrap'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { UserRoles } from '../../_theme/helpers'
import { RootState } from '../../setup'
import { UserModel } from '../modules/auth/models/UserModel'
import Swal from 'sweetalert2'
import { Api } from '../Services/api'
import { errorMsg, successMsg } from '../../_theme/helpers/AlertMsg'
import { setRefresh } from '../reducers/RefreshSlice'
import { ModalHeader } from '../Modals/ModalHeader'
import ChangeFilialStatus from '../Modals/ChangeFilialStatus'
import useTranslate from '../../_theme/helpers/translate'

type Props = {
	item: IOrgonizationFilial
}

const BranchIem: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

	const [showModal, setShowModal] = useState(false)
	const handleCloseModal = () => setShowModal(false)
	const handleOpenModal = () => setShowModal(true)

	const nav = () => {
		navigate(`/branches/${item.id}`)
	}
	function swalStatusChange() {
		Swal.fire({
			title: 'The brand will be deleted!',
			text: 'Are you sure to delete?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cencel',
			confirmButtonText: 'Delete',
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				const body = {
					status: item.status,
				}
				Api()
					.brands.delete(item?.id)
					.then((res) => {
						successMsg(res?.message)
						dispatch(setRefresh())
					})
					.catch((err) => {
						errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
					})
			} else if (result.isDenied) {
			}
		})
	}
	return (
		<>
			<tr>
				<td>
					<div className='d-flex align-items-center'>
						<div className='ms-5'>
							<div onClick={nav} className='text-gray-800 text-hover-primary fs-5 fw-bolder' data-kt-ecommerce-product-filter='product_name'>
								{item?.name}
							</div>
						</div>
					</div>
				</td>
				<td className=' pe-0'>
					<span className='fw-bolder'>{item?.organization.name}</span>
				</td>
				<td className=' pe-0'>
					<span className='fw-bolder'>
						{item?.manager?.name} {item?.manager?.surname}
					</span>
				</td>
				<td className=' pe-0'>
					<span className='fw-bolder'> {item?.manager?.phone}</span>
				</td>
				<td className=' pe-0'>
					<span className='fw-bolder'> {item?.address?.title}</span>
				</td>

				<td className=' pe-0' data-order='Published'>
					<div className={`badge badge-${item.status == 'pending' ? 'light-info' : item.status == 'active' ? 'light-success' : 'light-danger'} `}>{translate(`STATUS.TEXT.${item?.status.toUpperCase()}`)}</div>
				</td>
				<td className='text-end pe-0'>
					<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						<Dropdown.Toggle variant='primary' id='dropdown-basic'>
							{translate('TABLE.TITLE.ACTION')}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && (
								<Dropdown.Item>
									<div onClick={handleOpenModal} className='menu-link px-3 my-1'>
										{translate('CARD.TEXT.CHANGE_STATUS')}
									</div>
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</td>
				<Modal show={showModal} onHide={handleCloseModal}>
					<Modal.Body>
						<ModalHeader handleClose={handleCloseModal} titleHeader={translate('CARD.TEXT.CHANGE_STATUS')} />
						<ChangeFilialStatus filialId={item.id} handleClose={handleCloseModal} />
					</Modal.Body>
				</Modal>
			</tr>
		</>
	)
}

export default BranchIem
