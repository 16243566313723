import React, { FC, useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import osm from './osm'
import L from 'leaflet'

interface Coordinate {
	latitude: number
	longitude: number
}

type Props = {
	setData: any
	data?: any
}

const LocationMap: FC<Props> = ({ setData, data }) => {
	const [mapLayers, setMapLayers] = useState<any[]>([])
	const featureGroupRef = useRef<L.FeatureGroup>(null)

	const ZOOM_LEVEL = 7
	const mapRef = useRef<any>()

	const _onCreate = (e: any) => {
		const { layerType, layer } = e
		if (layerType === 'polygon') {
			const { _leaflet_id } = layer
			setMapLayers((layers: any) => [...layers, { id: _leaflet_id, latlngs: layer.getLatLngs()[0] }])
		}
	}

	const _onEdited = (e: any) => {
		const {
			layers: { _layers },
		} = e

		Object.values(_layers).map(({ _leaflet_id, editing }: any) => {
			setMapLayers((layers: any) => layers?.map((l: any) => (l.id === _leaflet_id ? { ...l, latlngs: editing.latlngs[0][0] } : l)))
		})
	}

	const _onDeleted = (e: any) => {
		const {
			layers: { _layers },
		} = e

		Object.values(_layers).map(({ _leaflet_id }: any) => {
			setMapLayers((layers: any[]) => layers.filter((l) => l.id !== _leaflet_id))
		})
	}

	const convertData = () => {
		const convertedData: Coordinate[][][] = mapLayers.map((location) => {
			return location?.latlngs?.map((coords: { lat: any; lng: any }) => ({
				latitude: coords.lat,
				longitude: coords.lng,
			}))
		})

		setData(convertedData)
	}

	const onFeatureGroupReady = async (reactFGref: L.FeatureGroup) => {
		for (let item of data) {
			const inputData = await convertData1(item)
			const geojsonData: any = getGeoJson(inputData)

			const leafletGeoJSON: any = new L.GeoJSON(geojsonData)

			const leafletFG = reactFGref
			leafletGeoJSON.eachLayer((layer: L.Layer) => {
				leafletFG.addLayer(layer)
			})
			const { _layers } = leafletGeoJSON
			Object.values(_layers).map(({ _leaflet_id, _latlngs }: any) => {
				setMapLayers((layers: any) => [...layers, { id: _leaflet_id, latlngs: _latlngs[0] }])
			})
		}
	}
	function convertData1(data: any[][]): number[][][] {
		return [data.map((item: any) => [item.longitude, item.latitude])]
	}

	useEffect(() => {
		setTimeout(() => {
			if (featureGroupRef.current && data) onFeatureGroupReady(featureGroupRef.current)
		}, 1000)
	}, [data])

	useEffect(() => {
		convertData()
	}, [mapLayers])

	return (
		<div className='row'>
			<div className='col text-center'>
				<MapContainer center={[41.9847447, 75.0594996]} zoom={ZOOM_LEVEL} ref={mapRef} style={{ width: '100%', height: '400px' }}>
					<FeatureGroup ref={featureGroupRef}>
						<EditControl
							position={'topright'}
							onCreated={_onCreate}
							onEdited={_onEdited}
							onDeleted={_onDeleted}
							draw={{
								rectangle: false,
								polyline: false,
								circle: false,
								circlemarker: false,
								marker: false,
							}}
						/>
					</FeatureGroup>

					<TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution} />
				</MapContainer>

				<pre className='text-left'>{JSON.stringify(mapLayers)}</pre>
			</div>
		</div>
	)
}

export { LocationMap }

function getGeoJson(data: any) {
	return {
		type: 'FeatureCollection',
		features: [
			{
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Polygon',
					coordinates: data,
				},
			},
		],
	}
}
