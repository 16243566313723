import React from 'react'
import { useAppSelector } from '../../../../setup/hooks/redux'
import useTranslate from '../../../../_theme/helpers/translate'

const CourierDetailCard = () => {
	const { order } = useAppSelector((state) => state.orders)
	const translate = useTranslate()
	return (
		<div className='card card-flush py-4 flex-row-fluid'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.COURIER')}</h2>
				</div>
			</div>
			<div className='card-body pt-0'>
				<div className='table-responsive'>
					<table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
						<tbody className='fw-bold text-gray-600'>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
												<path opacity='0.3' d='M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z' fill='currentColor' />
												<path d='M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z' fill='currentColor' />
												<rect x='7' y='6' width='4' height='4' rx='2' fill='currentColor' />
											</svg>
										</span>
										{order?.current_courier?.name}
									</div>
								</td>
							</tr>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<i className='fa fa-envelope'></i>
										</span>
										{order?.current_courier?.email}
									</div>
								</td>
							</tr>
							<tr>
								<td className='text-muted'>
									<div className='d-flex align-items-center'>
										<span className='svg-icon svg-icon-2 me-2'>
											<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
												<path d='M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z' fill='currentColor' />
												<path opacity='0.3' d='M19 4H5V20H19V4Z' fill='currentColor' />
											</svg>
										</span>
										{order?.current_courier?.phone}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default CourierDetailCard
