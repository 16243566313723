import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { setAddProduct } from '../../../../products/reducers/ProductSlice'
import { Debounce } from '../../../../../../_theme/helpers'
import useTranslate from '../../../../../../_theme/helpers/translate'

function PricingProduct() {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)

	const changeHandlerBasePrice = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, basePrice: e.target.value }))
		// dispatch(setProductDesc(e.target.value))
	}, 1000)

	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.PRICING')}</h2>
				</div>
			</div>

			<div className='card-body pt-0 scroll-x'>
				<div className='mb-10 fv-row'>
					<label className='required form-label'>{translate('CARD.TEXT.BASE_PRICE')}</label>

					<input type='text' name='price' defaultValue={addProduct?.basePrice} onChange={changeHandlerBasePrice} className='form-control mb-2' placeholder='Product price' />

					<div className='text-muted fs-7'>{translate('CARD.TEXT.SET_THE_PRODUCT_PRICE')}</div>
				</div>
			</div>
		</div>
	)
}

export default PricingProduct
