import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../setup/hooks/redux'
import OrderDetailsCard from './components/OrderDetailsCard'
import CustomerDetailsCard from './components/CustomerDetailsCard'
import DocumentsCard from './components/DocumentsCard'
import CourierDetailCard from './components/CourierDetailCard'
import FirstFilialDetailCard from './components/FirstFilialCard'
import { Modal } from 'react-bootstrap'
import { ModalHeader } from '../../Modals/ModalHeader'
import InfoPrintForDelivery from '../../Modals/InfoPrintForDelivery'
import { Api } from '../../Services/api'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setOrder } from './reducers/OrdersSlice'
import DeliveryCompanyCard from './components/DeliveryCompanyCard'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import { UserRoles } from '../../../_theme/helpers'
import useTranslate from '../../../_theme/helpers/translate'

const OrderDetail = () => {
	const translate = useTranslate()
	const loginUser: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const { id } = useParams()
	const dispatch = useDispatch()
	const { order } = useAppSelector((state) => state.orders)

	const [showModal, setShowModal] = useState(false)
	const handleCloseModal = () => setShowModal(false)
	const handleOpenModal = () => setShowModal(true)

	useEffect(() => {
		Api()
			.orders.getOneOrder(id ? id : '')
			.then((res) => {
				dispatch(setOrder(res))
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	return (
		<div>
			<div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
				<OrderDetailsCard />
				<CustomerDetailsCard />
				<DocumentsCard />
			</div>
			<div className='mt-5 d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
				<div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
					<div className='card-header'>
						<div className='card-title'>
							<h2>{translate('CARD.TEXT.CUSTOMER_ADDESS')}</h2>
						</div>
					</div>
					<div className='card-body pt-0'>
						{order?.address?.title}
						<br />
						{order?.address?.district?.title}/{order?.address?.village?.title}/{order?.address?.street}/{order?.address?.home_no}
					</div>
				</div>
				<div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
					<div className='card-header'>
						<div className='card-title'>
							<h2>{translate('CARD.TEXT.FILIAL_ADDESS')}</h2>
						</div>
					</div>
					<div className='card-body pt-0'>
						{order?.organization_filial?.org_filial_address?.title}
						<br />
						{order?.organization_filial?.org_filial_address?.district?.title}/{order?.organization_filial?.org_filial_address?.village?.title}/{order?.organization_filial?.org_filial_address?.street}/{order?.organization_filial?.org_filial_address?.home_no}/{order?.organization_filial?.org_filial_address?.city?.title}
					</div>
				</div>
			</div>
			<div className='mt-5 d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
				<DeliveryCompanyCard />
				<FirstFilialDetailCard />
				<CourierDetailCard />
			</div>
			<div className='mt-5'>
				<div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
					<div className='card-header d-flex justify-content-between align-items-center'>
						<div className='card-title '>
							<h2>Order #{order?.id}</h2>
						</div>
						{loginUser.role !== UserRoles.DELIVERY_COMPANY_MANAGER && loginUser.role !== UserRoles.DELIVERY_FILIAL_MANAGER && (
							<div onClick={handleOpenModal} className='btn btn-primary'>
								Print For Delivery
							</div>
						)}
					</div>
					<div className='card-body pt-0 scroll-x'>
						<div className='table-responsive'>
							<table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
								<thead>
									<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
										<th className='min-w-175px'>{translate('TABLE.TITLE.PRODUCT')}</th>
										<th className='min-w-100px text-end'>{translate('TABLE.TITLE.SKU')}</th>
										<th className='min-w-70px text-end'>{translate('TABLE.TITLE.QTY')}</th>
										<th className='min-w-70px text-end'>{translate('TABLE.TITLE.SPECIFICATIONS')}</th>
										<th className='min-w-100px text-end'>{translate('TABLE.TITLE.UNIT_PRICE')}</th>
										<th className='text-end min-w-100px'>{translate('TABLE.TITLE.TAX_FOR_TURKISHASIA')}</th>
									</tr>
								</thead>
								<tbody className='fw-bold text-gray-600'>
									{order?.order_products?.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className='d-flex align-items-center'>
														<div className='symbol symbol-50px'>
															<span className='symbol-label' style={{ backgroundImage: `url(${item?.product?.main_picture?.url})` }}></span>
														</div>
														<div className='ms-5'>
															<div className='fw-bolder text-gray-600 text-hover-primary'>{item?.product?.title}</div>
															{/* <div className='fs-7 text-muted'>Delivery Date: 04.11.2023</div> */}
														</div>
													</div>
												</td>
												<td className='text-end'>{item?.product?.article}</td>
												<td className='text-end'>{item?.amount}</td>

												<td className='text-end'>{item?.specifications?.map(({ value }) => `${value},`)}</td>
												<td className='text-end'>{item?.product?.base_price}</td>
												<td className='text-end'>{item?.product?.price_with_tax - item?.product?.base_price}</td>
												{/* <td className='text-end'>{item?.price - order?.delivery_price}</td> */}
											</tr>
										)
									})}
									<tr>
										<td colSpan={5} className='text-end'>
											Subtotal
										</td>
										<td className='text-end'>{order?.price_for_organization + order.price_for_ta}</td>
									</tr>
									<tr>
										<td colSpan={5} className='text-end'>
											Shipping Rate
										</td>
										<td className='text-end'>{order?.delivery_price}</td>
									</tr>
									<tr>
										<td colSpan={5} className='fs-3 text-dark text-end'>
											Total
										</td>
										<td className='text-dark fs-3 fw-boldest text-end'>{order?.price}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Modal size={'xl'} show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={'For Delivery'} />
					<InfoPrintForDelivery order={order} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default OrderDetail
