import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { Debounce } from '../../../../../../_theme/helpers'
import { setAddProduct } from '../../../reducers/ProductSlice'
import useTranslate from '../../../../../../_theme/helpers/translate'

function Shipping() {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)

	const changeHandlerLength = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, length: e.target.value }))
	}, 100)
	const changeHandlerWidth = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, width: e.target.value }))
	}, 100)
	const changeHandlerHeight = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, height: e.target.value }))
	}, 100)
	const changeHandlerWeight = Debounce(async (e: any) => {
		dispatch(setAddProduct({ ...addProduct, weight: e.target.value }))
	}, 100)
	return (
		<div className='card card-flush py-4'>
			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div id='kt_ecommerce_add_product_shipping' className=' mt-10'>
					<div className='mb-10 fv-row'>
						<label className='form-label'>{translate('FORM.INPUT.WEIGHT')}</label>

						<input type='text' name='weight' onChange={changeHandlerWeight} className='form-control mb-2' placeholder='Product weight' />

						<div className='text-muted fs-7'>Set a product weight in kilograms (kg).</div>
					</div>

					<div className='fv-row'>
						<label className='form-label'>Dimension</label>

						<div className='d-flex flex-wrap flex-sm-nowrap gap-3'>
							<input type='number' name='width' onChange={changeHandlerWidth} className='form-control mb-2' placeholder='Width (w)' />
							<input type='number' name='height' onChange={changeHandlerHeight} className='form-control mb-2' placeholder='Height (h)' />
							<input type='number' name='length' onChange={changeHandlerLength} className='form-control mb-2' placeholder='Lengtn (l)' />
						</div>

						<div className='text-muted fs-7'>Enter the product dimensions in centimeters (cm).</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Shipping
