import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import AddBrnad from '../modals/AddRefundType'
import IRefundType from '../models/RefundTypeModel'
import { Dropdown } from 'react-bootstrap'
import Swal from 'sweetalert2'
import useTranslate from '../../../../_theme/helpers/translate'

type Props = {
	item: IRefundType
}

const RefundTypeItem: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)
	function swalDelete() {
		Swal.fire({
			title: 'The brand will be deleted!',
			text: 'Are you sure to delete?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cencel',
			confirmButtonText: 'Delete',
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				// Api()
				// 	.categories.delete(item?.id)
				// 	.then((res) => {
				// 		successMsg(res?.message)
				// 		dispatch(setRefresh())
				// 	})
				// 	.catch((err) => {
				// 		errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
				// 	})
			} else if (result.isDenied) {
			}
		})
	}
	return (
		<>
			<tr>
				<td>
					<div className='d-flex align-items-center'>
						<div className='ms-5'>
							<div className='text-gray-800 text-hover-primary fs-5 fw-bolder' data-kt-ecommerce-product-filter='product_name'>
								{item?.type}
							</div>
						</div>
					</div>
				</td>

				{/* <td className='text-end pe-0' data-order='Published'>
					{item?.status == 'published' ? (
						<div className='badge badge-light-success '>Active</div>
					) : (
						<div className='badge badge-light-danger '>Pasife</div>
					)}
				</td> */}

				<td className='text-end'>
					<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						<Dropdown.Toggle variant='primary' id='dropdown-basic'>
							{translate('TABLE.TITLE.ACTION')}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item>
								<div className='menu-item px-3'>
									<div onClick={handleOpenModal} className='menu-link px-3'>
										{translate('TABLE.TITLE.ACTION')}
									</div>
								</div>
							</Dropdown.Item>
							<Dropdown.Item>
								<div onClick={swalDelete} className='menu-item px-3'>
									<div className='menu-link px-3' data-kt-ecommerce-product-filter='delete_row'>
										{translate('CARD.TEXT.DELETE')}
									</div>
								</div>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
			<AddBrnad update={true} refundType={item} isOpen={showModal} onClose={handleCloseModal} />
		</>
	)
}

export default RefundTypeItem
