import React, { useEffect, useState } from 'react'

import { Api } from '../../../../Services/api'
import ReviewItem from './components/ReviewItem'
import { useAppSelector } from '../../../../../setup/hooks/redux'
import IReview from '../../../../models/ReviewModel'
import useTranslate from '../../../../../_theme/helpers/translate'

const Reviews = () => {
	const translate = useTranslate()
	const { company } = useAppSelector((state) => state.company)
	const [reviews, setReviews] = useState<IReview[]>([])
	const [currentPage, setCurrentPage] = useState(1)
	const [limit, setLimit] = useState(20)
	const [total, setToltal] = useState(1)
	const currentTableData = () => {
		Api()
			.comment.getOrganizationComment(limit, currentPage, company.id)
			.then((res) => {
				setReviews(res?.items)
			})
	}

	useEffect(() => {
		setCurrentPage(1)
		currentTableData()
	}, [company])
	useEffect(() => {
		currentTableData()
	}, [currentPage])
	return (
		<div className='card card-flush'>
			<div className='card-body pt-5'>
				<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
					<thead>
						<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
							<th className='min-w-200px'>{translate('TABLE.TITLE.COMMENTS')}</th>
							<th className='min-w-100px'>{translate('TABLE.TITLE.STAR')}</th>
							<th className='min-w-70px'>{translate('TABLE.TITLE.USER')}</th>
							<th className='min-w-70px'>{translate('TABLE.TITLE.DATE')}</th>
						</tr>
					</thead>
					<tbody className='fw-bold text-gray-600'>
						{reviews?.map((review: any) => {
							return <ReviewItem review={review} key={review?.id} />
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Reviews
