import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import IDeliveries from '../models/DeliveriesModel'
import { Api } from '../../../Services/api'
import FileUpload from '../../../components/FileUpload'

import Swal from 'sweetalert2'
import DraggableMarker from '../../../../_theme/partials/content/DrawPolygon/MapDrag'
import { ICoordinate } from '../../../models/AddressModel'
import { LocationMap } from '../../../../_theme/partials/content/DrawPolygon/LocationMap'
import useTranslate from '../../../../_theme/helpers/translate'
import { setRefresh } from '../../../reducers/RefreshSlice'

type Props = {
	deliveries?: IDeliveries
	update: boolean
	handleClose: any
}

const DeliveriesSchema = Yup.object().shape({
	name: Yup.string().required(),
	email: Yup.string().email().required(),
	phone: Yup.string().required(),
	surname: Yup.string().required(),
	lastname: Yup.string().required(),
	delivery_price: Yup.number().required().min(1),
	delivery_refund_price: Yup.number().required().min(0),
	length: Yup.number().required().min(1),
	width: Yup.number().required().min(1),
	height: Yup.number().required().min(1),
	weight: Yup.number().required().min(1),
	country_id: Yup.number().required(),
	city_id: Yup.number(),
	village_id: Yup.number(),
	street: Yup.string(),
	home_no: Yup.string(),
	district_id: Yup.number().required(),
	region_id: Yup.number().required(),
	address_title: Yup.string().required(),
	deposite_amount: Yup.string().required(),
})

const AddDelivery: FC<Props> = ({ deliveries, update, handleClose }) => {
	const dispatch = useDispatch()
	const translate = useTranslate()
	const [file, setFile] = useState<any>([])
	const [showFile, setShowFile] = useState<any>([]) //banner show
	const [currentLocation, setCurrentLocation] = useState<ICoordinate>()
	const [poligonGetterPoints, setPoligonGetterPoint] = useState([])
	const [poligonSetterPoints, setPoligonSetterPoint] = useState([])
	const [depositeFile, setDepositeFile] = useState<any>([])

	const [countries, setCountries] = useState<any>([])
	const [district, setDistrict] = useState<any>([])

	const [regions, setRegions] = useState<any>([])
	const [cities, setCities] = useState<any>([])
	const [village, setVillage] = useState<any>([])

	const [userForEdit] = useState({
		name: '',
		email: '',
		phone: '',
		surname: '',
		lastname: '',
		delivery_price: '',
		delivery_refund_price: '',
		length: '',
		width: '',
		height: '',
		weight: '',
		country_id: '',
		city_id: '',
		village_id: '',
		street: '',
		home_no: '',
		district_id: '',
		region_id: '',
		address_title: '',
		deposite_amount: '',
	})

	const formik = useFormik({
		initialValues: userForEdit,
		validationSchema: DeliveriesSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			const body = {
				name: values.name,
				delivery_price: Number(values.delivery_price),
				delivery_refund_price: Number(values.delivery_refund_price),
				shipping_restriction: {
					length: Number(values.length),
					width: Number(values.width),
					height: Number(values.height),
					weight: Number(values.weight),
				},
				address: {
					country_id: Number(values.country_id),
					region_id: Number(values.region_id),
					district_id: Number(values.district_id),
					coordinate: {
						longitude: currentLocation?.longitude,
						latitude: currentLocation?.latitude,
					},
					address_title: values.address_title,
					city_id: values.city_id ? Number(values.city_id) : null,
					village_id: values.village_id ? Number(values.village_id) : null,
					street: values.street,
					home_no: values.home_no,
				},
				manager: {
					email: values.email,
					phone: values.phone,
					name: values.surname + ' ' + values.lastname,
					surname: values.surname,
					lastname: values.lastname,
				},
				filial_operating_coordinates: {
					receive_coordinates: poligonGetterPoints,
					delivery_coordinates: poligonSetterPoints,
				},
				deposite: {
					deposite_file_id: Number(depositeFile?.id),
					deposite_amount: Number(values.deposite_amount),
				},
			}

			try {
				Api()
					.deliveries.create(body)
					.then((res) => {
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: 'Successfuly Added!',
							showConfirmButton: false,
							timer: 1500,
						})
						handleClose()
						dispatch(setRefresh())
					})
					.catch((err) => {
						Swal.fire({
							icon: 'error',
							title: err?.response?.data?.statusCode,
							text: err?.response?.data?.message,
						})
					})
			} catch (ex) {
				console.log(ex)
				Swal.fire({
					icon: 'error',
					title: '',
					text: '',
				})
			} finally {
				setSubmitting(false)
			}
		},
	})
	//banner
	const removeFiles = (f: any) => () => {
		const newFiles = [...showFile]
		newFiles.splice(newFiles.indexOf(f), 1)
		setShowFile(newFiles)
	}
	const f = showFile.map(
		(
			file: {
				path: string | null | undefined
				size: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
			},
			index: React.Key | null | undefined
		) => (
			<li key={index} className='d-flex justify-content-between m-5'>
				<span className='w-50 ml-10'>
					{file.path} - {file.size} bytes{' '}
				</span>{' '}
				<span className='btn btn-sm  mr-10 d-flex align-items-center' onClick={removeFiles(file)} style={{ color: 'white' }}>
					Sil
				</span>
			</li>
		)
	)

	const uploadImg = (e: any) => {
		let formData = new FormData()
		formData.append('file', e)

		console.log(e)
		Api()
			.organization.imageUpload(formData)
			.then((res) => {
				setFile(res)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		Api()
			.address.countries()
			.then((res) => {
				setCountries(res?.items)
			})
		Api()
			.address.city()
			.then((res) => {
				setCities(res?.items)
			})
		Api()
			.address.province()
			.then((res) => {
				setRegions(res?.items)
			})
		Api()
			.address.regions()
			.then((res) => {
				setDistrict(res?.items)
			})
		Api()
			.address.villages()
			.then((res) => {
				setVillage(res?.items)
			})
	}, [])

	return (
		<form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
			<div className='d-flex flex-column scroll-y me-n7 pe-7 ' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DELIVERY_NAME')}</label>

						<input
							placeholder='Deliveries Name'
							{...formik.getFieldProps('name')}
							type='text'
							name='name'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.name && formik.errors.name },
								{
									'is-valid': formik.touched.name && !formik.errors.name,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.name && formik.errors.name && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.name}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.USER_NAME')}</label>

						<input
							placeholder='surname'
							{...formik.getFieldProps('surname')}
							type='text'
							name='surname'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.surname && formik.errors.surname },
								{
									'is-valid': formik.touched.surname && !formik.errors.surname,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.surname && formik.errors.surname && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.surname}</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.USER_SURNAME')}</label>

						<input
							placeholder='User Lastname'
							{...formik.getFieldProps('lastname')}
							type='text'
							name='lastname'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.lastname && formik.errors.lastname },
								{
									'is-valid': formik.touched.lastname && !formik.errors.lastname,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.lastname && formik.errors.lastname && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.lastname}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.PHONE')}</label>

						<input
							placeholder='User Phone'
							{...formik.getFieldProps('phone')}
							type='text'
							name='phone'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.phone && formik.errors.phone },
								{
									'is-valid': formik.touched.phone && !formik.errors.phone,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.phone && formik.errors.phone && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.phone}</span>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.EMAIL')}</label>

						<input
							placeholder='Email'
							{...formik.getFieldProps('email')}
							type='text'
							name='email'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.email && formik.errors.email },
								{
									'is-valid': formik.touched.email && !formik.errors.email,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.email && formik.errors.email && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.email}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DELIVERY_PRICE')}</label>

						<input
							placeholder='Delivery Price'
							{...formik.getFieldProps('delivery_price')}
							type='number'
							name='delivery_price'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.delivery_price && formik.errors.delivery_price },
								{
									'is-valid': formik.touched.delivery_price && !formik.errors.delivery_price,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.delivery_price && formik.errors.delivery_price && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.delivery_price}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DELIVERY_REFUND_PRICE')}</label>

						<input
							placeholder='Delivery Price'
							{...formik.getFieldProps('delivery_refund_price')}
							type='number'
							name='delivery_refund_price'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.delivery_refund_price && formik.errors.delivery_refund_price },
								{
									'is-valid': formik.touched.delivery_refund_price && !formik.errors.delivery_refund_price,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.delivery_refund_price && formik.errors.delivery_refund_price && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.delivery_refund_price}</span>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.LENGTH')}</label>

						<input
							placeholder='Length'
							{...formik.getFieldProps('length')}
							type='number'
							name='length'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.length && formik.errors.length },
								{
									'is-valid': formik.touched.length && !formik.errors.length,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.length && formik.errors.length && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.length}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.WIDTH')}</label>

						<input
							placeholder='Width'
							{...formik.getFieldProps('width')}
							type='number'
							name='width'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.width && formik.errors.width },
								{
									'is-valid': formik.touched.width && !formik.errors.width,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.width && formik.errors.width && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.width}</span>
								</div>
							</div>
						)}
					</div>

					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.HEIGHT')}</label>

						<input
							placeholder='Height'
							{...formik.getFieldProps('height')}
							type='number'
							name='height'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.height && formik.errors.height },
								{
									'is-valid': formik.touched.height && !formik.errors.height,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.height && formik.errors.height && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.height}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.WEIGHT')}</label>

						<input
							placeholder='Weight'
							{...formik.getFieldProps('weight')}
							type='number'
							name='weight'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.weight && formik.errors.weight },
								{
									'is-valid': formik.touched.weight && !formik.errors.weight,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.weight && formik.errors.weight && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.weight}</span>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className='row'>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.COUNTRY')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select Country' {...formik.getFieldProps('country_id')} name='country_id'>
							<option hidden>Select</option>
							{countries?.map((item: any, index: any) => {
								return (
									<option key={index} value={item?.id}>
										{item?.title}
									</option>
								)
							})}
						</select>
						{formik.touched.country_id && formik.errors.country_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.country_id}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.REGION')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select City' {...formik.getFieldProps('region_id')} name='region_id'>
							<option hidden>Select</option>
							{regions
								?.filter((item: any) => item?.country?.id == formik.values.country_id)
								?.map((item: any, index: any) => {
									return (
										<option key={index} value={item?.id}>
											{item?.title}
										</option>
									)
								})}
						</select>
						{formik.touched.region_id && formik.errors.region_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.region_id}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.CITY')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select City' {...formik.getFieldProps('city_id')} name='city_id'>
							<option hidden>Select</option>
							<option value={''}>Null</option>
							{cities
								// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
								?.map((item: any, index: any) => {
									return (
										<option key={index} value={item?.id}>
											{item?.title}
										</option>
									)
								})}
						</select>
						{formik.touched.city_id && formik.errors.city_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.city_id}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DISTRICT')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select district_id' {...formik.getFieldProps('district_id')} name='district_id'>
							<option hidden>Select</option>
							{district
								// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
								?.map((item: any, index: any) => {
									return (
										<option key={index} value={item?.id}>
											{item?.title}
										</option>
									)
								})}
						</select>
						{formik.touched.district_id && formik.errors.district_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.district_id}</span>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className='row'>
					<div className='col mb-7'>
						<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.VILLAGE')}</label>

						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select village_id' {...formik.getFieldProps('village_id')} name='village_id'>
							<option hidden>Select</option>
							<option value={''}>Null</option>
							{village
								// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
								?.map((item: any, index: any) => {
									return (
										<option key={index} value={item?.id}>
											{item?.title}
										</option>
									)
								})}
						</select>
						{formik.touched.district_id && formik.errors.district_id && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.district_id}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.STREET')}</label>

						<input
							placeholder='Street'
							{...formik.getFieldProps('street')}
							type='text'
							name='street'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.street && formik.errors.street },
								{
									'is-valid': formik.touched.street && !formik.errors.street,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.street && formik.errors.street && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.street}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col mb-7'>
						<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.HOME_NUMBER')}</label>

						<input
							placeholder='Home Number'
							{...formik.getFieldProps('home_no')}
							type='text'
							name='home_no'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.home_no && formik.errors.home_no },
								{
									'is-valid': formik.touched.home_no && !formik.errors.home_no,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.home_no && formik.errors.home_no && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.home_no}</span>
								</div>
							</div>
						)}
					</div>
					<div className='col-12 mb-7'>
						<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.ADDRESS')}</label>

						<input
							placeholder='Address'
							{...formik.getFieldProps('address_title')}
							type='text'
							name='address_title'
							className={clsx(
								'form-control form-control-solid mb-3 mb-lg-0',
								{ 'is-invalid': formik.touched.address_title && formik.errors.address_title },
								{
									'is-valid': formik.touched.address_title && !formik.errors.address_title,
								}
							)}
							autoComplete='off'
						/>
						{formik.touched.address_title && formik.errors.address_title && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.address_title}</span>
								</div>
							</div>
						)}
					</div>

					<div className='row'>
						<div className='col mb-7'>
							<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DEPOZIT')}</label>

							<input
								placeholder='deposite_amount'
								{...formik.getFieldProps('deposite_amount')}
								type='text'
								name='deposite_amount'
								className={clsx(
									'form-control form-control-solid mb-3 mb-lg-0',
									{ 'is-invalid': formik.touched.deposite_amount && formik.errors.deposite_amount },
									{
										'is-valid': formik.touched.deposite_amount && !formik.errors.deposite_amount,
									}
								)}
								autoComplete='off'
							/>
							{formik.touched.deposite_amount && formik.errors.deposite_amount && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block'>
										<span role='alert'>{formik.errors.deposite_amount}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<FileUpload title={translate('FORM.INPUT.SELECT_DEPOZIT_PHOTO')} setFile={setDepositeFile} />
				<div>
					<label className='form-label'>{translate('FORM.INPUT.RECEIVE_COORDINATES')}</label>
					<LocationMap setData={setPoligonGetterPoint} data={null} key={'receive_coordinates'} />
				</div>

				<div>
					<label className='form-label'>{translate('FORM.INPUT.DELIVERY_COORDINATES')}</label>
					<LocationMap setData={setPoligonSetterPoint} data={null} key={'delivery_coordinates'} />
				</div>

				<div>
					<label className='form-label'>{translate('FORM.INPUT.CURRENT_LOCATION')}</label>
					<DraggableMarker setData={setCurrentLocation} />
				</div>

				<div className='text-center pt-15'>
					<div onClick={handleClose} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
						{translate('FORM.BUTTON.CENCEL')}
					</div>

					<button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>
						<span className='indicator-label'>{translate('FORM.BUTTON.SEND')}</span>
						{formik.isSubmitting && (
							<span className='indicator-progress'>
								Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
				</div>
			</div>
		</form>
	)
}

export default AddDelivery
