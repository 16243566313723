import { FC, useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../../_theme/assets/ts/_utils'
import { IMonthlyOrder } from '../../../models/StatistiscModels'
import { Api } from '../../../Services/api'
import useTranslate from '../../../../_theme/helpers/translate'
import { months } from '../../../../_theme/helpers'

type Props = {
	className: string
	chartHeight: number
	queryType: string
}

export const ChartsOrderMonthlyCount: FC<Props> = ({ className, chartHeight, queryType }) => {
	const translate = useTranslate()
	const chartRef = useRef<HTMLDivElement | null>(null)
	const [data, setData] = useState<IMonthlyOrder[]>([])
	const [selectedMonth, setSelectedMonth] = useState<string>('0')
	const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString())

	useEffect(() => {
		Api()
			.statistics.getMonthlyOrder(selectedYear, selectedMonth, queryType)
			.then((res) => {
				setData(res)
			})
	}, [selectedYear, selectedMonth])

	useEffect(() => {
		if (!chartRef.current) {
			return
		}

		const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, data))
		if (chart) {
			chart.render()
		}

		return () => {
			if (chart) {
				chart.destroy()
			}
		}
	}, [chartRef, chartHeight, data])

	return (
		<div className={`card card-flush overflow-hidden ${className}`}>
			<div className='card-header py-5'>
				<h3 className='card-title align-items-start flex-column'>
					<span className='card-label fw-bolder text-dark'>{translate('GRAPHIC.TITLE.MONTHLY_ORDERS_COUNT')}</span>
					<span className='text-gray-400 mt-1 fw-bold fs-6'>
						{translate('CARD.TEXT.YEAR')} <b>{selectedYear}</b> {translate('CARD.TEXT.MONTHS')}: <b>{translate(months[Number(selectedMonth)])}</b>
					</span>
				</h3>
				<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
					<div className='w-100 mw-100px'>
						<select className='form-select form-select-solid' defaultValue={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
							<option value='2024'>2024</option>
							<option value='2025'>2025</option>
							<option value='2026'>2026</option>
							<option value='2027'>2027</option>
						</select>
					</div>
					<div className='w-100 mw-100px'>
						<select className='form-select form-select-solid' defaultValue={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
							{months.map((item, index) => (
								<option value={index}>{translate(item)}</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<div className='card-body d-flex justify-content-between flex-column pb-1 px-0'>
				<div className='px-9 mb-5'>
					<div className='d-flex mb-2'>
						<span className='fs-2hx fw-bolder text-gray-800 me-2 lh-1'>{data.reduce((acc, curr) => (acc += Number(curr.count)), 0)}</span>
						<span className='fs-4 fw-bold text-gray-400 me-1'> </span>
					</div>
				</div>
				<div ref={chartRef} className='min-h-auto ps-4 pe-6' style={{ height: '300px' }}></div>
			</div>
		</div>
	)
}

const chartOptions = (chartHeight: number, data: IMonthlyOrder[]): ApexOptions => {
	const labelColor = getCSSVariableValue('--bs-gray-500')
	const borderColor = getCSSVariableValue('--bs-border-dashed-color')
	const baseColor = getCSSVariableValue('--bs-success')
	const lightColor = getCSSVariableValue('--bs-success')

	return {
		series: [
			{
				name: 'Units',
				data: data.map((item) => Number(item.count)),
			},
		],
		chart: {
			fontFamily: 'inherit',
			type: 'area',
			height: chartHeight,
			toolbar: {
				show: false,
			},
		},
		plotOptions: {},
		legend: {
			show: false,
		},
		dataLabels: {
			enabled: false,
		},
		fill: {
			type: 'gradient',
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.3,
				opacityTo: 0.7,
				stops: [0, 90, 100],
			},
		},
		stroke: {
			curve: 'smooth',
			show: true,
			width: 3,
			colors: [baseColor],
		},
		xaxis: {
			categories: data.map((item, index) => index + 1),
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
			tickAmount: data.length,
			labels: {
				rotate: 0,
				rotateAlways: true,
				style: {
					colors: labelColor,
					fontSize: '12px',
				},
			},
			crosshairs: {
				position: 'front',
				stroke: {
					color: baseColor,
					width: 1,
					dashArray: 3,
				},
			},
			tooltip: {
				enabled: true,
				formatter: undefined,
				offsetY: 0,
				style: {
					fontSize: '12px',
				},
			},
		},
		yaxis: {
			tickAmount: 4,
			max: Math.max(...data.map((item) => Number(item.count))),
			min: 0,
			labels: {
				style: {
					colors: labelColor,
					fontSize: '12px',
				},
				formatter: function (val) {
					return Number(val) + ' '
				},
			},
		},
		states: {
			normal: {
				filter: {
					type: 'none',
					value: 0,
				},
			},
			hover: {
				filter: {
					type: 'none',
					value: 0,
				},
			},
			active: {
				allowMultipleDataPointsSelection: false,
				filter: {
					type: 'none',
					value: 0,
				},
			},
		},
		tooltip: {
			style: {
				fontSize: '12px',
			},
			y: {
				formatter: function (val) {
					return val + ' '
				},
			},
		},
		colors: [lightColor],
		grid: {
			borderColor: borderColor,
			strokeDashArray: 4,
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		markers: {
			strokeColors: [baseColor],
			strokeWidth: 3,
		},
	}
}
