import React, { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAddProduct } from '../../../reducers/ProductSlice'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { SIZES } from '../../../../../../_theme/helpers/data/size'
import useTranslate from '../../../../../../_theme/helpers/translate'

interface Specification {
	value: string
	// amount: string
}

interface SpecType {
	type: string
	specifications: Specification[]
}

const Variations = () => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)
	const [specifications, setSpecifications] = useState<SpecType[]>(addProduct.types)

	const handleInputChange = (typeIndex: number, specIndex: number, field: keyof Specification, value: string) => {
		const updatedSpecs = [...specifications]
		updatedSpecs[typeIndex].specifications[specIndex][field] = value
		setSpecifications(updatedSpecs)
	}

	const handleAddSpecification = (e: any) => {
		setSpecifications([...specifications, { type: e.target.value, specifications: [{ value: '' }] }])
	}

	const handleRemoveSpecification = (typeIndex: number) => {
		const updatedSpecs = [...specifications]
		updatedSpecs.splice(typeIndex, 1)
		setSpecifications(updatedSpecs)
	}

	const handleAddSubSpecification = (typeIndex: number) => {
		const updatedSpecs = [...specifications]
		updatedSpecs[typeIndex].specifications.push({ value: '' })
		setSpecifications(updatedSpecs)
	}

	const handleRemoveSubSpecification = (typeIndex: number, specIndex: number) => {
		const updatedSpecs = [...specifications]
		updatedSpecs[typeIndex].specifications.splice(specIndex, 1)
		setSpecifications(updatedSpecs)
	}

	const renderSpecifications = () => {
		return specifications.map((specType, typeIndex) => (
			<div key={typeIndex} className='my-3 mb-5'>
				<h3 className='h3'>{specType.type.toLocaleUpperCase()} Specifications</h3>
				{specType.specifications.map((spec, specIndex) => (
					<>
						<div key={specIndex} className='mb-3 w-100 d-flex align-items-center justify-content-start'>
							{specType.type === 'size' ? (
								<select className='form-select mw-100 w-200px me-3' name='product_option' onChange={(e: ChangeEvent<HTMLSelectElement>) => handleInputChange(typeIndex, specIndex, 'value', e.target.value)} defaultValue={spec.value} data-kt-ecommerce-catalog-add-product='product_option'>
									<option value={''} disabled>
										Select
									</option>
									{SIZES.map((item) => (
										<option value={item.value}>{item.title}</option>
									))}
								</select>
							) : (
								<input type='text' className='form-control mw-100 w-200px me-3' name='product_option_value' placeholder='Variation' value={spec.value} onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(typeIndex, specIndex, 'value', e.target.value)} />
							)}

							<button type='button' data-repeater-delete='' onClick={() => handleRemoveSubSpecification(typeIndex, specIndex)} className='btn btn-sm btn-icon btn-light-danger me-3'>
								-
							</button>
						</div>
					</>
				))}
				<div className='form-group mt-5'>
					<button type='button' onClick={() => handleAddSubSpecification(typeIndex)} data-repeater-create='' className='btn btn-sm btn-light-primary me-3'>
						+ {translate('CARD.TEXT.ADD_ANOTHER_SPECIFICATIONS')}
					</button>
					<button type='button' onClick={() => handleRemoveSpecification(typeIndex)} data-repeater-create='' className='btn btn-sm btn-light-danger'>
						+ {translate('CARD.TEXT.REMOVE')} {specType.type.toLocaleUpperCase()}
					</button>
				</div>
			</div>
		))
	}

	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title w-100 d-flex justify-content-between'>
					<h2>{translate('CARD.TEXT.VARIATIONS')}</h2>
					<div onClick={() => dispatch(setAddProduct({ ...addProduct, types: specifications }))} className='btn btn-primary'>
						{translate('CARD.TEXT.SAVE')}
					</div>
				</div>
			</div>

			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div>
					<div className='w-25 w-md-200px'>
						<select className='form-select' name='product_option' onChange={handleAddSpecification} defaultValue={''} data-kt-ecommerce-catalog-add-product='product_option'>
							<option value={''} disabled>
								{translate('CARD.TEXT.SELEKT')}
							</option>
							<option value='color'>Color</option>
							<option value='size'>Size</option>
							<option value='material'>Material</option>
							<option value='style'>Style</option>
						</select>
					</div>
					{/* <button onClick={handleAddSpecification}>Add Specification</button> */}
					{renderSpecifications()}
				</div>
			</div>
		</div>
	)
}

export default Variations
