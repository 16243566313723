import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../../../_theme/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAppSelector } from '../../../../../setup/hooks/redux'
import { Api } from '../../../../Services/api'
import { useNavigate } from 'react-router-dom'
import { errorMsg, successMsg } from '../../../../../_theme/helpers/AlertMsg'
import { useDispatch } from 'react-redux'
import { setRefresh } from '../../../../reducers/RefreshSlice'
import useTranslate from '../../../../../_theme/helpers/translate'

const settingsSchema = Yup.object().shape({
	name: Yup.string().required(),
	description: Yup.string().required(),
	tax_number: Yup.string().required(),
	tax_service_address: Yup.string().required(),
	settlement_account: Yup.string().required(),
	bik: Yup.string().required(),
	fax_number: Yup.string().required(),
	tj: Yup.string(),
})

const Settings: React.FC<{ children?: React.ReactNode }> = () => {
	const { company } = useAppSelector((state) => state.company)
	const translate = useTranslate()
	const dispatch = useDispatch()
	const nav = useNavigate()
	const initialValues = {
		name: company.name,
		description: company.description,
		tax_number: company.tax_number,
		tax_service_address: company.tax_service_address,
		settlement_account: company.settlement_account,
		bik: company.bik,
		fax_number: company.fax_number,
		tj: company.tj,
	}

	const [loading, setLoading] = useState(false)
	const formik = useFormik({
		initialValues,
		validationSchema: settingsSchema,
		onSubmit: (values) => {
			setLoading(true)
			Api()
				.organization.updatedOrg(values)
				.then((res) => {
					dispatch(setRefresh())
					successMsg('Successfuly Updeted!')
					nav(`/company/${company.id}`)
				})
				.catch((err) => {
					errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
				})
		},
	})

	return (
		<div className='card mb-5 mb-xl-10'>
			<div className='card-header border-0 cursor-pointer' role='button' data-bs-toggle='collapse' data-bs-target='#kt_account_profile_details' aria-expanded='true' aria-controls='kt_account_profile_details'>
				<div className='card-title m-0'>
					<h3 className='fw-bolder m-0'> {translate('CARD.TEXT.ABOUT')}</h3>
				</div>
			</div>

			<div id='kt_account_profile_details' className='collapse show'>
				<form onSubmit={formik.handleSubmit} noValidate className='form'>
					<div className='card-body border-top p-9'>
						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>Logo</label>
							<div className='col-lg-8'>
								<div className='image-input image-input-outline' data-kt-image-input='true' style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}>
									<div className='image-input-wrapper w-125px h-125px' style={{ backgroundImage: `url(${company?.logo?.url})` }}></div>
								</div>
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>{translate('FORM.INPUT.ORGANIZATION_NAME')}</label>

							<div className='col-lg-8'>
								<div className='row'>
									<div className='col-12 fv-row'>
										<input type='text' className='form-control form-control-lg form-control-solid mb-3 mb-lg-0' placeholder='First name' {...formik.getFieldProps('name')} />
										{formik.touched.name && formik.errors.name && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>{formik.errors.name}</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>{translate('FORM.INPUT.DESCRIPTION')} </label>

							<div className='col-lg-8'>
								<div className='row'>
									<div className='col-12 fv-row'>
										<textarea rows={4} className='form-control form-control-lg form-control-solid' placeholder='Last name' {...formik.getFieldProps('description')} />
										{formik.touched.description && formik.errors.description && (
											<div className='fv-plugins-message-container'>
												<div className='fv-help-block'>{formik.errors.description}</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>{translate('FORM.INPUT.TAX_NUMBER')}</label>

							<div className='col-lg-8 fv-row'>
								<input type='text' className='form-control form-control-lg form-control-solid' placeholder='Tax number' {...formik.getFieldProps('tax_number')} />
								{formik.touched.tax_number && formik.errors.tax_number && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.tax_number}</div>
									</div>
								)}
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>
								<span className='required'>{translate('FORM.INPUT.TAX_SERVICE_ADDRESS')}</span>
							</label>

							<div className='col-lg-8 fv-row'>
								<input type='tel' className='form-control form-control-lg form-control-solid' placeholder='Tax Service Address' {...formik.getFieldProps('tax_service_address')} />
								{formik.touched.tax_service_address && formik.errors.tax_service_address && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.tax_service_address}</div>
									</div>
								)}
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label fw-bold fs-6'>
								<span className='required'>{translate('FORM.INPUT.SETTLEMENT_ACCOUNT')}</span>
							</label>

							<div className='col-lg-8 fv-row'>
								<input type='text' className='form-control form-control-lg form-control-solid' placeholder='Settlement Account' {...formik.getFieldProps('settlement_account')} />
								{formik.touched.settlement_account && formik.errors.settlement_account && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.settlement_account}</div>
									</div>
								)}
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>{translate('FORM.INPUT.BIK')}</label>

							<div className='col-lg-8 fv-row'>
								<input type='text' className='form-control form-control-lg form-control-solid' placeholder='BIK' {...formik.getFieldProps('bik')} />
								{formik.touched.bik && formik.errors.bik && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.bik}</div>
									</div>
								)}
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>{translate('FORM.INPUT.FAX_NUMBER')}</label>

							<div className='col-lg-8 fv-row'>
								<input type='text' className='form-control form-control-lg form-control-solid' placeholder='Fax Number' {...formik.getFieldProps('fax_number')} />
								{formik.touched.fax_number && formik.errors.fax_number && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.fax_number}</div>
									</div>
								)}
							</div>
						</div>

						<div className='row mb-6'>
							<label className='col-lg-4 col-form-label required fw-bold fs-6'>{translate('FORM.INPUT.INN')}</label>

							<div className='col-lg-8 fv-row'>
								<input type='text' className='form-control form-control-lg form-control-solid' placeholder='ID Number' {...formik.getFieldProps('tj')} />
								{formik.touched.tj && formik.errors.tj && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.tj}</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className='card-footer d-flex justify-content-end py-6 px-9'>
						<button type='submit' className='btn btn-primary' disabled={loading}>
							{!loading && 'Save Changes'}
							{loading && (
								<span className='indicator-progress' style={{ display: 'block' }}>
									Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
								</span>
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}

export { Settings }
