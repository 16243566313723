import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import CustomerItem from './components/CustomerItem'
import useTranslate from '../../../../../_theme/helpers/translate'

const Customers = () => {
	const dispatch = useDispatch()
	const translate = useTranslate()

	return (
		<div>
			<div className='card card-flush'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					<div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>

							<input type='text' data-kt-ecommerce-product-filter='search' className='form-control form-control-solid w-250px ps-14' placeholder='Customers Ara' />
						</div>
					</div>

					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						<div className='w-100 mw-150px'>
							<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Status' data-kt-ecommerce-product-filter='status'>
								<option disabled>{translate('CARD.TEXT.STATUS')}</option>
								<option value='all'>All</option>
								<option value='published'>Paid</option>
								<option value='scheduled'>Wait</option>
							</select>
						</div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px  scroll-x'>
					<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
						<thead>
							<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
								<th className='min-w-200px'>Customer Name</th>
								<th className='text-end min-w-100px'>{translate('TABLE.TITLE.EMAIL')}</th>
								<th className='text-end min-w-100px'>{translate('TABLE.TITLE.PHONE')}</th>
								<th className='text-end min-w-100px'>Created Date</th>
							</tr>
						</thead>
						<tbody className='fw-bold text-gray-600'>
							<CustomerItem />
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default Customers
