import { createSlice } from '@reduxjs/toolkit'
import { IOrgonizationFilial } from '../../organization/models/OrganizationFilialModel'

interface BranchesState {
	branches: IOrgonizationFilial
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
}

const initialState: BranchesState = {
	branches: {} as IOrgonizationFilial,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
}

export const BranchesSlice = createSlice({
	name: 'branches',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setBranches: (state, action) => {
			state.branches = action.payload
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setBranches, setRefresh } = BranchesSlice.actions

export default BranchesSlice.reducer
