import { useDispatch } from 'react-redux'

import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../_theme/helpers'

const CustomerItem = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const nav = () => {
		navigate(`/company/${1}`, { replace: true })
	}
	return (
		<>
			<tr>
				<td>
					<div className='d-flex align-items-center'>
						<div className='symbol symbol-50px'>
							<span
								className='symbol-label'
								style={{
									backgroundImage: `url(${toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
								}}
							></span>
						</div>

						<div className='ms-5'>
							<div onClick={nav} className='text-gray-800 text-hover-primary fs-5 fw-bolder' data-kt-ecommerce-product-filter='product_name'>
								Emma Smith
							</div>
						</div>
					</div>
				</td>
				<td className='text-end pe-0' data-order='Published'>
					<div className=''>smith@kpmg.com</div>
				</td>
				<td className='text-end pe-0' data-order='Published'>
					<div className=''>+905523371080</div>
				</td>
				<td className='text-end pe-0' data-order='Published'>
					14.07.2023, 8:43
				</td>
			</tr>
		</>
	)
}

export default CustomerItem
