import React, { FC, useEffect, useState } from 'react'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../../../setup/hooks/redux'
import { Api } from '../../Services/api'
import { UserRoles } from '../../../_theme/helpers'
import OrderItem from '../../components/OrderItem'
import { IOrderModel } from '../../models/OrderModel'
import { SearchComponent } from '../../components/SearchBox'
import Pagination from '../../components/Pagination'
import { Modal, Spinner } from 'react-bootstrap'
import { OrderDeliveryStatus } from '../../../_theme/helpers/enums/OrderStatus'
import useTranslate from '../../../_theme/helpers/translate'
import { AddInvoice } from '../../Modals/AddInvoice'
import { ModalHeader } from '../../Modals/ModalHeader'
import { AddInvoiceForDelivery } from '../../Modals/AddInvoiceForDelivery'

type Props = {
	req?: string
}

const Orders: FC<Props> = ({ req = '' }) => {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const translate = useTranslate()
	const { refresh } = useAppSelector((state) => state.refresh)

	//States
	const [orders, setOrders] = useState<IOrderModel[]>([])
	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [total, setToltal] = useState(1)
	const [searchKeyClick, setSearchKeyClick] = useState('')
	const [statusSelect, setStatusSelect] = useState('')
	const [selectedOrderId, setSelectedOrderId] = useState(null)

	const [show, setShow] = useState(false)
	const [showDelivery, setShowDelivery] = useState(false)
	const openModal = (orderId: any, type: string) => {
		if (type === 'org') setShow(true)
		else setShowDelivery(true)
		setSelectedOrderId(orderId)
	}
	const closeModal = () => {
		setShowDelivery(false)
		setShow(false)
	}

	useEffect(() => {
		setLoading(true)
		const search = searchKeyClick ? `&title=${searchKeyClick}` : ''
		if (user.role === UserRoles.SUPER_ADMIN) {
			Api()
				.orders.getAllOrders(limit, currentPage, `${search}${statusSelect}${req}`)
				.then((res) => {
					setOrders(res?.items)
					setToltal(res?.total_count)
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => setLoading(false))
		}
		if (user.role === UserRoles.ORGANIZATION_OWNER) {
			Api()
				.orders.getAllOrganization(limit, currentPage, `${search}${statusSelect}${req}`)
				.then((res) => {
					setOrders(res?.items)
					setToltal(res?.total_count)
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => setLoading(false))
		}
		if (user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) {
			Api()
				.orders.getAllOrganizationFilial(limit, currentPage, `${search}${statusSelect}${req}`)
				.then((res) => {
					setOrders(res?.items)
					setToltal(res?.total_count)
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => setLoading(false))
		}
		if (user.role === UserRoles.DELIVERY_COMPANY_MANAGER) {
			Api()
				.orders.getAllDelivery(limit, currentPage, `${search}${statusSelect}${req}`)
				.then((res) => {
					setToltal(res?.total_count)
					setOrders(res?.items)
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => setLoading(false))
		}
		if (user.role === UserRoles.DELIVERY_FILIAL_MANAGER) {
			Api()
				.orders.getAllDeliveryFilial(limit, currentPage, `${search}${statusSelect}${req}`)
				.then((res) => {
					setToltal(res?.total_count)
					setOrders(res?.items)
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => setLoading(false))
		}
	}, [refresh, currentPage, searchKeyClick, statusSelect])

	return (
		<div>
			<div className='card card-flush'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					<div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>
							<SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Search'} />
						</div>
					</div>
					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						<div className='w-100 mw-150px'>
							<select className='form-select form-select-solid' onChange={(e) => setStatusSelect(e.target.value)} data-control='select2' data-hide-search='true' data-placeholder='Status' data-kt-ecommerce-product-filter='status'>
								<option disabled>{translate('CARD.TEXT.STATUS')}</option>
								<option value=''>{translate('STATUS.TEXT.ALL')}</option>
								<option value={`&delivery_status=${OrderDeliveryStatus.DELIVERED}`}>{translate(`STATUS.TEXT.${OrderDeliveryStatus.DELIVERED.toUpperCase()}`)}</option>
								<option value={`&delivery_status=${OrderDeliveryStatus.IN_FILIAL}`}>{translate(`STATUS.TEXT.${OrderDeliveryStatus.IN_FILIAL.toUpperCase()}`)}</option>
								<option value={`&delivery_status=${OrderDeliveryStatus.ON_WAY}`}>{translate(`STATUS.TEXT.${OrderDeliveryStatus.ON_WAY.toUpperCase()}`)}</option>
								<option value={`&delivery_status=${OrderDeliveryStatus.PENDING}`}>{translate(`STATUS.TEXT.${OrderDeliveryStatus.PENDING.toUpperCase()}`)}</option>
							</select>
						</div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px  scroll-x'>
					{loading ? (
						<div className='text-center'>
							<Spinner className='p-5 text-primary' />
						</div>
					) : (
						<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
							<thead>
								<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
									<th className=''>{translate('TABLE.TITLE.ORDER_ID')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.CUSTOMER')}</th>
									{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.DELIVERY_COMPANY_MANAGER || user.role === UserRoles.DELIVERY_COMPANY_MANAGER || user.role === UserRoles.DELIVERY_FILIAL_MANAGER) && <th className='text-end min-w-100px'>{translate('TABLE.TITLE.ORGANIZATION')}</th>}
									{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && <th className='text-end min-w-100px'>Deliveri</th>}
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.BENEFIT')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.TAX_FOR_TURKISHASIA')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.DELIVERY_PAID')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.TAX_FOR_TURKISHASIA')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.TOTAL_PAID')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.DATE_ADDED')}</th>
									{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && <th className='text-end min-w-100px'>{translate('TABLE.TITLE.PAID_TO_ORG')}</th>}
									{(user.role === UserRoles.SUPER_ADMIN || user?.role == UserRoles.DELIVERY_COMPANY_MANAGER || user?.role == UserRoles.DELIVERY_FILIAL_MANAGER) && <th className='text-end min-w-100px'>{translate('TABLE.TITLE.PAID_TO_DELIVERY')}</th>}
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.STATUS')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.DELIVERY')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.ACTION')}</th>
								</tr>
							</thead>
							<tbody className='fw-bold text-gray-600'>
								{orders?.map((item, index) => {
									return <OrderItem key={index} item={item} openModal={openModal} />
								})}
							</tbody>
						</table>
					)}
					<Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} />
				</div>
			</div>
			<Modal show={show} onHide={closeModal}>
				<Modal.Body>
					<ModalHeader handleClose={closeModal} titleHeader={'Add Invoice'} />
					<AddInvoice orderId={selectedOrderId} handleClose={closeModal} />
				</Modal.Body>
			</Modal>
			<Modal show={showDelivery} onHide={closeModal}>
				<Modal.Body>
					<ModalHeader handleClose={closeModal} titleHeader={'Add Invoice For Delivery'} />
					<AddInvoiceForDelivery orderId={selectedOrderId} handleClose={closeModal} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default Orders
