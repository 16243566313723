import React from 'react'
import ProductName from './components/ProductName'
import Media from './components/Media'
import Pricing from './components/Pricing'

function General() {
	return (
		<div className='tab-pane fade show active' id='kt_ecommerce_add_product_general' role='tab-panel'>
			<div className='d-flex flex-column gap-7 gap-lg-10'>
				<ProductName />
				<Media />
				<Pricing />
			</div>
		</div>
	)
}

export default General
