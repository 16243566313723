import moment from 'moment'
import { FC, useState } from 'react'
import { Discount } from '../../../../../models/ProductModel'
import useTranslate from '../../../../../../_theme/helpers/translate'
import { Dropdown } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { Api } from '../../../../../Services/api'
import { errorMsg, successMsg } from '../../../../../../_theme/helpers/AlertMsg'
import { setRefresh } from '../../../../../reducers/RefreshSlice'
import AddDiscount from '../modals/AddDiscount'

type Props = {
	discount: Discount
	setRecord: any
	handleOpenModal: any
}

const DiscountItem: FC<Props> = ({ discount, setRecord, handleOpenModal }) => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	function swalDelete() {
		Swal.fire({
			title: 'The brand will be deleted!',
			text: 'Are you sure to delete?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cencel',
			confirmButtonText: 'Delete',
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				Api()
					.discount.delete(discount?.id)
					.then((res) => {
						successMsg(res?.message)
						dispatch(setRefresh())
					})
					.catch((err) => {
						errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
					})
			} else if (result.isDenied) {
			}
		})
	}
	return (
		<tr>
			<td>
				<div className='d-flex align-items-center'>
					<div className=''>
						<div className='text-gray-800 fs-8 '>
							{moment(discount?.start_date).format('DD.MM.YYYY')} - {moment(discount?.end_date).format('DD.MM.YYYY')}
						</div>
					</div>
				</div>
			</td>
			<td>
				<div className='rating'>
					{discount?.procent} {discount?.type === 'percentage' ? '%' : translate('CURRENCY.TEXT.SOM')}
				</div>
			</td>

			<td className='text-end pe-0' data-order='Published'>
				{discount.status == 'active' ? <div className='badge badge-light-success '>Active</div> : <div className='badge badge-light-danger '>Pasife</div>}
			</td>

			<td className='text-end'>
				<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
					<Dropdown.Toggle variant='primary' id='dropdown-basic'>
						{translate('TABLE.TITLE.ACTION')}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item>
							<div className='menu-item px-3'>
								<div
									onClick={() => {
										setRecord(discount)
										handleOpenModal()
									}}
									className='menu-link px-3'
								>
									Edit
								</div>
							</div>
						</Dropdown.Item>
						<Dropdown.Item>
							<div onClick={swalDelete} className='menu-item px-3'>
								<div className='menu-link px-3' data-kt-ecommerce-product-filter='delete_row'>
									Delete
								</div>
							</div>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	)
}

export default DiscountItem
