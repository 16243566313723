import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Api } from '../../../Services/api'
import { SearchComponent } from '../../../components/SearchBox'
import DeliveryItem from './DeliveryItem'
import IDeliveries from '../../deliveries/models/DeliveriesModel'
import useTranslate from '../../../../_theme/helpers/translate'

const DeliveriesStatistics = () => {
	const translate = useTranslate()
	// States
	const [delivery, setDeliverys] = useState<IDeliveries[]>([])
	const [total, setTotal] = useState(0)
	const [currentTotal, setCurrentTotal] = useState(0)
	const [taxTotal, setTaxTotal] = useState(0)
	const [paidTotal, setPaidTotal] = useState(0)
	const [loading, setLoading] = useState(true)
	const [searchKeyClick, setSearchKeyClick] = useState('')

	useEffect(() => {
		setLoading(true)
		const search = searchKeyClick ? `&name=${searchKeyClick}` : ''

		Api()
			.deliveries.getAllForStatistics(100, 1, search)
			.then((res) => {
				setDeliverys(res?.items)
				setTotal(res?.items?.reduce((acc: any, curr: { balance: { total: any } }) => (acc += curr?.balance?.total), 0))
				setPaidTotal(res?.items?.reduce((acc: any, curr: { balance: { withdrew: any } }) => (acc += curr?.balance?.withdrew), 0))
				setTaxTotal(res?.items?.reduce((acc: any, curr: { balance: { tax_for_ta: any } }) => (acc += curr?.balance?.tax_for_ta), 0))
				setCurrentTotal(res?.items?.reduce((acc: any, curr: { balance: { current: any } }) => (acc += curr?.balance?.current), 0))
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => setLoading(false))

		return () => {}
	}, [searchKeyClick])

	return (
		<div>
			<div className='card card-flush'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					<div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>

							<SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Search'} />
						</div>
					</div>

					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						<div className='w-100 mw-150px'></div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px scroll-x scroll-y position-reletive' style={{ maxHeight: '600px' }}>
					{loading ? (
						<div className='text-center'>
							<Spinner className='p-5 text-primary' />
						</div>
					) : (
						<table className='table align-middle table-row-dashed fs-6 gy-5'>
							<thead>
								<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 ' style={{ position: 'sticky', top: '0px', zIndex: '100', backgroundColor: '#fff' }}>
									<th className='min-w-200px'>{translate('TABLE.TITLE.DELIVERY')}</th>
									<th className='text-end min-w-100px'>{translate('CARD.TEXT.TOTALS')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.CURRENT')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.TAX_FOR_TA')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.PAID')}</th>
									<th className='text-end min-w-100px'>{translate('TABLE.TITLE.REMAINDER')}</th>
								</tr>
							</thead>
							<tbody className='fw-bold text-gray-600'>
								<tr>
									<td>{translate('CARD.TEXT.TOTALS')}</td>
									<td className='text-end pe-0 text-purple'>{total}</td>
									<td className='text-end pe-0 text-primary'>{currentTotal}</td>
									<td className='text-end pe-0 text-orange'>{taxTotal}</td>
									<td className='text-end pe-0 text-success'>{paidTotal}</td>
									<td className='text-end pe-0 text-pink'>{currentTotal}</td>
								</tr>
								{delivery?.map((item) => {
									return <DeliveryItem key={item.id} item={item} />
								})}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</div>
	)
}

export default DeliveriesStatistics
