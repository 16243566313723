import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../../_theme/helpers'
import { Api } from '../../../Services/api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { setAddProduct } from '../reducers/ProductSlice'
import useTranslate from '../../../../_theme/helpers/translate'
const Thumbnail = () => {
	const translate = useTranslate()
	const [photo, setPhoto] = useState<any>(null)
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)
	const uploadImg = (e: any) => {
		let formData = new FormData()
		formData.append('file', e.target.files[0])
		Api()
			.products.imageUpload(formData)
			.then((res) => {
				setPhoto(res)
				dispatch(setAddProduct({ ...addProduct, mainPicture: res?.id }))
			})
			.catch((err) => {
				console.log(err)
			})
	}
	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.THUMBNAIL')}</h2>
				</div>
			</div>

			<div className='card-body text-center pt-0'>
				<div
					className='image-input image-input-empty image-input-outline mb-3'
					data-kt-image-input='true'
					style={{
						backgroundImage: `url(${photo ? photo?.url : toAbsoluteUrl(`/media/svg/files/blank-image.svg`)})`,
					}}
				>
					<div className='image-input-wrapper w-150px h-150px'></div>

					<label className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' data-kt-image-input-action='change' data-bs-toggle='tooltip' title='' data-bs-original-title='Change avatar'>
						<i className='bi bi-pencil-fill fs-7'></i>

						<input type='file' onChange={uploadImg} name='avatar' accept='.png, .jpg, .jpeg, .webp' />
						<input type='hidden' name='avatar_remove' />
					</label>

					<span className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' data-kt-image-input-action='cancel' data-bs-toggle='tooltip' title='' data-bs-original-title='Cancel avatar'>
						<i className='bi bi-x fs-2'></i>
					</span>

					<span className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' data-kt-image-input-action='remove' data-bs-toggle='tooltip' title='' data-bs-original-title='Remove avatar'>
						<i className='bi bi-x fs-2'></i>
					</span>
				</div>

				<div className='text-muted fs-7'>Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted</div>
			</div>
		</div>
	)
}

export default Thumbnail
