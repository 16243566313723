import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { Modal } from 'react-bootstrap'
import { ModalHeader } from '../../../Modals/ModalHeader'
import Variations from '../modals/UpdatedVariant'
import UpdateType from '../modals/UpdatedType'
import { Specification } from '../../../models/ProductModel'
import useTranslate from '../../../../_theme/helpers/translate'

const AboutProduct = () => {
	const translate = useTranslate()
	const { product } = useAppSelector((state) => state.product)
	const [showModal, setShowModal] = useState(false)
	const [selectedSpec, setSelectedSpec] = useState<Specification>({} as Specification)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	const [selectedType, setSelectedType] = useState<string>('')

	const [showModalType, setShowModalType] = useState(false)
	const handleOpenModalType = () => {
		setShowModalType(true)
	}
	const handleCloseModalType = () => setShowModalType(false)

	return (
		<div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
			<div className='card-header cursor-pointer'>
				<div className='card-title m-0'>
					<h3 className='fw-bolder m-0'>{translate('CARD.TEXT.PRODUCT_DETAILS')}</h3>
				</div>

				<Link to='../update' className='btn btn-primary align-self-center'>
					Edit Product
				</Link>
			</div>

			<div className='card-body p-9'>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>Name</label>

					<div className='col-lg-8'>
						<span className='fw-bolder fs-6 text-dark'>{product.title}</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.DESCRIPTION')}</label>

					<div className='col-lg-8 fv-row'>
						<p className='fw-bold fs-6' dangerouslySetInnerHTML={{ __html: product.description }}></p>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>
						Base price
						<i className='fas fa-exclamation-circle ms-1 fs-7' data-bs-toggle='tooltip' title='Phone number must be active'></i>
					</label>

					<div className='col-lg-8 d-flex align-items-center'>
						<span className='fw-bolder fs-6 me-2'>{product?.base_price}</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>Price with Tax</label>

					<div className='col-lg-8 fw-bolder'>{product?.price_with_tax}</div>
				</div>

				{/* <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Country
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{product}</span>
          </div>
        </div> */}

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>SKU</label>

					<div className='col-lg-8'>
						<span className='fw-bolder fs-6 text-dark'>{product?.article}</span>
					</div>
				</div>

				<div className='row mb-10'>
					<label className='col-lg-4 fw-bold text-muted'>Amount</label>

					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{product.amount}</span>
					</div>
				</div>
				<div className='row mb-10'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.BRAND')}</label>

					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{product?.brand?.name}</span>
					</div>
				</div>
				{product?.types?.map((item, index) => {
					return (
						<div key={index} className='row mb-10'>
							<label
								onClick={() => {
									setSelectedType(item?.type)
									handleOpenModalType()
								}}
								className='col-lg-4 fw-bold text-muted'
							>
								{item?.type}
							</label>

							<div className='col-lg-8'>
								{item?.product_specifications?.map((spec, index) => {
									return (
										<div
											key={index}
											onClick={() => {
												setSelectedSpec(spec)
												handleOpenModal()
											}}
											style={{ background: item?.type == 'color' ? spec?.value : '' }}
											className='symbol me-4'
										>
											<span className='badge badge-circle bg-primary position-absolute p-1' style={{ top: '-5px', right: '-5px', fontSize: '10px' }}>
												{spec?.amount}
											</span>
											<div style={{ background: item?.type == 'color' ? spec?.value : '' }} className='symbol-label fw-bold cursor-pointer'>
												{spec?.value}
											</div>
										</div>
									)
								})}
							</div>
						</div>
					)
				})}
			</div>
			{showModalType && <UpdateType type={selectedType} isOpen={showModalType} onClose={handleCloseModalType} />}
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={'Update'} />
					<Variations spec={selectedSpec} handleClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default AboutProduct
