import React, { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_theme/layout/core'
import { BranchesHeader } from './BranchesHeader'
import UpdatedBranches from './components/Details'
import Reviews from './components/reviews/Reviews'
import AddProduct from '../products/AddProduct'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../setup/hooks/redux'
import { Api } from '../../Services/api'
import { Settings } from './components/settings/Settings'
import { setBranches } from './reducers/BranchesSlice'
import Products from './components/products/Products'
import Orders from '../orders/Orders'
import useTranslate from '../../../_theme/helpers/translate'
import CancellationRequest from '../cancellationRequest/cancellationRequest'

const branchesBreadCrumbs: Array<PageLink> = [
	{
		title: 'Organizations',
		path: '/organizations',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
]

const BranchesPage: React.FC<{ children?: React.ReactNode }> = () => {
	const { id } = useParams()
	const translate = useTranslate()
	const dispatch = useDispatch()

	useEffect(() => {
		Api()
			.organization.getOneFilial(id)
			.then((res) => {
				dispatch(setBranches(res))
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])
	return (
		<Routes>
			<Route
				element={
					<>
						<BranchesHeader />
						<Outlet />
					</>
				}
			>
				<Route
					path='detail'
					element={
						<>
							<PageTitle breadcrumbs={branchesBreadCrumbs}>{translate('CARD.TEXT.ABOUT')}</PageTitle>
							<UpdatedBranches />
						</>
					}
				/>
				<Route
					path='reservations'
					element={
						<>
							<PageTitle breadcrumbs={branchesBreadCrumbs}>{translate('CARD.TEXT.CUSTOMERS')}</PageTitle>
						</>
					}
				/>
				<Route
					path='products'
					element={
						<>
							<PageTitle breadcrumbs={branchesBreadCrumbs}>{translate('CARD.TEXT.PRODUCTS')}</PageTitle>
							<Products />
						</>
					}
				/>
				<Route
					path='add-product'
					element={
						<>
							<PageTitle breadcrumbs={branchesBreadCrumbs}>Add Products</PageTitle>
							<AddProduct />
						</>
					}
				/>
				<Route
					path='reviews'
					element={
						<>
							<PageTitle breadcrumbs={branchesBreadCrumbs}>{translate('CARD.TEXT.REVIEWS')}</PageTitle>
							<Reviews />
						</>
					}
				/>
				<Route
					path='orders'
					element={
						<>
							<PageTitle breadcrumbs={branchesBreadCrumbs}>{translate('CARD.TEXT.PRODUCTS')}</PageTitle>
							<Orders req={`&organization_filial_id=${id}`} />
						</>
					}
				/>
				<Route
					path='returns'
					element={
						<>
							<PageTitle breadcrumbs={branchesBreadCrumbs}>{translate('CARD.TEXT.REFUNDS')}</PageTitle>
							<CancellationRequest req={`&organization_filial_id=${id}`} />
						</>
					}
				/>
				<Route
					path='setting'
					element={
						<>
							<PageTitle breadcrumbs={branchesBreadCrumbs}>{translate('CARD.TEXT.REFUNDS')}</PageTitle>
							<Settings />
						</>
					}
				/>
				<Route index element={<Navigate to={`./detail`} />} />
			</Route>
		</Routes>
	)
}

export default BranchesPage
