import { createSlice } from '@reduxjs/toolkit'
import { IProduct } from '../../../models/ProductModel'

interface ProductState {
	products: IProduct[]
	product: IProduct
	total: number
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
	addProduct: AddProduct
}

interface AddProduct {
	status: number
	selectedCategories: number[]
	selectBrand: any[]
	productName: string
	productDesc: string
	article: string
	basePrice: number
	mainPicture: number | null
	discountType: string
	discount: number
	discountStartDate: string
	discountEndDate: string
	metaName: string
	metaDesc: string
	taxType: number | null
	taxProcent: number | null
	types: Types[]
	relations: number[]
	child_pictures: number[]
	bindDelivery: IBindDelivery[]
	counrtiesForSaleIds: number[]
	fromCountryId: any[]
	delivery_payment_by: string
	limit_to_payment_delivery: string | number
	length: number
	width: number
	height: number
	weight: number
	is_reproducible: boolean
	is_wholesale: boolean
	can_refund: boolean
	quantity: number
}
interface Types {
	type: string
	specifications: Specification[]
}

export interface IBindDelivery {
	delivery_company_id: number
	delivery_company_filial_id: number
	country_id: number
}
interface ISize {}

interface Specification {
	value: string
	// amount: string
}

const initialState: ProductState = {
	products: [],
	product: {} as IProduct,
	total: 0,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
	addProduct: {
		status: 1,
		selectedCategories: [],
		selectBrand: [],
		article: '',
		productName: '',
		productDesc: '',
		basePrice: 0,
		mainPicture: null,
		discountType: '1',
		discount: 0,
		discountStartDate: '',
		discountEndDate: '',
		metaName: '',
		metaDesc: '',
		taxType: null,
		taxProcent: null,
		types: [],
		relations: [],
		child_pictures: [],
		counrtiesForSaleIds: [],
		fromCountryId: [],
		delivery_payment_by: '',
		limit_to_payment_delivery: '',
		length: 0,
		width: 0,
		height: 0,
		weight: 0,
		is_reproducible: true,
		is_wholesale: false,
		can_refund: true,
		bindDelivery: [],
		quantity: 0,
	},
}

export const ProductSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setProducts: (state, action) => {
			state.products = action.payload.data
			state.total = action.payload.total
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
		setAddProduct: (state, action) => {
			state.addProduct = action.payload
		},
		setProduct: (state, action) => {
			state.product = action.payload
		},
	},
})

export const { openModal, closeModal, setProducts, setRefresh, setAddProduct, setProduct } = ProductSlice.actions

export default ProductSlice.reducer
