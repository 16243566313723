import { createSlice } from '@reduxjs/toolkit'
import IBrand from '../models/BrandModel'

interface BrandState {
	brands: IBrand[]
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
	total: number
}

const initialState: BrandState = {
	brands: [],
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
	total: 0,
}

export const BrandSlice = createSlice({
	name: 'brands',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setBrand: (state, action) => {
			state.brands = action.payload.data
			state.total = action.payload.total
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setBrand, setRefresh } = BrandSlice.actions

export default BrandSlice.reducer
