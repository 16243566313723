import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import useTranslate from '../../_theme/helpers/translate'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Api } from '../Services/api'
import { errorMsg, successMsg } from '../../_theme/helpers/AlertMsg'
import { UserModel } from '../modules/auth/models/UserModel'
import { RootState } from '../../setup'

const initialValues = {
	old_password: '',
	new_password: '',
	password: '',
}

type Props = {
	onClose: any
}

export const ResetPassword: FC<Props> = ({ onClose }) => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

	const [loading, setLoading] = useState(false)
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
	const resetPasswordSchema = Yup.object().shape({
		old_password: Yup.string().required('Password is required'),
		password: Yup.string().required('Password is required'),
		new_password: Yup.string()
			.required('Password is required')
			.oneOf([Yup.ref('password')], translate('AUTH.INPUT.NEW_PASSWORD_WRONG')),
	})
	const formik = useFormik({
		initialValues,
		validationSchema: resetPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true)
			setHasErrors(undefined)
			const body = {
				email: user?.email,
				old_password: values.old_password,
				new_password: values.new_password,
			}
			Api()
				.user.resetPassword(body)
				.then((res) => {
					successMsg(res?.message)
					onClose()
				})
				.catch((err) => errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message))
		},
	})

	return (
		<>
			<form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_password_reset_form' onSubmit={formik.handleSubmit}>
				<div className='text-center mb-10'>
					{/* begin::Title */}
					<h1 className='text-dark mb-3'>{translate('CARD.TEXT.REST_PASSWORD')}</h1>
					{/* end::Title */}

					{/* begin::Link */}
					<div className='text-gray-400 fw-bold fs-4'>{translate('AUTH.INPUT.PASSWORD')}.</div>
					{/* end::Link */}
				</div>

				{/* begin::Form group */}
				<div className='fv-row mb-10'>
					<label className='form-label fw-bolder text-gray-900 fs-6'>{translate('AUTH.INPUT.OLD_PASSWORD')}</label>
					<input
						type='password'
						placeholder=''
						autoComplete='off'
						{...formik.getFieldProps('old_password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.old_password && formik.errors.old_password },
							{
								'is-valid': formik.touched.old_password && !formik.errors.old_password,
							}
						)}
					/>
					{formik.touched.old_password && formik.errors.old_password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.old_password}</span>
							</div>
						</div>
					)}
				</div>
				<div className='fv-row mb-10'>
					<label className='form-label fw-bolder text-gray-900 fs-6'>{translate('AUTH.INPUT.PASSWORD')}</label>
					<input
						type='password'
						placeholder=''
						autoComplete='off'
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.password && formik.errors.password },
							{
								'is-valid': formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					{formik.touched.password && formik.errors.password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.password}</span>
							</div>
						</div>
					)}
				</div>
				<div className='fv-row mb-10'>
					<label className='form-label fw-bolder text-gray-900 fs-6'>{translate('AUTH.INPUT.AGAING_PASSWORD')}</label>
					<input
						type='password'
						placeholder=''
						autoComplete='off'
						{...formik.getFieldProps('new_password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.new_password && formik.errors.new_password },
							{
								'is-valid': formik.touched.new_password && !formik.errors.new_password,
							}
						)}
					/>
					{formik.touched.new_password && formik.errors.new_password && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{formik.errors.new_password}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className='d-flex flex-wrap justify-content-center pb-lg-0'>
					<button type='submit' id='kt_password_reset_submit' className='btn btn-lg btn-primary fw-bolder me-4'>
						<span className='indicator-label'>{translate('AUTH.GENERAL.SUBMIT_BUTTON')}</span>
						{loading && (
							<span className='indicator-progress'>
								Please wait...
								<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
							</span>
						)}
					</button>
					<div>
						<button onClick={onClose} className='btn btn-lg btn-light-primary fw-bolder'>
							{translate('CARD.TEXT.CANCEL')}
						</button>
					</div>{' '}
				</div>
				{/* end::Form group */}
			</form>
		</>
	)
}
