import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_theme/layout/core'
import { DeldeliveryHeader } from './DeliveryHeader'

import { useDispatch } from 'react-redux'
import { Api } from '../../Services/api'
import { Settings } from './components/settings/Settings'

import { DeliveryDetail } from './components/DeliveryDetail'
import { useAppSelector } from '../../../setup/hooks/redux'
import { Spinner } from 'react-bootstrap'
import { setDelivery } from './reducers/DeliverySlice'
import Orders from '../orders/Orders'
import DeliveryFilials from '../delivery-filials/Deliveries'
import useTranslate from '../../../_theme/helpers/translate'
import CancellationRequest from '../cancellationRequest/cancellationRequest'

const companyBreadCrumbs: Array<PageLink> = [
	{
		title: 'Organizations',
		path: '/organization',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
]

const DeliveryPage: React.FC<{ children?: React.ReactNode }> = () => {
	const { id } = useParams()
	const dispatch = useDispatch()
	const translate = useTranslate()
	const [loading, setLoading] = useState(false)
	const { refresh } = useAppSelector((state) => state.refresh)

	useEffect(() => {
		setLoading(true)
		Api()
			.deliveries.getOne(id)
			.then((res) => {
				dispatch(setDelivery(res))
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => setLoading(false))
	}, [refresh])
	return (
		<Routes>
			<Route
				element={
					<>
						<DeldeliveryHeader />
						<Outlet />
					</>
				}
			>
				<Route
					path='detail'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.ABOUT')}</PageTitle>
							<DeliveryDetail />
						</>
					}
				/>
				<Route
					path='reservations'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.CUSTOMERS')}</PageTitle>
						</>
					}
				/>
				<Route
					path='branches'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.PRODUCTS')}</PageTitle>
							<DeliveryFilials req={`&delivery_company_id=${id}`} />
						</>
					}
				/>
				<Route
					path='active'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.ORDERS')}</PageTitle>
							<Orders req={`&delivery_company_id=${id}`} />
						</>
					}
				/>
				<Route
					path='returns'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.REFUNDS')}</PageTitle>
							<CancellationRequest req={`&delivery_company_id=${id}`} />
						</>
					}
				/>
				<Route
					path='setting'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.UPDATE')}</PageTitle>
							{loading ? <Spinner /> : <Settings />}
						</>
					}
				/>
				<Route index element={<Navigate to={`./detail`} />} />
			</Route>
		</Routes>
	)
}

export default DeliveryPage
