import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../_theme/helpers'
import { ICourier } from '../models/CourierModal'
import { Dropdown } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { Api } from '../Services/api'
import { useDispatch } from 'react-redux'
import { setRefresh } from '../reducers/RefreshSlice'
import useTranslate from '../../_theme/helpers/translate'

type Props = {
	item: ICourier
}

const CourierItem: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const nav = () => {
		navigate(`/courier/${item.id}`, { replace: true })
	}

	function lockUnlock() {
		Swal.fire({
			title: "Won't you lock/unlock this courier",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes',
		}).then((result) => {
			if (result.isConfirmed) {
				Api()
					.user.lockUnlock(item?.is_locked ? 'unlock' : 'lock', { user_id: item.id })
					.then((res) => {
						dispatch(setRefresh())
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: 'Company canceled',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			}
		})
	}

	return (
		<tr>
			<td>
				<div className='d-flex align-items-center cursor-pointer'>
					<div onClick={nav} className='symbol symbol-50px'>
						<span
							className='symbol-label'
							style={{
								backgroundImage: `url(${toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
							}}
						></span>
					</div>

					<div className='ms-5'>
						<div onClick={nav} className='text-gray-800 text-hover-primary fs-5 fw-bolder'>
							{item?.name} <br />
							<small>{item?.courier_filial?.name}</small>
						</div>
					</div>
				</div>
			</td>
			<td className='pe-0 text-gray-600 fs-7'>{item?.email}</td>
			<td className='pe-0 text-gray-600 fs-7'>{item?.phone}</td>
			<td>
				<div className={`badge badge-${item?.is_locked ? 'light-danger' : 'light-success'} `}>{item?.is_locked ? 'Passive' : 'Active'}</div>
			</td>

			<td className='text-end'>
				<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
					<Dropdown.Toggle variant='primary' id='dropdown-basic'>
						{translate('TABLE.TITLE.ACTION')}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item>
							<div className='menu-item px-3'>
								<div onClick={lockUnlock} className='menu-link px-3'>
									{item?.is_locked ? 'Unlock' : 'Lock'}
								</div>
							</div>
						</Dropdown.Item>
						{/* <Dropdown.Item>
								<div className='menu-item px-3'>
									<div onClick={handleOpenModal} className='menu-link px-3'>
										Edit
									</div>
								</div>
							</Dropdown.Item> */}
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	)
}

export default CourierItem
