import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { ModalHeader } from '../../../Modals/ModalHeader'
import AddDelivery from '../../deliveries/modals/AddDelivery'
import { cm3ToM3 } from '../../../../_theme/helpers'
import useTranslate from '../../../../_theme/helpers/translate'

export const DeliveryDetail = () => {
	const translate = useTranslate()
	const { delivery } = useAppSelector((state) => state.delivery)
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)
	useEffect(() => {}, [])

	return (
		<div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
			<div className='card-header cursor-pointer'>
				<div className='card-title m-0'>
					<h3 className='fw-bolder m-0'>{translate('CARD.TEXT.DETAILS')}</h3>
				</div>
				<div className='d-flex align-items-center'>
					<Link to='../setting' className='btn btn-primary align-self-center mr-2'>
						Edit
					</Link>
				</div>
			</div>
			<div className='card-body p-9'>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.FULL_NAME')}</label>

					<div className='col-lg-8'>
						<span className='fw-bolder fs-6 text-dark'>{delivery?.manager?.name}</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.DELIVERY_NAME')}</label>

					<div className='col-lg-8 fv-row'>
						<span className='fw-bold fs-6'>{delivery?.name}</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.ADDRESS')}</label>

					<div className='col-lg-8 fv-row'>
						<span className='fw-bold fs-6'>{delivery?.address?.title}</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>
						{translate('FORM.INPUT.PHONE')}
						<i className='fas fa-exclamation-circle ms-1 fs-7' data-bs-toggle='tooltip' title='Phone number must be active'></i>
					</label>

					<div className='col-lg-8 d-flex align-items-center'>
						<span className='fw-bolder fs-6 me-2'>{delivery?.manager?.phone}</span>

						{/* <span className='badge badge-success'>Verified</span> */}
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>
						{translate('FORM.INPUT.EMAIL')}
						<i className='fas fa-exclamation-circle ms-1 fs-7' data-bs-toggle='tooltip' title='Phone number must be active'></i>
					</label>

					<div className='col-lg-8 d-flex align-items-center'>
						<span className='fw-bolder fs-6 me-2'>{delivery?.manager?.email}</span>

						{/* <span className='badge badge-success'>Verified</span> */}
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.DELIVERY_PRICE')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{delivery?.delivery_price} som</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.DELIVERY_REFUND_PRICE')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{delivery?.delivery_refund_price} som</span>
					</div>
				</div>

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.WEIGHT')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{delivery?.shipping_restriction?.weight} kg</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.LENGTH')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{delivery?.shipping_restriction?.length} cm</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.WIDTH')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{delivery?.shipping_restriction?.width} cm</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('FORM.INPUT.HEIGHT')}</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{delivery?.shipping_restriction?.height} cm</span>
					</div>
				</div>
				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>m3</label>
					<div className='col-lg-8'>
						<span className='fw-bold fs-6'>{cm3ToM3(delivery?.shipping_restriction?.length, delivery?.shipping_restriction?.width, delivery?.shipping_restriction?.height)} m3</span>
					</div>
				</div>

				{/* <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Country
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Country of origination'
            ></i>
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>Turkey</span>
          </div>
        </div> */}

				<div className='row mb-7'>
					<label className='col-lg-4 fw-bold text-muted'>{translate('CARD.TEXT.COMMUNICATION')}</label>

					<div className='col-lg-8'>
						<span className='fw-bolder fs-6 text-dark'>Email, Phone</span>
					</div>
				</div>

				{/* <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
          <KTSVG
            path='icons/duotune/general/gen044.svg'
            className='svg-icon-2tx svg-icon-warning me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
              <div className='fs-6 text-gray-600'>
                Your payment was declined. To start using tools, please
                <Link className='fw-bolder' to='/crafted/account/settings'>
                  {' '}
                  Add Payment Method
                </Link>
                .
              </div>
            </div>
          </div>
        </div> */}
			</div>
			<Modal size={'lg'} show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={translate('FORM.TITLE.NEW_ORGANIZATION')} />
					<AddDelivery update={true} handleClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
		</div>
	)
}
