import React, { useEffect, useState } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Api } from '../../../Services/api'
import { setAddProduct } from '../reducers/ProductSlice'
import { UserRoles } from '../../../../_theme/helpers'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'
import useTranslate from '../../../../_theme/helpers/translate'

const ProductRelations = () => {
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const [relations, setRelations] = useState([])
	const translate = useTranslate()
	const { addProduct } = useAppSelector((state) => state.product)
	const dispatch = useDispatch()
	const onSelectRelations = (selectedList: any, selectedItem: any) => {
		// dispatch(setCategory(selectedList.map(({id}: any) => id)))
		dispatch(setAddProduct({ ...addProduct, relations: selectedList }))
	}
	const onRemoveRelations = (selectedList: any, removedItem: any) => {
		dispatch(setAddProduct({ ...addProduct, relations: selectedList }))
	}

	useEffect(() => {
		if (user.role === UserRoles.ORGANIZATION_OWNER) {
			Api()
				.products.getOrgonizationAll(100, 1)
				.then((res) => {
					setRelations(res?.items?.map((item: any) => item?.product))
				})
				.catch((err) => {
					console.log(err)
				})
		}
		if (user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) {
			Api()
				.products.getOrgonizationFilialAll()
				.then((res) => {
					setRelations(res?.items)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [])

	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.PRODUCT_RELATIONS')}</h2>
				</div>
			</div>

			<div className='card-body pt-0'>
				<label className='form-label'>{translate('CARD.TEXT.PRODUCTS')}</label>

				<Multiselect
					options={relations} // Options to display in the dropdown
					selectedValues={addProduct.relations}
					avoidHighlightFirstOption={true}
					onSelect={onSelectRelations} // Function will trigger on select event
					onRemove={onRemoveRelations} // Function will trigger on remove event
					displayValue='title'
					// onSearch={function noRefCheck() {}}
					// showCheckbox // Property name to display in the dropdown options
					placeholder='Select'
					style={{
						chips: {
							background: '#4fc9da',

							wordWrap: 'break-word',
							wordBreak: 'break-word',
							overflowWrap: 'break-word',
						},
						searchBox: {
							borderRadius: '10px',
						},
					}}
				/>
			</div>
		</div>
	)
}

export default ProductRelations
