import React, { useEffect, useState } from 'react'
import BranchInfo from './BranchInfo'
import { Slider } from './Slider'
import Returns from './components/Returns'
import Couriers from './components/Couriers'
import { useParams } from 'react-router-dom'
import { Api } from '../../Services/api'
import { useAppSelector } from '../../../setup/hooks/redux'
import Orders from '../orders/Orders'
import { LocationMap } from '../../../_theme/partials/content/DrawPolygon/LocationMap'
import HistoryOrders from './components/HistoryOrders'
import IDeliveryBranch from '../../models/DeliveryBranchModel'
import OrdersComponent from '../../components/Orders/Orders'
import useTranslate from '../../../_theme/helpers/translate'
import CancellationRequest from '../cancellationRequest/cancellationRequest'

const Detail = () => {
	const translate = useTranslate()
	const { id } = useParams()
	const { refresh } = useAppSelector((state) => state.refresh)
	const [active, setActive] = useState(1)
	const [branch, setBranch] = useState<IDeliveryBranch>({} as IDeliveryBranch)
	const [show, setShow] = useState(false)

	useEffect(() => {
		setShow(false)
		Api()
			.deliveries.getOneFilial(id)
			.then((res) => {
				setBranch(res)
				setShow(true)
			})
	}, [refresh])

	return (
		<div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
			<div className='d-flex flex-column flex-column-fluid'>
				<div id='kt_app_content' className='app-content flex-column-fluid'>
					<div id='kt_app_content_container' className='app-container container-fluid p-0'>
						<div className='d-flex flex-column flex-lg-row '>
							<BranchInfo item={branch} />
							<div className='w-100 pt-0 p-10 pe-0 overflow-hidden'>
								<Slider active={active} setActive={setActive} />
								<div className='tab-content' id='myTabContent'>
									<div className='tab-pane fade show active' id='kt_user_view_overview_tab' role='tabpanel'>
										{active == 1 ? <Orders req={`&delivery_filial_id=${id}`} /> : active == 2 ? <HistoryOrders /> : active == 3 ? <CancellationRequest req={`&delivery_company_filial_id=${id}`} /> : <Couriers branch={branch} />}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{show && (
				<div className='card'>
					<div className='card-body'>
						<div className='h4'>{translate('FORM.INPUT.RECEIVE_COORDINATES')}</div>
						<LocationMap setData={() => {}} data={branch?.receive_coordinates} key={'receive_coordinates'} />
					</div>
				</div>
			)}
			{show && (
				<div className='card'>
					<div className='card-body'>
						<div className='h4'>{translate('FORM.INPUT.DELIVERY_COORDINATES')}</div>
						<LocationMap setData={() => {}} data={branch?.delivery_coordinates} key={'delivery_coordinates'} />
					</div>
				</div>
			)}
		</div>
	)
}

export default Detail
