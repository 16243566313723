import { createSlice } from '@reduxjs/toolkit'
import ICategory from '../models/CategoryModel'

interface CategoryState {
	categories: ICategory[]
	total: number
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
}

const initialState: CategoryState = {
	categories: [],
	total: 0,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
}

export const CategorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setCategories: (state, action) => {
			state.categories = action.payload.data
			state.total = action.payload.total
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setCategories, setRefresh } = CategorySlice.actions

export default CategorySlice.reducer
