import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { FileItem } from '../../../components/fileItem'
import WSPGalleryDocs from '../../company-detail/components/Photo/WSPGallery'
import { IDocument } from '../../organization/models/OrganizationModel'
import { fileIconName } from '../../../../_theme/helpers'
import useTranslate from '../../../../_theme/helpers/translate'

const CustomerComment = () => {
	const { refund } = useAppSelector((state) => state.refunds)
	const translate = useTranslate()
	const [photos, setPhotos] = useState<IDocument[]>([])
	const [docs, setDocs] = useState<IDocument[]>([])
	useEffect(() => {
		setDocs(refund?.photos?.filter((item) => fileIconName(item.url) == 'pdf' || fileIconName(item.url) == 'docx'))
		setPhotos(refund?.photos?.filter((item) => fileIconName(item.url) != 'pdf' && fileIconName(item.url) != 'docx'))
	}, [refund])
	return (
		<div className='card card-flush py-4 flex-row-fluid'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>
						<h2>{translate('CARD.TEXT.COMMENT')}</h2>
					</h2>
				</div>
			</div>
			<div className='card-body pt-0'>
				{refund?.reason}
				<br />
				{refund?.type?.type}
			</div>
			<div className='row px-4'>{photos?.length > 0 && <WSPGalleryDocs galleryImages={photos} />}</div>
			<div className='overflow-auto pb-5'>
				<div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-200px p-7  scroll-x'>
					{docs?.map((item) => (
						<FileItem key={item.key} fileLink={item.url} name={item.url} size={''} />
					))}
				</div>
			</div>
		</div>
	)
}

export default CustomerComment
