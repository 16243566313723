/* eslint-disable jsx-a11y/anchor-is-valid */
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'
import { Languages } from './Languages'
import useTranslate from '../../../helpers/translate'
import { Modal } from 'react-bootstrap'
import { ModalHeader } from '../../../../app/Modals/ModalHeader'
import { ResetPassword } from '../../../../app/Modals/ResetPassword'
import { useState } from 'react'

const HeaderUserMenu = () => {
	const translate = useTranslate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const handleCloseModal = () => setShowModal(false)
	const handleOpenModal = () => setShowModal(true)
	const logout = () => {
		dispatch(auth.actions.logout())
	}

	return (
		<div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px' data-kt-menu='true'>
			<div className='menu-item px-3'>
				<div className='menu-content d-flex align-items-center px-3'>
					<div className='symbol symbol-50px me-5'>
						<img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
					</div>
					<div className='d-flex flex-column'>
						<div className='fw-bolder d-flex align-items-center fs-5'>
							{user?.surname} {user?.lastname}
							{/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
						</div>
						<a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
							{user.email}
						</a>
					</div>
				</div>
			</div>
			<div className='separator my-2'></div>

			<div className='menu-item px-5'>
				<div onClick={handleOpenModal} className='menu-link px-5'>
					{translate('CARD.TEXT.REST_PASSWORD')}
				</div>
			</div>
			<div className='menu-item px-5'>
				<a onClick={logout} className='menu-link px-5'>
					{translate('CARD.TEXT.SIGN_OUT')}
				</a>
			</div>
			<Languages />
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ResetPassword onClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export { HeaderUserMenu }
