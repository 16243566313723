import React, { ChangeEventHandler, FC, useState } from 'react'
import { OrderCollectingStatus } from '../../_theme/helpers/enums/OrderStatus'
import { successMsg, errorMsg } from '../../_theme/helpers/AlertMsg'
import { Api } from '../Services/api'
import { useDispatch } from 'react-redux'
import { setRefresh } from '../reducers/RefreshSlice'
import useTranslate from '../../_theme/helpers/translate'

type Props = {
	orderId: string | number
	handleClose: any
	status: OrderCollectingStatus
}

const OrderCollectingStatusModal: FC<Props> = ({ orderId, handleClose, status }) => {
	const dispatch = useDispatch()
	const translate = useTranslate()
	const [selectedStatus, setSelectedStatus] = useState<OrderCollectingStatus>(status)
	const statusOptions = Object.values(OrderCollectingStatus).map((value) => ({
		label: value,
		value,
	}))
	const handleSubmit = () => {
		const body = {
			collecting_status: selectedStatus,
		}
		Api()
			.orders.changeCollectingStatus(orderId, body)
			.then(() => {
				successMsg('Success')
				dispatch(setRefresh())
				handleClose()
			})
			.catch((err) => {
				errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
			})
	}
	return (
		<div className='card card-flush py-4'>
			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className=''>
					<div className='mb-4 pt-0'>
						<label className='form-label'>{translate('CARD.TEXT.SELECT_ORDER_COLLECTION_STATUS')}</label>
						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' onChange={(e: any) => setSelectedStatus(e.target.value)} defaultValue={selectedStatus} data-placeholder='Select Product' name='status'>
							<option hidden>{translate('CARD.TEXT.SELECT')}</option>
							{statusOptions?.map((item) => {
								return <option value={item.value}>{translate(`STATUS.TEXT.${item.label.toUpperCase()}`)}</option>
							})}
						</select>
					</div>
				</div>
				<div className='mt-5 w-100 text-center'>
					<div onClick={handleClose} className='btn  me-1'>
						{translate('FORM.BUTTON.CENCEL')}
					</div>
					<div onClick={handleSubmit} className='btn btn-primary'>
						{translate('FORM.BUTTON.SEND')}
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrderCollectingStatusModal
