import { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import './wsp-gallery.css'

type Props = {
	galleryImages: any
}
const WSPGalleryDocs: FC<Props> = ({ galleryImages }) => {
	const [slideNumber, setSlideNumber] = useState(0)
	const [openModal1, setOpenModal] = useState(false)
	const handleOpenModal = (index: any) => {
		setSlideNumber(index)
		setOpenModal(true)
	}

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	const prevSlide = () => {
		slideNumber === 0 ? setSlideNumber(galleryImages.length - 1) : setSlideNumber(slideNumber - 1)
	}

	const nextSlide = () => {
		slideNumber + 1 === galleryImages.length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1)
	}

	return (
		<>
			<div>
				{openModal1 && (
					<div className='sliderWrap'>
						<FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal} />
						<FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
						<FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
						<div className='fullScreenImage'>
							<img src={galleryImages[slideNumber].url} alt='' />
						</div>
					</div>
				)}
				<div className='d-flex flex-wrap mb-6 mb-xl-9'>
					{galleryImages &&
						galleryImages?.map((slide: any, index: any) => {
							return (
								<div className='me-0 me-sm-5 overlay overflow-hidden'>
									<div className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded mb-5' key={index}>
										<img
											src={slide.url}
											alt=''
											style={{
												width: '205px',
												height: '144px',
												cursor: 'pointer',
												borderRadius: '10px',
												objectFit: 'contain',
											}}
										/>
										<div className='overlay-layer bg-opacity-10'>
											<div onClick={() => handleOpenModal(index)} className='btn btn-primary btn-shadow'>
												<i className='fa fa-eye text-light fs-3'></i>
											</div>
										</div>
									</div>
								</div>
							)
						})}
				</div>
			</div>
		</>
	)
}

export default WSPGalleryDocs
