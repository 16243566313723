import axios from 'axios'
import { CategoriesApi } from './categories'
import { BrandsApi } from './brands'
import { OrganizationApi } from './organization'
import { ProductsApi } from './product'
import { DeliveriesApi } from './deliveries'
import { AddressApi } from './address'
import { OrdersApi } from './orders'
import { CommentApi } from './comment'
import { RefundApi } from './refund'
import { ProductType } from './product-type'
import { ProductSpec } from './product-spec'
import { BalanceApi } from './balance'
import { StatisticsApi } from './statistics'
import store from '../../setup/redux/Store'
import { UserApi } from './user'
import { DiscountsApi } from './discount'
import { DocsApi } from './docs'

export const Api = () => {
	const {
		auth: { accessToken },
	} = store.getState()
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	})

	return {
		categories: CategoriesApi(instance),
		brands: BrandsApi(instance),
		organization: OrganizationApi(instance),
		products: ProductsApi(instance),
		deliveries: DeliveriesApi(instance),
		address: AddressApi(instance),
		orders: OrdersApi(instance),
		comment: CommentApi(instance),
		refund: RefundApi(instance),
		productType: ProductType(instance),
		productSpec: ProductSpec(instance),
		balance: BalanceApi(instance),
		statistics: StatisticsApi(instance),
		user: UserApi(instance),
		discount: DiscountsApi(instance),
		docs: DocsApi(instance),
	}
}
