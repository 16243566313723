import { createSlice } from '@reduxjs/toolkit'
import IDeliveries from '../../deliveries/models/DeliveriesModel'

interface DeliveriesState {
	delivery: IDeliveries
	total: number
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
}

const initialState: DeliveriesState = {
	delivery: {} as IDeliveries,
	total: 0,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
}

export const DeliverySlice = createSlice({
	name: 'delivery',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setDelivery: (state, action) => {
			state.delivery = action.payload
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setDelivery, setRefresh } = DeliverySlice.actions

export default DeliverySlice.reducer
