import React from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_theme/layout/core'
import Detail from '../courier-detail/Detail'

const companyBreadCrumbs: Array<PageLink> = [
	{
		title: 'Organizations',
		path: '/organizations',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
]

const CourierPage: React.FC<{ children?: React.ReactNode }> = () => {
	const { id } = useParams()

	return (
		<Routes>
			<Route
				element={
					<>
						<PageTitle breadcrumbs={companyBreadCrumbs}>Courier Detail</PageTitle>
						<Detail />
						{/* <Outlet /> */}
					</>
				}
			>
				<Route
					path='detail'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>Courier Detail</PageTitle>
							<Detail />
						</>
					}
				/>
				{/* <Route
          path='reservations'
          element={
            <>
              <PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.CUSTOMERS')}</PageTitle>
              <Reservations />
            </>
          }
        />
        <Route
          path='rooms'
          element={
            <>
              <PageTitle breadcrumbs={companyBreadCrumbs}>Products</PageTitle>
              <Rooms />
            </>
          }
        />
        <Route
          path='customers'
          element={
            <>
              <PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.COMMENTS')}</PageTitle>
              <Customers />
            </>
          }
        /> */}
				<Route index element={<Navigate to={`./detail`} />} />
			</Route>
		</Routes>
	)
}

export default CourierPage
