import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { Api } from '../../../../../Services/api'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../../setup/hooks/redux'
import { setAddProduct } from '../../../reducers/ProductSlice'
import useTranslate from '../../../../../../_theme/helpers/translate'

function Media() {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)

	const [files, setFiles] = useState<any>([])
	const [showFile, setShowFile] = useState<any>([]) //banner show
	const [errors, setErrors] = useState('')
	const removeFile = (f: any) => () => {
		const newFiles = [...files]
		newFiles.splice(newFiles.indexOf(f), 1)
		setFiles(newFiles)
	}
	const filess = files.map(
		(
			file: {
				path: string | null | undefined
				size: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
			},
			index: React.Key | null | undefined
		) => (
			<li key={index} className='d-flex justify-content-between m-5'>
				<span className='w-50 ml-10'>
					{file.path} - {file.size} bytes{' '}
				</span>{' '}
				<span className='btn btn-sm  mr-10' onClick={removeFile(file)}>
					Sil
				</span>
			</li>
		)
	)

	const removeAll = () => {
		setFiles([])
	}
	const uploadImg = (e: any) => {
		let formData = new FormData()
		for (let i = 0; i < e.length; i++) {
			formData.append('files', e[i])
		}
		Api()
			.products.imagesUpload(formData)
			.then((res) => {
				dispatch(setAddProduct({ ...addProduct, child_pictures: res.map(({ id }: any) => id) }))
			})
			.catch((err) => {
				console.log(err)
			})
	}
	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.MEDIA')}</h2>
				</div>
			</div>

			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className='fv-row mb-1'>
					<label className='fs-7 fw-bold mb-2'>{translate('FORM.INPUT.SELECT_IMAGE')}</label>

					<div style={{ display: 'block' }} className='fv-row mb-8'>
						<div className='dropzone' id='kt_modal_create_ticket_attachments'>
							<div className='dz-message needsclick align-items-center'>
								<span className='svg-icon svg-icon-3hx svg-icon-primary' style={{ color: 'rgb(1 13 88)' }}>
									<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
										<path opacity='0.3' d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z' fill='currentColor' />
										<path d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z' fill='currentColor' />
										<path d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z' fill='currentColor' />
										<path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
									</svg>
								</span>
								{/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}

								<Dropzone
									accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
									multiple={true}
									// maxSize={2097152}
									maxFiles={10}
									onDrop={async (acceptedFile, fileRejections) => {
										acceptedFile.length > 0 && uploadImg(acceptedFile)
										// setShowFile(acceptedFile[0])
										setFiles(acceptedFile)
										fileRejections.forEach((file) => {
											file.errors.forEach((err) => {
												if (err.code === 'file-too-large') {
													setErrors(`Hata: ${err.message}`)
												}

												if (err.code === 'file-invalid-type') {
													setErrors(`Hata: ${err.message}`)
												} else {
													setErrors(`Hata: ${err.message}`)
												}
											})
										})
									}}
								>
									{({ getRootProps, getInputProps }) => (
										<section>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<div className='ms-4'>
													<h3 className='fs-5 fw-bolder text-gray-900 mb-1'>{translate('FORM.INPUT.SELECT_IMAGE')}</h3>
													<span className='fw-bold fs-7 text-gray-400'>10 item select</span>
												</div>
												<p style={{ color: 'red', padding: 5, margin: 0, fontSize: 14 }}>{errors}</p>
											</div>
										</section>
									)}
								</Dropzone>
							</div>
						</div>
					</div>
				</div>
				<aside>
					<ul className='m-0 p-0'>{filess}</ul>
				</aside>
				<div className='d-flex justify-content-end'>
					{' '}
					{files.length > 0 && (
						<span onClick={removeAll} className='btn btn-sm me-2 '>
							Remove All
						</span>
					)}
				</div>

				<div className='text-muted fs-7'>{translate('CARD.TEXT.SET_THE_PRODUCT_MEDIA_GALLERY')}</div>
			</div>
		</div>
	)
}

export default Media
