import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Api } from '../../../../Services/api'
import { UserModel } from '../../../../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { UserRoles } from '../../../../../_theme/helpers'
import ProductItem from './components/ProductItem'
import { useAppSelector } from '../../../../../setup/hooks/redux'
import useTranslate from '../../../../../_theme/helpers/translate'
import { SearchComponent } from '../../../../components/SearchBox'
import Pagination from '../../../../components/Pagination'

const Products = () => {
	let navigate = useNavigate()
	const translate = useTranslate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const { refresh } = useAppSelector((state) => state.refresh)
	const { id } = useParams()

	const [products, setProducts] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [total, setToltal] = useState(1)
	const [searchKeyClick, setSearchKeyClick] = useState('')
	const [statusSelect, setStatusSelect] = useState('')
	useEffect(() => {
		// if (user.role === UserRoles.SUPER_ADMIN) {
		// 	Api()
		// 		.products.getOrgonizationAll(100, 1, `&organization_filial_id=${id}`)
		// 		.then((res) => {
		// 			setProducts(res?.items)
		// 		})
		// 		.catch((err) => {
		// 			console.log(err)
		// 		})
		// }
		const search = searchKeyClick ? `&product_title=${searchKeyClick}` : ''
		if (user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER) {
			Api()
				.products.getOrgonizationAll(limit, currentPage, `&organization_filial_id=${id}${search}${statusSelect}`)
				.then((res) => {
					setProducts(res?.items)
				})
				.catch((err) => {
					console.log(err)
				})
		}
		if (user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) {
			Api()
				.products.getOrgonizationFilialAll(100, 1, `&organization_filial_id=${id}${search}${statusSelect}`)
				.then((res) => {
					setProducts(res?.items)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [refresh, currentPage, searchKeyClick, statusSelect])

	return (
		<div>
			<div className='card card-flush'>
				<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
					<div className='card-title'>
						<div className='d-flex align-items-center position-relative my-1'>
							<span className='svg-icon svg-icon-1 position-absolute ms-4'>
								<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
									<rect opacity='0.5' x='17.0365' y='15.1223' width='8.15546' height='2' rx='1' transform='rotate(45 17.0365 15.1223)' fill='currentColor' />
									<path d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z' fill='currentColor' />
								</svg>
							</span>

							<SearchComponent setSearchKeyClick={setSearchKeyClick} placeholder={'Search'} />
						</div>
					</div>

					<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
						<div className='w-100 mw-150px'>
							<select className='form-select form-select-solid' onChange={(e) => setStatusSelect(e.target.value)} data-control='select2' data-hide-search='true' data-placeholder='Status' data-kt-ecommerce-product-filter='status'>
								<option disabled>{translate('CARD.TEXT.STATUS')}</option>
								<option value=''>{translate('STATUS.TEXT.ALL')}</option>
								<option value='&status=published'>{translate('STATUS.TEXT.PUBLISHED')}</option>
								<option value='&status=unpublished'>{translate('STATUS.TEXT.UNPUBLISHED')}</option>
							</select>
						</div>
					</div>
				</div>
				<div className='card-body pt-0 min-h-300px  scroll-x'>
					<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
						<thead>
							<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
								<th className='min-w-200px'>{translate('TABLE.TITLE.PRODUCT')}</th>
								<th className='text-end min-w-100px'>{translate('TABLE.TITLE.SKU')}</th>
								<th className='text-end min-w-70px'>{translate('TABLE.TITLE.QTY')}</th>
								<th className='text-end min-w-100px'>{translate('TABLE.TITLE.PRICE')}</th>
								<th className='text-end min-w-100px'>{translate('TABLE.TITLE.PRICE_WITH_TAX')}</th>
								<th className='text-end min-w-100px'>{translate('TABLE.TITLE.STATUS')}</th>
								<th className='text-end min-w-70px'>{translate('TABLE.TITLE.ACTION')}</th>
							</tr>
						</thead>
						<tbody className='fw-bold text-gray-600'>
							{products.map((item, index) => {
								return <ProductItem key={index} item={item} />
							})}
						</tbody>
					</table>
					<Pagination onPageChange={(page: number) => setCurrentPage(page)} totalCount={total} currentPage={currentPage} pageSize={limit} />
				</div>
			</div>
		</div>
	)
}

export default Products
