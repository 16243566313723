import React, { FC, useState } from 'react'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Dropdown, Modal } from 'react-bootstrap'
import { IRequestModel } from '../model/RequestModel'

import Swal from 'sweetalert2'
import CheckRefundModal from '../modals/CheckRefunds'
import { ModalHeader } from '../../../Modals/ModalHeader'
import { OrderDeliveryStatus } from '../../../../_theme/helpers/enums/OrderStatus'
import useTranslate from '../../../../_theme/helpers/translate'
import AssignCourierModal from '../modals/AssignCourier'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { UserRoles } from '../../../../_theme/helpers'
import { RootState } from '../../../../setup'
import { IDocument } from '../../organization/models/OrganizationModel'

type Props = {
	request: IRequestModel
	openModal?: any
}

const RequestItem: FC<Props> = ({ request, openModal }) => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

	const [showModal1, setShowModal1] = useState(false)
	const handleOpenModal1 = () => setShowModal1(true)
	const handleCloseModal1 = () => setShowModal1(false)

	function swalDelete(msg: string, sts: number) {
		Swal.fire({
			title: msg,
			icon: 'info',
			showDenyButton: false,
			showCancelButton: true,
			confirmButtonText: 'Evet',
			cancelButtonText: 'Vazgeç',
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				const body = {
					request_status: sts,
				}
				// Api()
				//   .CancellationRequest.cancellationStatus(request.id, body)
				//   .then((res) => {
				//     if (res?.success) setStatus(!status)
				//   })
			} else if (result.isDenied) {
			}
		})
	}
	const nav = () => {
		navigate(`/refund/${request.id}`)
	}

	const downloadBills = (filials: IDocument[]) => {
		filials?.map((item) => {
			window.open(item?.url, '_blank')
		})
	}

	return (
		<>
			<tr>
				<td onClick={nav}>
					<div className='d-flex align-items-center cursor-pointer'>
						<div className='symbol symbol-50px'></div>

						<div className='ms-5'>
							<div className='text-gray-800  fs-5 fw-bolder' data-kt-ecommerce-product-filter='product_name'>
								{request?.order_product?.product?.title}
							</div>
						</div>
					</div>
				</td>

				<td onClick={nav} className='pe-0 cursor-pointer'>
					<div className=''> {request?.organization?.name}</div>
				</td>
				<td onClick={nav} className='pe-0 cursor-pointer'>
					<div className=''> {request?.organization_filial?.name} </div>
				</td>
				<td onClick={nav} className='pe-0 cursor-pointer'>
					<div className=''> {request?.amount} </div>
				</td>
				<td onClick={nav} className='pe-0 cursor-pointer'>
					{request?.type?.type}
				</td>
				{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && (
					<td onClick={nav} className='text-end pe-0'>
						{request.worker_transaction_confirm ? <div className='badge badge-light-success '>{translate('STATUS.TEXT.PAID')}</div> : <div className='badge badge-light-danger '>{translate('STATUS.TEXT.NO_PAID')}</div>}
					</td>
				)}
				{(user.role === UserRoles.SUPER_ADMIN || user?.role == UserRoles.DELIVERY_COMPANY_MANAGER || user?.role == UserRoles.DELIVERY_FILIAL_MANAGER) && (
					<td onClick={nav} className='text-end pe-0'>
						{request.delivery_transaction_confirm ? <div className='badge badge-light-success '>{translate('STATUS.TEXT.PAID')}</div> : <div className='badge badge-light-danger '>{translate('STATUS.TEXT.NO_PAID')}</div>}
					</td>
				)}
				{(user.role === UserRoles.SUPER_ADMIN || user?.role == UserRoles.DELIVERY_COMPANY_MANAGER || user?.role == UserRoles.DELIVERY_FILIAL_MANAGER) && (
					<td onClick={nav} className='text-end pe-0'>
						{request.user_transaction_confirm ? <div className='badge badge-light-success '>{translate('STATUS.TEXT.PAID')}</div> : <div className='badge badge-light-danger '>{translate('STATUS.TEXT.NO_PAID')}</div>}
					</td>
				)}

				<td onClick={nav} className='pe-0 cursor-pointer'>
					<div className=''>
						{' '}
						{request?.order_product?.specifications?.map((item, index) => (
							<div key={index}>{item?.value},</div>
						))}{' '}
					</div>
				</td>
				<td onClick={nav} className='text-end pe-0 cursor-pointer'>
					{request.delivery_status === OrderDeliveryStatus.DELIVERED ? (
						<div className='badge badge-light-success '>{translate(`STATUS.TEXT.${request.delivery_status.toUpperCase()}`)}</div>
					) : request.delivery_status === OrderDeliveryStatus.ON_WAY ? (
						<div className='badge badge-light-danger '>{translate(`STATUS.TEXT.${request.delivery_status.toUpperCase()}`)}</div>
					) : request.delivery_status === OrderDeliveryStatus.IN_FILIAL ? (
						<div className='badge badge-light-warning '>{translate(`STATUS.TEXT.${request.delivery_status.toUpperCase()}`)}</div>
					) : (
						<div className='badge badge-light-dark '>{translate(`STATUS.TEXT.${request.delivery_status.toUpperCase()}`)}</div>
					)}
				</td>
				<td onClick={nav} className='pe-0 cursor-pointer'>
					{request?.responsible}
				</td>
				<td className='text-end'>
					<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						<Dropdown.Toggle variant='primary' id='dropdown-basic'>
							{translate('TABLE.TITLE.ACTION')}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item>
								{' '}
								<div onClick={handleOpenModal} className='menu-item px-3'>
									<div className='menu-link px-3'>{translate('TABLE.TITLE.ACTION.SELECT_RESPONSIBLE')}</div>
								</div>
							</Dropdown.Item>
							<Dropdown.Item>
								{' '}
								<div onClick={handleOpenModal1} className='menu-item px-3'>
									<div className='menu-link px-3'>{translate('TABLE.TITLE.ACTION.ASSIGN_CUORIER')}</div>
								</div>
							</Dropdown.Item>
							{user?.role == UserRoles.SUPER_ADMIN && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div
											className='menu-link px-3'
											onClick={() => {
												openModal(request.id, 'org')
											}}
										>
											{translate('TABLE.TITLE.ACTION.ADD_INVOICE_FOR_ORGANIZATION')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{user?.role == UserRoles.SUPER_ADMIN && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div
											className='menu-link px-3'
											onClick={() => {
												openModal(request.id, 'delivery')
											}}
										>
											{translate('TABLE.TITLE.ACTION.ADD_INVOICE_FOR_DELIVERY')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{user?.role == UserRoles.SUPER_ADMIN && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div
											className='menu-link px-3'
											onClick={() => {
												openModal(request.id, 'user')
											}}
										>
											{translate('TABLE.TITLE.ACTION.ADD_INVOICE_FOR_USER')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{(user?.role == UserRoles.SUPER_ADMIN || user?.role == UserRoles.ORGANIZATION_OWNER || user?.role == UserRoles.ORGANIZATION_FILIAL_MANAGER) && request?.worker_transaction_confirm && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div onClick={() => downloadBills(request?.worker_transaction_files!)} className='menu-link px-3'>
											{translate('TABLE.TITLE.ACTION.DOWNLOAD_INVOICE')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{(user?.role == UserRoles.SUPER_ADMIN || user?.role == UserRoles.DELIVERY_COMPANY_MANAGER || user?.role == UserRoles.DELIVERY_FILIAL_MANAGER) && request?.delivery_transaction_confirm && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div onClick={() => downloadBills(request?.delivery_transaction_files!)} className='menu-link px-3'>
											{translate('TABLE.TITLE.ACTION.DOWNLOAD_INVOICE')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{user?.role == UserRoles.SUPER_ADMIN && request?.user_transaction_confirm && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div onClick={() => downloadBills(request?.user_transaction_files!)} className='menu-link px-3'>
											{translate('TABLE.TITLE.ACTION.DOWNLOAD_INVOICE')}
										</div>
									</div>
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>

					{/* <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
            data-kt-menu='true'
          >  
          </div> */}
				</td>
			</tr>
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={translate('TABLE.TITLE.ACTION.SELECT_RESPONSIBLE')} />
					<CheckRefundModal orderId={request?.id} handleClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
			<Modal show={showModal1} onHide={handleCloseModal1}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal1} titleHeader={translate('TABLE.TITLE.ACTION.ASSIGN_CUORIER')} />
					<AssignCourierModal orderId={request.id} handleClose={handleCloseModal} />
				</Modal.Body>
			</Modal>
		</>
	)
}

export default RequestItem
