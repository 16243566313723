/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_theme/helpers'
import { Link } from 'react-router-dom'
import { Dropdown1 } from '../../../_theme/partials'
import { useLocation } from 'react-router'
import { useAppSelector } from '../../../setup/hooks/redux'
import useTranslate from '../../../_theme/helpers/translate'

const DeldeliveryHeader: React.FC<{ children?: React.ReactNode }> = () => {
	const translate = useTranslate()
	const location = useLocation()
	const { delivery } = useAppSelector((state) => state.delivery)

	return (
		<div className='card mb-5 mb-xl-10'>
			<div className='card-body pt-9 pb-0'>
				<div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
					<div className='me-7 mb-4'>
						<div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
							{/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Good' /> */}
							<div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-1 h-100px w-100px rounded-circle'>
								{delivery?.name?.substring(0, 1)} {delivery?.name?.substring(1, 2).toUpperCase()}
							</div>
							<div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
						</div>
					</div>

					<div className='flex-grow-1'>
						<div className='d-flex justify-content-between align-items-start flex-wrap mb-1'>
							<div className='d-flex flex-column'>
								<div className='d-flex align-items-center mb-2'>
									<div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>{delivery?.name}</div>
								</div>

								<div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'></div>
							</div>

							<div className='d-flex my-4'>
								<div className='me-0'>
									<button className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end'>
										<i className='bi bi-three-dots fs-3'></i>
									</button>
									<Dropdown1 />
								</div>
							</div>
						</div>

						<div className='d-flex flex-wrap flex-stack'>
							<div className='d-flex flex-column flex-grow-1 pe-8'>
								<div className='d-flex flex-wrap'>
									<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
										<div className='d-flex align-items-center'>
											<div className='fs-2 fw-bolder'>{delivery?.balance?.current}</div>
										</div>

										<div className='fw-bold fs-6 text-gray-400'>{translate('CARD.TEXT.BALANCE')}</div>
									</div>

									<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
										<div className='d-flex align-items-center'>
											<div className='fs-2 fw-bolder'> {delivery?.balance?.total}</div>
										</div>

										<div className='fw-bold fs-6 text-gray-400'>{translate('CARD.TEXT.TOTAL_EARNING')}</div>
									</div>

									<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
										<div className='d-flex align-items-center'>
											<div className='fs-2 fw-bolder'>{delivery?.balance?.withdrew}</div>
										</div>

										<div className='fw-bold fs-6 text-gray-400'>{translate('STATUS.TEXT.PAID')}</div>
									</div>
								</div>
							</div>

							{/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
								<div className='d-flex justify-content-between w-100 mt-auto mb-2'>
									<span className='fw-bold fs-6 text-gray-400'>{translate('FORM.BUTTON.SEND')}</span>
									<span className='fw-bolder fs-6'>50%</span>
								</div>
								<div className='h-5px mx-3 w-100 bg-light mb-3'>
									<div className='bg-success rounded h-5px' role='progressbar' style={{ width: '50%' }}></div>
								</div>
							</div> */}
						</div>
					</div>
				</div>

				<div className='d-flex overflow-auto h-55px'>
					<ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'detail' && 'active')} to={`detail`}>
								{translate('CARD.TEXT.ABOUT')}
							</Link>
						</li>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'branches' && 'active')} to={`branches`}>
								{translate('CARD.TEXT.BRANCHES')}
							</Link>
						</li>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'active' && 'active')} to={`active`}>
								{translate('CARD.TEXT.ORDERS')}
							</Link>
						</li>
						<li className='nav-item'>
							<Link className={`nav-link text-active-primary me-6 ` + (location.pathname?.split('/')[3] === 'returns' && 'active')} to={`returns`}>
								{translate('CARD.TEXT.REFUNDS')}
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export { DeldeliveryHeader }
