export const ProductsApi = (instance: any) => ({
	async create(body: any) {
		const { data } = await instance.post(`/product/create`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/product/delete/${id}`)
		return data
	},
	async getAll(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/product/find/all?limit=${limit}&page=${page}&sortType=DESC&select=discounts,categories,types,specifications,brand,users,child_pictures,main_picture,chars,relations,metas,product_specifications,organization${q}`)
		return data
	},
	async getOrgonizationAll(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/organization-filial-product/organization?limit=${limit}&page=${page}&sortType=DESC&select=product,main_picture,organization_filial,specifications,specification,organization${q}`)
		return data
	},
	async getOrgonizationFilialAll(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/organization-filial-product/organization-filial/all?limit=${limit}&page=${page}&sortType=DESC&select=product,main_picture,organization_filial,specifications,specification,organization${q}`)
		return data
	},
	async getProduct(id: string) {
		const { data } = await instance.get(`/product/find/one/${id}`)
		return data
	},
	async changeProductStatus(body: any) {
		const { data } = await instance.patch(`/product/change-status`, body)
		return data
	},
	async imageUpload(body: any) {
		const { data } = await instance.post(`/product/file/upload`, body)
		return data
	},
	async imagesUpload(body: any) {
		const { data } = await instance.post(`/product/files/upload`, body)
		return data
	},
	async updateProductType(id: any, body: any) {
		const { data } = await instance.patch(`/product-type/update/${id}`, body)
		return data
	},
	async updateProduct(id: any, body: any) {
		const { data } = await instance.patch(`/product/update/${id}`, body)
		return data
	},
})
