import { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import './wsp-gallery.css'
import { openModal } from '../reducers/PhotoSlice'
import { AddPhoto } from './AddPhoto'
import { useDispatch } from 'react-redux'
import { Api } from '../../../../../Services/api'
import Swal from 'sweetalert2'
import { IDocument } from '../../../../organization/models/OrganizationModel'
import { setRefresh } from '../../../../../reducers/RefreshSlice'
type Props = {
	productId: number
	galleryImages: IDocument[]
}
const WSPGallery: FC<Props> = ({ productId, galleryImages }) => {
	const [slideNumber, setSlideNumber] = useState(0)
	const [openModal1, setOpenModal] = useState(false)
	const dispatch = useDispatch()
	const handleOpenModal = (index: any) => {
		setSlideNumber(index)
		setOpenModal(true)
	}

	// Close Modal
	const handleCloseModal = () => {
		setOpenModal(false)
	}

	// Previous Image
	const prevSlide = () => {
		slideNumber === 0 ? setSlideNumber(galleryImages.length - 1) : setSlideNumber(slideNumber - 1)
	}

	// Next Image
	const nextSlide = () => {
		slideNumber + 1 === galleryImages.length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1)
	}

	function remove(photoId: string | number) {
		Swal.fire({
			title: 'The picture will be deleted!',
			text: 'Do you want to delete the image?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33 ',
			cancelButtonColor: '#3085d6',
			cancelButtonText: 'Cencel',
			confirmButtonText: 'Delete',
		}).then(async (result) => {
			if (result.isConfirmed) {
				const body = { child_pictures_ids: galleryImages?.filter(({ id }) => id != photoId)?.map(({ id }) => id) }
				await Api()
					.products.updateProduct(productId, body)
					.then((res) => {
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: res?.message,
							showConfirmButton: false,
							timer: 1500,
						})
						dispatch(setRefresh())
					})
					.catch((err) => {
						Swal.fire({
							icon: 'error',
							title: err?.response?.data?.statusCode,
							text: err?.response?.data?.message,
						})
						console.log(err)
					})
			}
		})
	}

	return (
		<>
			<div>
				{openModal1 && (
					<div className='sliderWrap'>
						<FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal} />
						<FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
						<FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
						<div className='fullScreenImage'>
							<img src={galleryImages[slideNumber].url} alt='' />
						</div>
					</div>
				)}

				{/* <br />
    Current slide number:  {slideNumber}
    <br />
    Total Slides: {galleryImages.length}
    <br /><br /> */}

				<div className='d-flex flex-wrap mb-6 mb-xl-9'>
					{galleryImages &&
						galleryImages?.map((slide: any, index: any) => {
							return (
								<div className='me-0 me-sm-5 overlay overflow-hidden'>
									<div className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded mb-5' key={index}>
										<img
											src={slide.url}
											alt=''
											style={{
												width: '205px',
												height: '144px',
												cursor: 'pointer',
												borderRadius: '10px',
												objectFit: 'contain',
											}}
										/>
										<div className='overlay-layer bg-opacity-10'>
											<div onClick={() => handleOpenModal(index)} className='btn btn-primary btn-shadow'>
												<i className='fa fa-eye text-light fs-3'></i>
											</div>
											<div onClick={() => remove(slide?.id)} className='btn btn-light-primary btn-shadow ms-2'>
												<i className='fa fa-trash text-light fs-3'></i>
											</div>
										</div>
									</div>
								</div>
							)
						})}
					<div className='' onClick={() => dispatch(openModal())}>
						<div className='dropzone d-flex align-items-center addPhoto' id='kt_modal_create_ticket_attachments'>
							<div className='dz-message needsclick align-items-center text-center'>
								<div className='ms-4'>
									<h3 className='fs-5 fw-bolder text-gray-900 mb-1'>Add Photo</h3>
									<span className='fw-bold fs-7 text-gray-400'>Drag and drop the photo into this area</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddPhoto />
		</>
	)
}

export default WSPGallery
