import React, { useEffect, useState } from 'react'

import { Api } from '../../../../Services/api'
import { useAppSelector } from '../../../../../setup/hooks/redux'
import useTranslate from '../../../../../_theme/helpers/translate'
import DiscountItem from './components/DiscountItem'
import AddDiscount from './modals/AddDiscount'
import { Discount } from '../../../../models/ProductModel'

const Discounts = () => {
	const translate = useTranslate()
	const { product } = useAppSelector((state) => state.product)
	const [Discounts, setDiscounts] = useState<Discount[]>([])
	const [record, setRecord] = useState<Discount | null>(null)

	const [showModal, setShowModal] = useState(false)
	const handleOpenModal = () => setShowModal(true)
	const handleCloseModal = () => setShowModal(false)

	const currentTableData = () => {
		Api()
			.discount.getAll(product?.id?.toString())
			.then((res) => {
				console.log(res)
				setDiscounts(res)
			})
	}

	useEffect(() => {
		currentTableData()
	}, [product])
	return (
		<div className='card card-flush'>
			{showModal && <AddDiscount update={false} isOpen={showModal} discount={record} onClose={handleCloseModal} productId={product?.id} />}
			<div className='card-header align-items-center py-5 gap-2 gap-md-5'>
				<div className='card-title'></div>

				<div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
					<div
						onClick={() => {
							setRecord(null)
							handleOpenModal()
						}}
						className='btn btn-primary'
					>
						Add Discount
					</div>
				</div>
			</div>
			<div className='card-body pt-5 min-h-300px  scroll-x'>
				<table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
					<thead>
						<tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
							<th className='min-w-200px'>{translate('TABLE.TITLE.START_END_TIME')}</th>
							<th className=''>{translate('TABLE.TITLE.DISCOUNT')}</th>
							<th className='min-w-70px text-end'>{translate('TABLE.TITLE.STATUS')}</th>
							<th className='min-w-70px text-end'>{translate('TABLE.TITLE.ACTION')}</th>
						</tr>
					</thead>
					<tbody className='fw-bold text-gray-600'>
						{Discounts?.map((discount) => {
							return <DiscountItem discount={discount} key={discount?.id} setRecord={setRecord} handleOpenModal={handleOpenModal} />
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Discounts
