import { createSlice } from '@reduxjs/toolkit'
import IOrganization from '../../organization/models/OrganizationModel'

interface CompanyState {
	company: IOrganization
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
}

const initialState: CompanyState = {
	company: {} as IOrganization,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
}

export const CompanySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setCompany: (state, action) => {
			state.company = action.payload
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setCompany, setRefresh } = CompanySlice.actions

export default CompanySlice.reducer
