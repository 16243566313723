import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { KTSVG, UserRoles } from '../../../helpers'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { MENU } from './menu'
import { useMemo } from 'react'
import { checkRoles } from '../../../helpers/checkRole'
import useTranslate from '../../../helpers/translate'

export function AsideMenuMain() {
	const translate = useTranslate()
	const menuData = useMemo((): any => {
		const itemCreator = MENU.map((item) => {
			if (checkRoles(item.hasRole)) {
				return <AsideMenuItem key={item.key} to={item.to} title={translate(item.title)} fontIcon={item.fontIcon} icon={item.icon} />
			}
		})
		return itemCreator
	}, [])
	return (
		<>
			{menuData}
			<div className='menu-item'>
				<div className='menu-content'>
					<div className='separator separator-dashed mx-n10'></div>
				</div>
			</div>
		</>
	)
}
