import React, { FC, useMemo, useRef, useState } from 'react'
import { MapContainer, TileLayer, FeatureGroup, Marker, Popup, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import osm from './osm'
import useGeoLocation from '../../../helpers/useGeoLocation'
import { toAbsoluteUrl } from '../../../helpers'
import { ICoordinate } from '../../../../app/models/AddressModel'
import useTranslate from '../../../helpers/translate'

interface initCoordinate {
	lat: number | string | undefined
	lng: number | string | undefined
}

const center = {
	lat: 42.8694222423884,
	lng: 74.61060863085159,
}

type Props = {
	initData?: initCoordinate
	setData: any
}

function Test({ location, search, setData, setPosition, mapRef }: any) {
	const markerRef = useRef(null)
	const map = useMap()
	const markerIcon = L.icon({
		iconUrl: toAbsoluteUrl('/media/icons/duotune/maps/marker-icon.png'),
		iconSize: [30, 40],
		iconAnchor: [12, 39],
		shadowAnchor: [13, 41],
		popupAnchor: [1, -37],
	})
	const eventHandlers = useMemo(
		() => ({
			dragend() {
				const marker: any = markerRef.current
				if (marker != null) {
					const { lat, lng } = marker?.getLatLng()
					setPosition({ lat: lat, lng: lng })
					setData({
						latitude: lat,
						longitude: lng,
					})
				}
			},
		}),
		[]
	)
	if (location) map?.flyTo(location, 12)

	return location ? (
		<Marker draggable position={location} ref={markerRef} icon={markerIcon} eventHandlers={eventHandlers}>
			<Popup>You are address</Popup>
		</Marker>
	) : null
}

const DraggableMarker: FC<Props> = ({ initData, setData }) => {
	const ZOOM_LEVEL = 6
	const mapRef = useRef<any>()

	const translate = useTranslate()

	const location = useGeoLocation()

	const [position, setPosition] = useState<any>(initData?.lat ? initData : center)

	const showMyLocation = (e: any) => {
		e.preventDefault()
		if (location.loaded && !location.error) {
			setData({
				latitude: location.coordinates.lat,
				longitude: location.coordinates.lng,
			})
			setPosition({
				lat: location.coordinates.lat,
				lng: location.coordinates.lng,
			})
			mapRef?.current?.leafletElement?.flyTo([location.coordinates.lat, location.coordinates.lng], ZOOM_LEVEL, { animate: true })
		} else {
			alert(location.error.message)
		}
	}

	return (
		<div>
			<MapContainer center={[41.9847447, 75.0594996]} zoom={6} style={{ width: '100%', height: '400px' }} ref={mapRef}>
				<TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution} />
				<Test location={position} search={''} setData={setData} setPosition={setPosition} mapRef={mapRef} />
			</MapContainer>

			<div className='row my-4'>
				<div className='col d-flex justify-content-center'>
					<button className='btn btn-primary' onClick={showMyLocation}>
						{translate('FORM.TEXT.LOCATE_ME')}
					</button>
				</div>
			</div>
		</div>
	)
}

export default DraggableMarker
