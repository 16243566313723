import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import CategorySlice from '../../app/pages/categories/reducers/CategorySlice'
import BrandSlice from '../../app/pages/brands/reducers/BrandSlice'
import OrganizationSlice from './../../app/pages/organization/reducers/OrganizationSlice'
import CompanySlice from '../../app/pages/company-detail/reducers/CompanySlice'
import ProductSlice from '../../app/pages/products/reducers/ProductSlice'
import DeliveriesSlice from '../../app/pages/deliveries/reducers/DeliveriesSlice'
import RefreshSlice from '../../app/reducers/RefreshSlice'
import PhotoSlice from '../../app/pages/product-detail/components/photos/reducers/PhotoSlice'
import BranchesSlice from '../../app/pages/branches-detail/reducers/BranchesSlice'
import OrdersSlice from '../../app/pages/orders/reducers/OrdersSlice'
import DeliverySlice from '../../app/pages/delivery-detail/reducers/DeliverySlice'
import RefundsSlice from '../../app/pages/cancellationRequest/reducers/RefundSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	category: CategorySlice,
	brand: BrandSlice,
	organization: OrganizationSlice,
	company: CompanySlice,
	product: ProductSlice,
	deliveries: DeliveriesSlice,
	delivery: DeliverySlice,
	refresh: RefreshSlice,
	prdPhotos: PhotoSlice,
	branches: BranchesSlice,
	orders: OrdersSlice,
	refunds: RefundsSlice,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
	yield all([auth.saga()])
}
