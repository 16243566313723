import React, { useEffect, useState } from 'react'
import { UserRoles, toAbsoluteUrl } from '../../../../../_theme/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Api } from '../../../../Services/api'
import Swal from 'sweetalert2'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setRefresh } from '../../../../reducers/RefreshSlice'
import { ICoordinate } from '../../../../models/AddressModel'
import DraggableMarker from '../../../../../_theme/partials/content/DrawPolygon/MapDrag'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../../setup/hooks/redux'
import useTranslate from '../../../../../_theme/helpers/translate'
import { UserModel } from '../../../../modules/auth/models/UserModel'
import { RootState } from '../../../../../setup'

interface EditDelivery {
	name: string
	delivery_price: string | number
	delivery_refund_price: string | number
	length: string | number
	width: string | number
	height: string | number
	weight: string | number
	country_id: string | number
	city_id: string | number
	district_id: string | number
	region_id: string | number
	address_title: string
	village_id: string | number
	street: string | null
	home_no: string | null
}

const DeliveriesSchema = Yup.object().shape({
	name: Yup.string().required(),
	delivery_price: Yup.number().required().min(1),
	delivery_refund_price: Yup.number().required().min(0),
	length: Yup.number().required().min(1),
	width: Yup.number().required().min(1),
	height: Yup.number().required().min(1),
	weight: Yup.number().required().min(1),
	country_id: Yup.number().required(),
	city_id: Yup.number(),
	village_id: Yup.number(),
	street: Yup.string(),
	home_no: Yup.string(),
	district_id: Yup.number().required(),
	region_id: Yup.number().required(),
	address_title: Yup.string().required(),
})

const Settings: React.FC<{ children?: React.ReactNode }> = () => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const nav = useNavigate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const { delivery } = useAppSelector((state) => state.delivery)

	const [currentLocation, setCurrentLocation] = useState<ICoordinate>(delivery?.address?.coordinate)
	const [countries, setCountries] = useState<any>([])
	const [district, setDistrict] = useState<any>([])
	const [regions, setRegions] = useState<any>([])
	const [cities, setCities] = useState<any>([])
	const [village, setVillage] = useState<any>([])

	const [deliveryForEdit] = useState<EditDelivery>({
		name: delivery?.name,
		delivery_price: delivery?.delivery_price,
		delivery_refund_price: delivery?.delivery_refund_price,
		length: delivery?.shipping_restriction?.length,
		width: delivery?.shipping_restriction?.width,
		height: delivery?.shipping_restriction?.height,
		weight: delivery?.shipping_restriction?.weight,
		country_id: delivery?.country?.id,
		city_id: '',
		village_id: '',
		street: delivery?.address?.street,
		home_no: delivery?.address?.home_no,
		district_id: '',
		region_id: '',
		address_title: delivery?.address?.title,
	})

	useEffect(() => {
		Api()
			.address.countries()
			.then((res) => {
				setCountries(res?.items)
			})
		Api()
			.address.city()
			.then((res) => {
				setCities(res?.items)
			})
		Api()
			.address.province()
			.then((res) => {
				setRegions(res?.items)
			})
		Api()
			.address.regions()
			.then((res) => {
				setDistrict(res?.items)
			})
		Api()
			.address.villages()
			.then((res) => {
				setVillage(res?.items)
			})
	}, [])

	const formik = useFormik({
		initialValues: deliveryForEdit,
		validationSchema: DeliveriesSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			const body = {
				name: values.name,
				delivery_price: Number(values.delivery_price),
				delivery_refund_price: Number(values.delivery_refund_price),
				delivery_company_id: delivery.id,
				shipping_restriction: {
					length: Number(values.length),
					width: Number(values.width),
					height: Number(values.height),
					weight: Number(values.weight),
				},
				address: {
					country_id: Number(values.country_id),
					region_id: Number(values.region_id),
					district_id: Number(values.district_id),
					coordinate: {
						longitude: currentLocation?.longitude,
						latitude: currentLocation?.latitude,
					},
					title: values.address_title,
					city_id: values.city_id ? Number(values.city_id) : null,
					village_id: values.village_id ? Number(values.village_id) : null,
					street: values.street,
					home_no: values.home_no,
				},
			}

			try {
				Api()
					.deliveries.updateDelivery(body)
					.then((res) => {
						Swal.fire({
							position: 'center',
							icon: 'success',
							title: 'Successfuly Added!',
							showConfirmButton: false,
							timer: 1500,
						})
						dispatch(setRefresh())
						nav(-1)
					})
					.catch((err) => {
						Swal.fire({
							icon: 'error',
							title: err?.response?.data?.statusCode,
							text: err?.response?.data?.message,
						})
					})
			} catch (ex) {
				console.log(ex)
				Swal.fire({
					icon: 'error',
					title: '',
					text: '',
				})
			} finally {
				setSubmitting(false)
			}
		},
	})

	return (
		<div className='card mb-5 mb-xl-10'>
			<div className='card-header border-0 cursor-pointer' role='button' data-bs-toggle='collapse' data-bs-target='#kt_account_profile_details' aria-expanded='true' aria-controls='kt_account_profile_details'>
				<div className='card-title m-0'>
					<h3 className='fw-bolder m-0'>Delivery Update</h3>
				</div>
			</div>

			<div>
				<div className='card-body'>
					<form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
						<div className='d-flex flex-column scroll-y me-n7 pe-7 '>
							<div className='row'>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DELIVERY_NAME')}</label>

									<input
										placeholder='Deliveries Name'
										{...formik.getFieldProps('name')}
										type='text'
										name='name'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.name && formik.errors.name },
											{
												'is-valid': formik.touched.name && !formik.errors.name,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.name && formik.errors.name && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.name}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DELIVERY_PRICE')}</label>

									<input
										placeholder='Delivery Price'
										{...formik.getFieldProps('delivery_price')}
										type='number'
										name='delivery_price'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.delivery_price && formik.errors.delivery_price },
											{
												'is-valid': formik.touched.delivery_price && !formik.errors.delivery_price,
											}
										)}
										disabled={user.role !== UserRoles.SUPER_ADMIN}
										autoComplete='off'
									/>
									{formik.touched.delivery_price && formik.errors.delivery_price && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.delivery_price}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DELIVERY_REFUND_PRICE')}</label>

									<input
										placeholder='Delivery Price'
										{...formik.getFieldProps('delivery_refund_price')}
										type='number'
										name='delivery_refund_price'
										disabled={user.role !== UserRoles.SUPER_ADMIN}
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.delivery_refund_price && formik.errors.delivery_refund_price },
											{
												'is-valid': formik.touched.delivery_refund_price && !formik.errors.delivery_refund_price,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.delivery_refund_price && formik.errors.delivery_refund_price && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.delivery_refund_price}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className='row'>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.LENGTH')}</label>

									<input
										placeholder='Length'
										{...formik.getFieldProps('length')}
										type='number'
										name='length'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.length && formik.errors.length },
											{
												'is-valid': formik.touched.length && !formik.errors.length,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.length && formik.errors.length && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.length}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.WIDTH')}</label>

									<input
										placeholder='Width'
										{...formik.getFieldProps('width')}
										type='number'
										name='width'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.width && formik.errors.width },
											{
												'is-valid': formik.touched.width && !formik.errors.width,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.width && formik.errors.width && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.width}</span>
											</div>
										</div>
									)}
								</div>

								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.HEIGHT')}</label>

									<input
										placeholder='Height'
										{...formik.getFieldProps('height')}
										type='number'
										name='height'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.height && formik.errors.height },
											{
												'is-valid': formik.touched.height && !formik.errors.height,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.height && formik.errors.height && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.height}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.WEIGHT')}</label>

									<input
										placeholder='Weight'
										{...formik.getFieldProps('weight')}
										type='number'
										name='weight'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.weight && formik.errors.weight },
											{
												'is-valid': formik.touched.weight && !formik.errors.weight,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.weight && formik.errors.weight && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.weight}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className='row'>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.COUNTRY')}</label>

									<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select Country' {...formik.getFieldProps('country_id')} name='country_id'>
										<option hidden>Select</option>
										{countries?.map((item: any, index: any) => {
											return (
												<option key={index} value={item?.id}>
													{item?.title}
												</option>
											)
										})}
									</select>
									{formik.touched.country_id && formik.errors.country_id && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.country_id}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.REGION')}</label>

									<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select City' {...formik.getFieldProps('region_id')} name='region_id'>
										<option hidden>Select</option>
										{regions
											?.filter((item: any) => item?.country?.id == formik.values.country_id)
											?.map((item: any, index: any) => {
												return (
													<option key={index} value={item?.id}>
														{item?.title}
													</option>
												)
											})}
									</select>
									{formik.touched.region_id && formik.errors.region_id && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.region_id}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.CITY')}</label>

									<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select City' {...formik.getFieldProps('city_id')} name='city_id'>
										<option hidden>Select</option>
										<option value={''}>Null</option>
										{cities
											// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
											?.map((item: any, index: any) => {
												return (
													<option key={index} value={item?.id}>
														{item?.title}
													</option>
												)
											})}
									</select>
									{formik.touched.city_id && formik.errors.city_id && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.city_id}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DISTRICT')}</label>

									<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select district_id' {...formik.getFieldProps('district_id')} name='district_id'>
										<option hidden>Select</option>
										{district
											// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
											?.map((item: any, index: any) => {
												return (
													<option key={index} value={item?.id}>
														{item?.title}
													</option>
												)
											})}
									</select>
									{formik.touched.district_id && formik.errors.district_id && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.district_id}</span>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className='row'>
								<div className='col mb-7'>
									<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.VILLAGE')}</label>

									<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select village_id' {...formik.getFieldProps('village_id')} name='village_id'>
										<option hidden>Select</option>
										<option value={''}>Null</option>
										{village
											// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
											?.map((item: any, index: any) => {
												return (
													<option key={index} value={item?.id}>
														{item?.title}
													</option>
												)
											})}
									</select>
									{formik.touched.district_id && formik.errors.district_id && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.district_id}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.STREET')}</label>

									<input
										placeholder='Street'
										{...formik.getFieldProps('street')}
										type='text'
										name='street'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.street && formik.errors.street },
											{
												'is-valid': formik.touched.street && !formik.errors.street,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.street && formik.errors.street && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.street}</span>
											</div>
										</div>
									)}
								</div>
								<div className='col mb-7'>
									<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.HOME_NUMBER')}</label>

									<input
										placeholder='Home Number'
										{...formik.getFieldProps('home_no')}
										type='text'
										name='home_no'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.home_no && formik.errors.home_no },
											{
												'is-valid': formik.touched.home_no && !formik.errors.home_no,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.home_no && formik.errors.home_no && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.home_no}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className='row'>
								<div className='col mb-7'>
									<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.ADDRESS')}</label>

									<input
										placeholder='address_title'
										{...formik.getFieldProps('address_title')}
										type='text'
										name='address_title'
										className={clsx(
											'form-control form-control-solid mb-3 mb-lg-0',
											{ 'is-invalid': formik.touched.address_title && formik.errors.address_title },
											{
												'is-valid': formik.touched.address_title && !formik.errors.address_title,
											}
										)}
										autoComplete='off'
									/>
									{formik.touched.address_title && formik.errors.address_title && (
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<span role='alert'>{formik.errors.address_title}</span>
											</div>
										</div>
									)}
								</div>
							</div>
							<div>
								<label className='form-label'>{translate('FORM.INPUT.CURRENT_LOCATION')}</label>
								{delivery.address && <DraggableMarker initData={{ lng: delivery?.address?.coordinate?.longitude, lat: delivery?.address?.coordinate?.latitude }} setData={setCurrentLocation} />}
							</div>

							<div className='text-center pt-15'>
								<div onClick={() => nav(-1)} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
									{translate('FORM.BUTTON.CENCEL')}
								</div>

								<button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>
									<span className='indicator-label'>{translate('FORM.BUTTON.SEND')}</span>
									{formik.isSubmitting && (
										<span className='indicator-progress'>
											Loading... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									)}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export { Settings }
