import { FC, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { IOrderModel } from '../models/OrderModel'
import moment from 'moment'
import { OrderCollectingStatus, OrderDeliveryStatus, OrderProceedStatus } from '../../_theme/helpers/enums/OrderStatus'
import { setOrder } from '../pages/orders/reducers/OrdersSlice'
import { Dropdown, Modal } from 'react-bootstrap'
import { ModalHeader } from '../Modals/ModalHeader'
import OrderCollectingStatusModal from '../Modals/OrderCollectingStatus'
import { RootState } from '../../setup'
import { UserModel } from '../modules/auth/models/UserModel'
import { UserRoles } from '../../_theme/helpers'
import AssignCourierModal from '../Modals/AssignCourier'
import useTranslate from '../../_theme/helpers/translate'
import { IDocument } from '../pages/organization/models/OrganizationModel'

type Props = {
	item: IOrderModel
	openModal?: any
}

const OrderItem: FC<Props> = ({ item, openModal }) => {
	const translate = useTranslate()
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
	const navigate = useNavigate()

	// States
	const [showModal, setShowModal] = useState(false)
	const handleCloseModal = () => setShowModal(false)
	const handleOpenModal = () => setShowModal(true)

	const [showModalAsign, setShowModalAsign] = useState(false)
	const handleCloseAsingModal = () => setShowModalAsign(false)
	const handleOpenAsingModal = () => setShowModalAsign(true)

	const downloadBills = (filials: IDocument[]) => {
		filials?.map((item) => {
			window.open(item?.url, '_blank')
		})
	}

	const nav = () => {
		navigate(`/orders/${item.id}`)
	}
	return (
		<>
			<tr className='cursor-pointer'>
				<td onClick={nav} className='cursor-pointer'>
					<div className=''>#{item?.id}</div>
				</td>

				<td onClick={nav} className='text-end pe-0'>
					<div className=''>
						{item?.main_order?.user?.surname} {item?.main_order?.user?.name}
					</div>
				</td>
				{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.DELIVERY_COMPANY_MANAGER || user.role === UserRoles.DELIVERY_FILIAL_MANAGER) && (
					<td onClick={nav} className='text-end pe-0'>
						<div className=''>{item?.organization_filial?.name}</div>
					</td>
				)}
				{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && (
					<td onClick={nav} className='text-end pe-0'>
						<div className=''>{item?.delivery_companies?.map((item) => item.name)?.toString()}</div>
					</td>
				)}
				<td onClick={nav} className='text-end pe-0'>
					{item?.price_for_organization}
				</td>
				<td onClick={nav} className='text-end pe-0'>
					{item?.price_for_ta}
				</td>
				<td onClick={nav} className='text-end pe-0'>
					{item?.delivery_price_without_tax}
				</td>
				<td onClick={nav} className='text-end pe-0'>
					{item?.delivery_price - item?.delivery_price_without_tax}
				</td>
				<td onClick={nav} className='text-end pe-0'>
					{item?.price}
				</td>
				<td onClick={nav} className='text-end pe-0'>
					<div className=''> {moment(item?.created).format('DD.MM.YYYY')}</div>
				</td>
				{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && (
					<td onClick={nav} className='text-end pe-0'>
						{item.worker_transaction_confirm ? <div className='badge badge-light-success '>{translate('STATUS.TEXT.PAID')}</div> : <div className='badge badge-light-danger '>{translate('STATUS.TEXT.NO_PAID')}</div>}
					</td>
				)}
				{(user.role === UserRoles.SUPER_ADMIN || user?.role == UserRoles.DELIVERY_COMPANY_MANAGER || user?.role == UserRoles.DELIVERY_FILIAL_MANAGER) && (
					<td onClick={nav} className='text-end pe-0'>
						{item.delivery_transaction_confirm ? <div className='badge badge-light-success '>{translate('STATUS.TEXT.PAID')}</div> : <div className='badge badge-light-danger '>{translate('STATUS.TEXT.NO_PAID')}</div>}
					</td>
				)}
				<td onClick={nav} className='text-end pe-0'>
					{item.paid_status === OrderProceedStatus.PAID ? (
						<div className='badge badge-light-success '>{translate(`STATUS.TEXT.${item.paid_status.toUpperCase()}`)}</div>
					) : item.paid_status === OrderProceedStatus.CANCELLED ? (
						<div className='badge badge-light-danger '>{translate(`STATUS.TEXT.${item.paid_status.toUpperCase()}`)}</div>
					) : item.paid_status === OrderProceedStatus.ACTIVE ? (
						<div className='badge badge-light-warning '>{translate(`STATUS.TEXT.${item.paid_status.toUpperCase()}`)}</div>
					) : (
						<div className='badge badge-light-dark '>{translate(`STATUS.TEXT.${item.paid_status.toUpperCase()}`)}</div>
					)}
				</td>
				<td onClick={nav} className='text-end pe-0'>
					{item.delivery_status === OrderDeliveryStatus.DELIVERED ? (
						<div className='badge badge-light-success '>{translate(`STATUS.TEXT.${item.delivery_status.toUpperCase()}`)}</div>
					) : item.delivery_status === OrderDeliveryStatus.ON_WAY ? (
						<div className='badge badge-light-danger '>{translate(`STATUS.TEXT.${item.delivery_status.toUpperCase()}`)}</div>
					) : item.delivery_status === OrderDeliveryStatus.IN_FILIAL ? (
						<div className='badge badge-light-warning '>{translate(`STATUS.TEXT.${item.delivery_status.toUpperCase()}`)}</div>
					) : (
						<div className='badge badge-light-dark '>{translate(`STATUS.TEXT.${item.delivery_status.toUpperCase()}`)}</div>
					)}
				</td>
				<td className='text-end pe-0'>
					<Dropdown data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
						<Dropdown.Toggle variant='primary' id='dropdown-basic'>
							{translate('TABLE.TITLE.ACTION')}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.ORGANIZATION_OWNER || user.role === UserRoles.ORGANIZATION_FILIAL_MANAGER) && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div onClick={handleOpenModal} className='menu-link px-3'>
											{translate('TABLE.TITLE.ACTION.CHANGE_COLLECTING_STATUS')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{(user.role === UserRoles.SUPER_ADMIN || user.role === UserRoles.DELIVERY_COMPANY_MANAGER || user.role === UserRoles.DELIVERY_FILIAL_MANAGER) && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div onClick={handleOpenAsingModal} className='menu-link px-3'>
											{translate('TABLE.TITLE.ACTION.ASSIGN_CUORIER')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{user?.role == UserRoles.SUPER_ADMIN && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div
											className='menu-link px-3'
											onClick={() => {
												openModal(item.id, 'org')
											}}
										>
											{translate('TABLE.TITLE.ACTION.ADD_INVOICE_FOR_ORGANIZATION')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{user?.role == UserRoles.SUPER_ADMIN && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div
											className='menu-link px-3'
											onClick={() => {
												openModal(item.id, 'delivery')
											}}
										>
											{translate('TABLE.TITLE.ACTION.ADD_INVOICE_FOR_DELIVERY')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{(user?.role == UserRoles.SUPER_ADMIN || user?.role == UserRoles.ORGANIZATION_OWNER || user?.role == UserRoles.ORGANIZATION_FILIAL_MANAGER) && item?.worker_transaction_confirm && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div onClick={() => downloadBills(item?.worker_transaction_files)} className='menu-link px-3'>
											{translate('TABLE.TITLE.ACTION.DOWNLOAD_INVOICE')}
										</div>
									</div>
								</Dropdown.Item>
							)}
							{(user?.role == UserRoles.SUPER_ADMIN || user?.role == UserRoles.DELIVERY_COMPANY_MANAGER || user?.role == UserRoles.DELIVERY_FILIAL_MANAGER) && item?.delivery_transaction_confirm && (
								<Dropdown.Item>
									<div className='menu-item'>
										<div onClick={() => downloadBills(item?.delivery_transaction_files)} className='menu-link px-3'>
											{translate('TABLE.TITLE.ACTION.DOWNLOAD_INVOICE')}
										</div>
									</div>
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseModal} titleHeader={translate('TABLE.TITLE.ACTION.CHANGE_COLLECTING_STATUS')} />
					<OrderCollectingStatusModal orderId={item.id} handleClose={handleCloseModal} status={item.collecting_status as OrderCollectingStatus} />
				</Modal.Body>
			</Modal>
			<Modal show={showModalAsign} onHide={handleCloseAsingModal}>
				<Modal.Body>
					<ModalHeader handleClose={handleCloseAsingModal} titleHeader={translate('TABLE.TITLE.ACTION.ASSIGN_CUORIER')} />
					<AssignCourierModal orderId={item.id} handleClose={handleCloseAsingModal} />
				</Modal.Body>
			</Modal>
		</>
	)
}

export default OrderItem
