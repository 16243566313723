import React from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_theme/layout/core'
import UpdatedCompany from '../../../product-detail/components/Details'
import { ProductHeader } from '../../../product-detail/ProductHeader'
import Detail from '../../../delivery-branch-detail/Detail'

const companyBreadCrumbs: Array<PageLink> = [
	{
		title: 'Organizations',
		path: '/organizations',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
]

const DeliveryBranchPage: React.FC<{ children?: React.ReactNode }> = () => {
	const { id } = useParams()

	return (
		<Routes>
			<Route
				element={
					<>
						<PageTitle breadcrumbs={companyBreadCrumbs}>Branch Detail</PageTitle>
						<Detail />
						{/* <Outlet /> */}
					</>
				}
			>
				<Route
					path='detail'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>Branch Detail</PageTitle>
							<Detail />
						</>
					}
				/>
				<Route index element={<Navigate to={`./detail`} />} />
			</Route>
		</Routes>
	)
}

export default DeliveryBranchPage
