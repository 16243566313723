import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'
import { ModalHeader } from '../../../Modals/ModalHeader'
import clsx from 'clsx'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { useDispatch } from 'react-redux'
import Dropzone from 'react-dropzone'
import IRefundType from '../models/RefundTypeModel'
import { Api } from '../../../Services/api'
import { setRefresh } from '../../../reducers/RefreshSlice'
import useTranslate from '../../../../_theme/helpers/translate'

type Props = {
	refundType?: IRefundType
	update: boolean
	isOpen: boolean
	onClose: any
}

const RefundTypeSchema = Yup.object().shape({
	type: Yup.string().required(),
	status: Yup.string(),
})

const AddRefundType: FC<Props> = ({ refundType, update, isOpen, onClose }) => {
	const dispatch = useDispatch()
	const translate = useTranslate()
	const [file, setFile] = useState<any>({})
	const [showFile, setShowFile] = useState<any>([]) //banner show
	const [errors, setErrors] = useState('')
	const [userForEdit] = useState({
		type: refundType?.type,
		// status: refundType?.status,
	})

	const formik = useFormik({
		initialValues: userForEdit,
		validationSchema: RefundTypeSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			try {
				Api()
					.refund.createRefundType({ type: values.type })
					.then((res) => {
						dispatch(setRefresh())
						onClose()
					})
					.catch((err) => {
						console.log(err)
					})
			} catch (ex) {
				console.error(ex)
			} finally {
			}
		},
	})

	return (
		<Modal show={isOpen} onHide={() => onClose()}>
			<Modal.Body>
				<ModalHeader handleClose={onClose} titleHeader={'New RefundType'} />
				<form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
					{/* begin::Scroll */}
					<div className='d-flex flex-column scroll-y me-n7 pe-7 ' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
						{/* begin::Input group */}
						<div className='fv-row mb-7'>
							{/* begin::Label */}
							<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.REFUND_TYPES')}</label>
							{/* end::Label */}

							{/* begin::Input */}
							<input
								placeholder='RefundType Name'
								{...formik.getFieldProps('type')}
								type='text'
								name='type'
								className={clsx(
									'form-control form-control-solid mb-3 mb-lg-0',
									{ 'is-invalid': formik.touched.type && formik.errors.type },
									{
										'is-valid': formik.touched.type && !formik.errors.type,
									}
								)}
								autoComplete='off'
							/>
							{formik.touched.type && formik.errors.type && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block'>
										<span role='alert'>{formik.errors.type}</span>
									</div>
								</div>
							)}
							{/* end::Input */}
						</div>

						{/* <div className='fv-row mb-7'>
							<label className='fw-bold fs-6 mb-2'>{translate('FORM.INPUT.VISIBILITY')}</label>
							<select
								className='form-select form-select-solid'
								data-control='select2'
								data-hide-search='true'
								data-placeholder='Visibility'
								{...formik.getFieldProps('status')}
								name='status'
							>
								<option hidden>Select</option>

								<option value={'published'}>Active</option>
								<option value={'unpublished'}>Passife</option>
							</select>
							{formik.touched.status && formik.errors.status && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block'>
										<span role='alert'>{formik.errors.status}</span>
									</div>
								</div>
							)}
						</div> */}
						<div className='text-center pt-15'>
							<div onClick={onClose} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
								{translate('FORM.BUTTON.CENCEL')}
							</div>

							<button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>
								<span className='indicator-label'>{translate('FORM.BUTTON.SEND')}</span>
								{formik.isSubmitting && (
									<span className='indicator-progress'>
										Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								)}
							</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	)
}

export default AddRefundType
