import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import IDeliveries from '../../deliveries/models/DeliveriesModel'

type Props = {
	item: IDeliveries
}

const DeliveryItem: FC<Props> = ({ item }) => {
	const navigate = useNavigate()
	const nav = () => {
		navigate(`/delivery/${item.id}`, { replace: true })
	}

	return (
		<>
			<tr onClick={nav} className='cursor-pointer'>
				<td>
					<div className='d-flex align-items-center'>
						<div className='ms-5'>
							<div className='text-gray-800 text-hover-primary fs-5 fw-bolder' data-kt-ecommerce-product-filter='product_name'>
								{item?.name}
							</div>
						</div>
					</div>
				</td>
				<td className='text-end pe-0 text-purple'>{item.balance.total}</td>
				<td className='text-end pe-0 text-primary'>{item?.balance?.current}</td>
				<td className='text-end pe-0 text-orange'>{item?.balance?.tax_for_ta}</td>
				<td className='text-end pe-0 text-success'>{item.balance.withdrew}</td>
				<td className='text-end pe-0 text-pink'>{item.balance.current}</td>
			</tr>
		</>
	)
}

export default DeliveryItem
