import { createSlice } from '@reduxjs/toolkit'
import IOrganization from '../models/OrganizationModel'

interface OrganizationState {
	organization: IOrganization[]
	total: number
	isLoading: boolean
	error: string
	modalActive: boolean
	refresh: boolean
}

const initialState: OrganizationState = {
	organization: [],
	total: 0,
	isLoading: false,
	error: '',
	modalActive: false,
	refresh: false,
}

export const OrganizationSlice = createSlice({
	name: 'organization',
	initialState,
	reducers: {
		openModal: (state) => {
			state.modalActive = true
		},
		closeModal: (state) => {
			state.modalActive = false
		},
		setOrganizations: (state, action) => {
			state.organization = action.payload.data
			state.total = action.payload.total
		},
		setRefresh: (state) => {
			state.refresh = !state.refresh
		},
	},
})

export const { openModal, closeModal, setOrganizations, setRefresh } = OrganizationSlice.actions

export default OrganizationSlice.reducer
