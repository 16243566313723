import React, { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_theme/layout/core'
import Customers from './components/costomers/Customers'
import { ProductHeader } from './ProductHeader'
import AboutProduct from './components/Details'
import { Photos } from './components/photos/Photos'
import Reviews from './components/reviews/Reviews'
import { Api } from '../../Services/api'
import { useDispatch } from 'react-redux'
import { setProduct } from '../products/reducers/ProductSlice'
import { useAppSelector } from '../../../setup/hooks/redux'
import { UpdateProduct } from './components/settings/UpdateProduct'
import Orders from '../orders/Orders'
import useTranslate from '../../../_theme/helpers/translate'
import Discounts from './components/discount/Discount'

const companyBreadCrumbs: Array<PageLink> = [
	{
		title: 'Product',
		path: '/organizations',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
]

const ProductPage: React.FC<{ children?: React.ReactNode }> = () => {
	const { id } = useParams()
	const dispatch = useDispatch()
	const translate = useTranslate()
	const { refresh } = useAppSelector((state) => state.refresh)
	useEffect(() => {
		Api()
			.products.getProduct(id ? id.toString() : '')
			.then((res) => {
				dispatch(setProduct(res))
			})
	}, [refresh])

	return (
		<Routes>
			<Route
				element={
					<>
						<ProductHeader />
						<Outlet />
					</>
				}
			>
				<Route
					path='detail'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.ABOUT')}</PageTitle>
							<AboutProduct />
						</>
					}
				/>
				<Route
					path='review'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.COMMENTS')}</PageTitle>
							<Reviews />
						</>
					}
				/>
				<Route
					path='discount'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.COMMENTS')}</PageTitle>
							<Discounts />
						</>
					}
				/>
				<Route
					path='photos'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.PHOTO')}</PageTitle>
							<Photos />
						</>
					}
				/>
				<Route
					path='customers'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.CUSTOMERS')}</PageTitle>
							<Customers />
						</>
					}
				/>
				<Route
					path='orders'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.PRODUCTS')}</PageTitle>
							<Orders req={`&product_id=${id}`} />
						</>
					}
				/>
				<Route
					path='update'
					element={
						<>
							<PageTitle breadcrumbs={companyBreadCrumbs}>{translate('CARD.TEXT.UPDATE')}</PageTitle>
							<UpdateProduct />
						</>
					}
				/>
				<Route index element={<Navigate to={`./detail`} />} />
			</Route>
		</Routes>
	)
}

export default ProductPage
