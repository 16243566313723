import { IDividePrd } from './../models/DivideProductModal'
import { IOrgBranch } from '../models/AddOrgBranchModal'
import IAddOrganization from '../pages/organization/models/AddOrganizationModel'

export const OrganizationApi = (instance: any) => ({
	async create(body: IAddOrganization) {
		const { data } = await instance.post(`/organization/create`, body)
		return data
	},
	async createBranch(body: IOrgBranch) {
		const { data } = await instance.post(`/organization/filial/create`, body)
		return data
	},
	async updatedOrg(body: any) {
		const { data } = await instance.patch(`/organization/update`, body)
		return data
	},
	async updatedFilial(id: number | string, body: any) {
		const { data } = await instance.patch(`/organization-filial/update/${id}`, body)
		return data
	},
	async filialStatusChange(id: number | string, body: any) {
		const { data } = await instance.patch(`/organization-filial/change-status/${id}`, body)
		return data
	},
	async delete(id: number) {
		const { data } = await instance.delete(`/organization/delete`)
		return data
	},
	async accept(id: number) {
		const { data } = await instance.patch(`/organization/approve/${id}`)
		return data
	},
	async reject(id: number) {
		const { data } = await instance.patch(`/organization/reject/${id}`)
		return data
	},
	async getAll(limit: number = 100, page: number = 1, q: string) {
		const { data } = await instance.get(`/organization/find/all?limit=${limit}&page=${page}&sortType=DESC&select=documents,owner,logo${q}`)
		return data
	},
	async getAllForStatistics(limit: number = 100, page: number = 1, q: string) {
		const { data } = await instance.get(`/organization/find/all?limit=${limit}&page=${page}&sortType=DESC&select=logo,balance${q}`)
		return data
	},
	async getOne(id: string | undefined) {
		const { data } = await instance.get(`/organization/find/one/${id}`)
		return data
	},
	async getAllFilials(limit: number = 50, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/organization-filial/all?limit=${limit}&page=${page}&sortType=DESC&select=address,manager,country,organization${q}`)
		return data
	},
	async getOneFilial(id: string | undefined) {
		const { data } = await instance.get(`/organization-filial/find/one/${id}`)
		return data
	},
	async divideProduct(body: IDividePrd) {
		const { data } = await instance.patch(`/product/filials/divide`, body)
		return data
	},
	async imageUpload(body: any) {
		const { data } = await instance.post(`/organization/files/upload`, body)
		return data
	},
})
