import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { successMsg, errorMsg } from '../../../../_theme/helpers/AlertMsg'
import { Api } from '../../../Services/api'
import { setRefresh } from '../../../reducers/RefreshSlice'
import clsx from 'clsx'
import useTranslate from '../../../../_theme/helpers/translate'

type Props = {
	orderId: string | number
	handleClose: any
}

const CheckRefundModal: FC<Props> = ({ orderId, handleClose }) => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const [selectedCourierId, setSelectedCourierId] = useState<string>('')
	const [responsible, setResponsible] = useState([{ title: 'delivery_company' }, { title: 'organization' }])
	const [fine, setFine] = useState(0)

	const handleSubmit = () => {
		const body = {
			responsible: selectedCourierId,
			fine: fine,
		}
		Api()
			.refund.selectResponsible(orderId, body)
			.then(() => {
				successMsg('Success')
				dispatch(setRefresh())
				handleClose()
			})
			.catch((err) => {
				errorMsg(err?.response?.data?.statusCode, err?.response?.data?.message)
			})
	}
	return (
		<div className='card card-flush py-4'>
			<div className='card-body pt-0 min-h-300px  scroll-x'>
				<div className=''>
					<div className='mb-4 pt-0'>
						<label className='form-label'>Select Responible</label>
						<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' onChange={(e: any) => setSelectedCourierId(e.target.value)} defaultValue={selectedCourierId} data-placeholder='Select Product' name='status'>
							<option hidden>Select</option>
							{responsible?.map((item: any) => {
								return <option value={item?.title}>{item?.title}</option>
							})}
						</select>
					</div>
				</div>
				<div className='fv-row mb-7'>
					{/* begin::Label */}
					<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.FINE')}</label>
					<input
						placeholder='Fine'
						onChange={(e) => setFine(Number(e?.target?.value))}
						type='number'
						name='name'
						min={0}
						className={clsx(
							'form-control form-control-solid mb-3 mb-lg-0',
							{ 'is-invalid': fine < 0 },
							{
								'is-valid': fine > 0,
							}
						)}
						autoComplete='off'
					/>
					{fine < 0 && (
						<div className='fv-plugins-message-container'>
							<div className='fv-help-block'>
								<span role='alert'>{'In Correct'}</span>
							</div>
						</div>
					)}
					{/* end::Input */}
				</div>
				<div className='mt-5 w-100 text-center'>
					<div onClick={handleClose} className='btn  me-1'>
						Cancel
					</div>
					<div onClick={handleSubmit} className='btn btn-primary'>
						Save
					</div>
				</div>
			</div>
		</div>
	)
}

export default CheckRefundModal
