/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthCRUD'
import { toAbsoluteUrl } from '../../../../_theme/helpers'
import useTranslate from '../../../../_theme/helpers/translate'
import { Languages } from '../../../../_theme/partials/layout/header-menus/Languages'
import { LanguagesDrop } from '../../../../_theme/partials/layout/header-menus/LanguagesDrop'

const initialValues = {
	email: '',
	password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
	const translate = useTranslate()

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	const loginSchema = Yup.object().shape({
		email: Yup.string()
			.email(translate('FORM.VALIDATION.INVALID', { name: 'Email' }))
			.min(3, translate('FORM.VALIDATION.INVALID', { name: 'Email' }))
			.max(50, translate('FORM.VALIDATION.INVALID', { name: 'Email' }))
			.required(translate('FORM.VALIDATION.REQUIRED', { name: 'Email' })),
		password: Yup.string()
			.min(3, translate('FORM.VALIDATION.INVALID', { name: 'Password' }))
			.max(50, translate('FORM.VALIDATION.INVALID', { name: 'Password' }))
			.required(translate('FORM.VALIDATION.REQUIRED', { name: 'Password' })),
	})
	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true)
			setTimeout(() => {
				login(values.email, values.password)
					.then((res: any) => {
						setLoading(false)

						dispatch(auth.actions.login(res?.data?.access_token, res?.data?.user))
					})
					.catch(() => {
						setLoading(false)
						setSubmitting(false)
						setStatus('The login detail is incorrect')
					})
			}, 1000)
		},
	})

	return (
		<form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
			{/* begin::Heading */}
			<div className='text-center mb-10'>
				<h1 className='text-dark mb-3'>{translate('AUTH.GENERAL.TITLE')}</h1>
				<div className='text-gray-400 fw-bold fs-4'></div>
			</div>
			{/* begin::Heading */}

			{formik.status && (
				<div className='mb-lg-15 alert alert-danger'>
					<div className='alert-text font-weight-bold'>{formik.status}</div>
				</div>
			)}

			{/* begin::Form group */}
			<div className='fv-row mb-10'>
				<label className='form-label fs-6 fw-bolder text-dark'>{translate('AUTH.INPUT.EMAIL')}</label>
				<input
					placeholder={translate('AUTH.INPUT.EMAIL')}
					{...formik.getFieldProps('email')}
					className={clsx(
						'form-control form-control-lg form-control-solid',
						{ 'is-invalid': formik.touched.email && formik.errors.email },
						{
							'is-valid': formik.touched.email && !formik.errors.email,
						}
					)}
					type='email'
					name='email'
					autoComplete='off'
				/>
				{formik.touched.email && formik.errors.email && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.email}</span>
						</div>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Form group */}
			<div className='fv-row mb-10'>
				<div className='d-flex justify-content-between mt-n5'>
					<div className='d-flex flex-stack mb-2'>
						{/* begin::Label */}
						<label className='form-label fw-bolder text-dark fs-6 mb-0'>{translate('AUTH.INPUT.PASSWORD')}</label>
						{/* end::Label */}
						{/* begin::Link */}
						<Link to='/auth/forgot-password' className='link-primary fs-6 fw-bolder' style={{ marginLeft: '5px' }}>
							{translate('AUTH.FORGOT.TITLE')}
						</Link>
						{/* end::Link */}
					</div>
				</div>
				<input
					type='password'
					placeholder='Password'
					autoComplete='off'
					{...formik.getFieldProps('password')}
					className={clsx(
						'form-control form-control-lg form-control-solid',
						{
							'is-invalid': formik.touched.password && formik.errors.password,
						},
						{
							'is-valid': formik.touched.password && !formik.errors.password,
						}
					)}
				/>
				{formik.touched.password && formik.errors.password && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.password}</span>
						</div>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Action */}
			<div className='text-center'>
				<button type='submit' id='kt_sign_in_submit' className='btn btn-lg btn-primary w-100 mb-5' disabled={formik.isSubmitting || !formik.isValid}>
					{!loading && <span className='indicator-label'>{translate('AUTH.LOGIN.BUTTON')}</span>}
					{loading && (
						<span className='indicator-progress' style={{ display: 'block' }}>
							Please wait...
							<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
						</span>
					)}
				</button>
			</div>
			<div className='text-center'>
				<LanguagesDrop />
			</div>
			{/* end::Action */}
		</form>
	)
}
