import { useIntl } from 'react-intl'

const useTranslate = () => {
	const intl = useIntl()

	const translate = (id: string, selector: any = '') => {
		return intl.formatMessage({ id }, selector)
	}

	return translate
}

export default useTranslate
