import React, { FC } from 'react'
import { toAbsoluteUrl } from '../../../../_theme/helpers'
import { useNavigate } from 'react-router-dom'
import IOrganization from '../../organization/models/OrganizationModel'

type Props = {
	item: IOrganization
}

const OrganizationItem: FC<Props> = ({ item }) => {
	const navigate = useNavigate()
	const nav = () => {
		navigate(`/company/${item.id}`, { replace: true })
	}

	return (
		<>
			<tr onClick={nav} className='cursor-pointer'>
				<td>
					<div className='d-flex align-items-center'>
						<div className='symbol symbol-50px'>
							<span
								className='symbol-label'
								style={{
									backgroundSize: '50px',
									backgroundImage: `url(${item.logo ? item.logo.url : toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
								}}
							></span>
						</div>

						<div className='ms-5'>
							<div className='text-gray-800 text-hover-primary fs-5 fw-bolder' data-kt-ecommerce-product-filter='product_name'>
								{item?.name}
							</div>
						</div>
					</div>
				</td>
				<td className='text-end pe-0 text-purple'>{item.balance.total}</td>
				<td className='text-end pe-0 text-primary'>{item.balance.current}</td>
				<td className='text-end pe-0 text-orange'>{item.balance.tax_for_ta}</td>
				<td className='text-end pe-0 text-success'>{item.balance.withdrew}</td>
				<td className='text-end pe-0 text-pink'>{item.balance.current < 1 ? 0 : item.balance.current}</td>
				<td className='text-end pe-0 text-danger'>{item.balance.refund}</td>
			</tr>
		</>
	)
}

export default OrganizationItem
