export const AddressApi = (instance: any) => ({
	async countries(limit: number = 100, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/country/all?limit=${limit}&page=${page}${q}`)
		return data
	},
	async province(limit: number = 100, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/region/all?limit=${limit}&page=${page}&select=country${q}`)
		return data
	},
	async city(limit: number = 100, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/city/all?limit=${limit}&page=${page}&select=country,region${q}`)
		return data
	},

	async regions(limit: number = 100, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/district/all?limit=${limit}&page=${page}&select=city,addresses${q}`)
		return data
	},

	async villages(limit: number = 100, page: number = 1, q: string = '') {
		const { data } = await instance.get(`/village/all?limit=${limit}&page=${page}&select=city,district,addresses${q}`)
		return data
	},

	async addCountry(body: any) {
		const { data } = await instance.post(`/country/create`, body)
		return data
	},
	async addCity(body: any) {
		const { data } = await instance.post(`/city/create`, body)
		return data
	},
	async addRegion(body: any) {
		const { data } = await instance.post(`/district/create`, body)
		return data
	},
	async addProvince(body: any) {
		const { data } = await instance.post(`/region/create`, body)
		return data
	},
	async addVillage(body: any) {
		const { data } = await instance.post(`/village/create`, body)
		return data
	},

	async updateCountry(id: string | number, body: { title: string }) {
		const { data } = await instance.patch(`/country/update/${id}`, body)
		return data
	},
	async updateCity(id: string | number, body: any) {
		const { data } = await instance.patch(`/city/update/${id}`, body)
		return data
	},
	async updateRegion(id: string | number, body: any) {
		const { data } = await instance.patch(`/district/update/${id}`, body)
		return data
	},
	async updateProvince(id: string | number, body: any) {
		const { data } = await instance.patch(`/region/update/${id}`, body)
		return data
	},
	async updateVillage(id: string | number, body: any) {
		const { data } = await instance.patch(`/village/update/${id}`, body)
		return data
	},
	async createAddress(body: any) {
		const { data } = await instance.post(`/address/create/address`, body)
		return data
	},
})
