import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { ICoordinate } from '../../../../../models/AddressModel'
import { Api } from '../../../../../Services/api'
import DraggableMarker from '../../../../../../_theme/partials/content/DrawPolygon/MapDrag'
import { ModalHeader } from '../../../../../Modals/ModalHeader'
import { setRefresh } from '../../../../../reducers/RefreshSlice'
import useTranslate from '../../../../../../_theme/helpers/translate'

type Props = {
	branch?: any
	update: boolean
	isOpen: boolean
	onClose: any
}

const BranchSchema = Yup.object().shape({
	name: Yup.string().required(),
	status: Yup.string().required(),
	email: Yup.string().email().required(),
	lastname: Yup.string().required(),
	surname: Yup.string().required(),
	phone: Yup.string().required(),
	country_id: Yup.number().required(),
	village_id: Yup.number(),
	street: Yup.string(),
	home_no: Yup.string(),
	city_id: Yup.number(),
	district_id: Yup.number().required(),
	region_id: Yup.number().required(),
	address_title: Yup.string().required(),
})

const AddBranch: FC<Props> = ({ branch, update, isOpen, onClose }) => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const [countries, setCountries] = useState<any>([])
	const [district, setDistrict] = useState<any>([])
	const [currentLocation, setCurrentLocation] = useState<ICoordinate>()
	const [regions, setRegions] = useState<any>([{ id: 1, title: 'Bishkek' }])
	const [cities, setCities] = useState<any>([{ id: 1, title: 'Bishkek' }])
	const [village, setVillage] = useState<any>([])

	useEffect(() => {
		Api()
			.address.countries()
			.then((res) => {
				setCountries(res?.items)
			})
		Api()
			.address.city()
			.then((res) => {
				setCities(res?.items)
			})
		Api()
			.address.province()
			.then((res) => {
				setRegions(res?.items)
			})
		Api()
			.address.regions()
			.then((res) => {
				setDistrict(res?.items)
			})
			.catch((err) => {
				console.log('err', err)
			})
		Api()
			.address.villages()
			.then((res) => {
				setVillage(res?.items)
			})
	}, [])
	const [branchForEdit] = useState({
		name: '',
		status: '',
		email: '',
		lastname: '',
		surname: '',
		phone: '',
		country_id: '',
		city_id: '',
		district_id: '',
		region_id: '',
		village_id: '',
		street: '',
		home_no: '',
		address_title: '',
	})

	useEffect(() => {
		console.log(currentLocation)
	}, [currentLocation])

	const formik = useFormik({
		initialValues: branchForEdit,
		validationSchema: BranchSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)
			const body = {
				name: values.name,
				status: values.status,
				user: {
					email: values.email,
					name: values.name,
					surname: values.surname,
					fullname: values.name + ' ' + values.surname,
					phone: values.phone,
				},
				address: {
					country_id: Number(values.country_id),
					region_id: Number(values.region_id),
					district_id: Number(values.district_id),
					coordinate: {
						longitude: currentLocation?.longitude,
						latitude: currentLocation?.latitude,
					},
					title: values.address_title,
					city_id: values.city_id ? Number(values.city_id) : null,
				},
			}
			Api()
				.organization.createBranch(body)
				.then((res) => {
					dispatch(setRefresh())
					onClose()
				})
		},
	})

	return (
		<Modal size={'lg'} show={isOpen} onHide={onClose}>
			<Modal.Body>
				<ModalHeader handleClose={onClose} titleHeader={'New Branch'} />
				<form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
					{/* begin::Scroll */}
					<div className='d-flex flex-column scroll-y me-n7 pe-7 ' id='kt_modal_add_user_scroll' data-kt-scroll='true' data-kt-scroll-activate='{default: false, lg: true}' data-kt-scroll-max-height='auto' data-kt-scroll-dependencies='#kt_modal_add_user_header' data-kt-scroll-wrappers='#kt_modal_add_user_scroll' data-kt-scroll-offset='300px'>
						{/* begin::Input group */}
						<div className='row'>
							<div className='col mb-7'>
								{/* begin::Label */}
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.TEXT.LOCATE_ME')}</label>
								{/* end::Label */}

								{/* begin::Input */}
								<input
									placeholder='Branch Name'
									{...formik.getFieldProps('name')}
									type='text'
									name='name'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.name && formik.errors.name },
										{
											'is-valid': formik.touched.name && !formik.errors.name,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.name && formik.errors.name && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.name}</span>
										</div>
									</div>
								)}
								{/* end::Input */}
							</div>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('CARD.TEXT.STATUS')}</label>

								<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select status' {...formik.getFieldProps('status')} name='status'>
									<option hidden>Select</option>

									<option value={'rejected'}>{translate('STATUS.TEXT.REJECTED')}</option>
									<option value={'not_working'}>{translate('STATUS.TEXT.NOT_WORKING')}</option>
									<option value={'active'}>{translate('STATUS.TEXT.ACTIVE')}</option>
									<option value={'pending'}>{translate('STATUS.TEXT.PENDING')}</option>
								</select>
								{formik.touched.district_id && formik.errors.district_id && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.district_id}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='row'>
							<div className='col mb-7'>
								{/* begin::Label */}
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.USER_NAME')}</label>
								{/* end::Label */}

								{/* begin::Input */}
								<input
									placeholder='User Name'
									{...formik.getFieldProps('lastname')}
									type='text'
									name='lastname'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.lastname && formik.errors.lastname },
										{
											'is-valid': formik.touched.lastname && !formik.errors.lastname,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.lastname && formik.errors.lastname && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.lastname}</span>
										</div>
									</div>
								)}
								{/* end::Input */}
							</div>
							<div className='col mb-7'>
								{/* begin::Label */}
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.USER_SURNAME')}</label>
								{/* end::Label */}

								{/* begin::Input */}
								<input
									placeholder='User Surname'
									{...formik.getFieldProps('surname')}
									type='text'
									name='surname'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.surname && formik.errors.surname },
										{
											'is-valid': formik.touched.surname && !formik.errors.surname,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.surname && formik.errors.surname && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.surname}</span>
										</div>
									</div>
								)}
								{/* end::Input */}
							</div>
						</div>
						<div className='row'>
							<div className='col mb-7'>
								{/* begin::Label */}
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.EMAIL')}</label>
								{/* end::Label */}

								{/* begin::Input */}
								<input
									placeholder='Email'
									{...formik.getFieldProps('email')}
									type='text'
									name='email'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.email && formik.errors.email },
										{
											'is-valid': formik.touched.email && !formik.errors.email,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.email && formik.errors.email && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.email}</span>
										</div>
									</div>
								)}
								{/* end::Input */}
							</div>
							<div className='col mb-7'>
								{/* begin::Label */}
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.PHONE')}</label>
								{/* end::Label */}

								{/* begin::Input */}
								<input
									placeholder='Phone'
									{...formik.getFieldProps('phone')}
									type='text'
									name='phone'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.phone && formik.errors.phone },
										{
											'is-valid': formik.touched.phone && !formik.errors.phone,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.phone && formik.errors.phone && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.phone}</span>
										</div>
									</div>
								)}
								{/* end::Input */}
							</div>
						</div>

						<div className='row'>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.COUNTRY')}</label>

								<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select Country' {...formik.getFieldProps('country_id')} name='country_id'>
									<option hidden>Select</option>
									{countries?.map((item: any, index: any) => {
										return (
											<option key={index} value={item?.id}>
												{item?.title}
											</option>
										)
									})}
								</select>
								{formik.touched.country_id && formik.errors.country_id && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.country_id}</span>
										</div>
									</div>
								)}
							</div>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.REGION')}</label>

								<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select City' {...formik.getFieldProps('region_id')} name='region_id'>
									<option hidden>Select</option>
									{regions
										?.filter((item: any) => item?.country?.id == formik.values.country_id)
										?.map((item: any, index: any) => {
											return (
												<option key={index} value={item?.id}>
													{item?.title}
												</option>
											)
										})}
								</select>
								{formik.touched.region_id && formik.errors.region_id && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.region_id}</span>
										</div>
									</div>
								)}
							</div>
							<div className='col mb-7'>
								<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.CITY')}</label>

								<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select City' {...formik.getFieldProps('city_id')} name='city_id'>
									<option hidden>Select</option>
									<option value={''}>Null</option>
									{cities
										?.filter((item: any) => item?.country?.id == formik.values.country_id)
										?.map((item: any, index: any) => {
											return (
												<option key={index} value={item?.id}>
													{item?.title}
												</option>
											)
										})}
								</select>
								{formik.touched.city_id && formik.errors.city_id && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.city_id}</span>
										</div>
									</div>
								)}
							</div>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.DISTRICT')}</label>

								<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select district_id' {...formik.getFieldProps('district_id')} name='district_id'>
									<option hidden>Select</option>
									{district
										// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
										?.map((item: any, index: any) => {
											return (
												<option key={index} value={item?.id}>
													{item?.title}
												</option>
											)
										})}
								</select>
								{formik.touched.district_id && formik.errors.district_id && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.district_id}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='row'>
							<div className='col mb-7'>
								<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.VILLAGE')}</label>

								<select className='form-select form-select-solid' data-control='select2' data-hide-search='true' data-placeholder='Select village_id' {...formik.getFieldProps('village_id')} name='village_id'>
									<option hidden>Select</option>
									<option value={''}>Null</option>
									{village
										// ?.filter((item: any) => item?.region?.id == formik.values.region_id)
										?.map((item: any, index: any) => {
											return (
												<option key={index} value={item?.id}>
													{item?.title}
												</option>
											)
										})}
								</select>
								{formik.touched.district_id && formik.errors.district_id && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.district_id}</span>
										</div>
									</div>
								)}
							</div>
							<div className='col mb-7'>
								<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.STREET')}</label>

								<input
									placeholder='Street'
									{...formik.getFieldProps('street')}
									type='text'
									name='street'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.street && formik.errors.street },
										{
											'is-valid': formik.touched.street && !formik.errors.street,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.street && formik.errors.street && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.street}</span>
										</div>
									</div>
								)}
							</div>
							<div className='col mb-7'>
								<label className='fw-bold fs-8 mb-2'>{translate('FORM.INPUT.HOME_NUMBER')}</label>

								<input
									placeholder='Home Number'
									{...formik.getFieldProps('home_no')}
									type='text'
									name='home_no'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.home_no && formik.errors.home_no },
										{
											'is-valid': formik.touched.home_no && !formik.errors.home_no,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.home_no && formik.errors.home_no && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.home_no}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='row'>
							<div className='col mb-7'>
								<label className='required fw-bold fs-8 mb-2'>{translate('FORM.INPUT.ADDRESS')}</label>

								<input
									placeholder='address_title'
									{...formik.getFieldProps('address_title')}
									type='text'
									name='address_title'
									className={clsx(
										'form-control form-control-solid mb-3 mb-lg-0',
										{ 'is-invalid': formik.touched.address_title && formik.errors.address_title },
										{
											'is-valid': formik.touched.address_title && !formik.errors.address_title,
										}
									)}
									autoComplete='off'
								/>
								{formik.touched.address_title && formik.errors.address_title && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											<span role='alert'>{formik.errors.address_title}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div>
							<label className='form-label'>{translate('FORM.INPUT.CURRENT_LOCATION')}</label>
							<DraggableMarker setData={setCurrentLocation} />
						</div>

						<div className='text-center pt-15'>
							<div onClick={onClose} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
								{translate('FORM.BUTTON.CENCEL')}
							</div>

							<button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !formik.isValid || !formik.touched}>
								<span className='indicator-label'>{translate('FORM.BUTTON.SEND')}</span>
								{formik.isSubmitting && (
									<span className='indicator-progress'>
										Loading... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								)}
							</button>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	)
}

export default AddBranch
