import React, { FC } from 'react'
import { toAbsoluteUrl } from '../../../_theme/helpers'
import { ICourier } from '../../models/CourierModal'
import useTranslate from '../../../_theme/helpers/translate'
type Props = {
	item: ICourier
}
const CourierInfo: FC<Props> = ({ item }) => {
	const translate = useTranslate()
	return (
		<div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10 ml-0'>
			<div className='card mb-5 mb-xl-8'>
				<div className='card-body '>
					<div className='d-flex flex-center flex-column py-5'>
						<div className='symbol symbol-100px symbol-circle mb-7'>
							<span
								className='symbol-label'
								style={{
									backgroundImage: `url(${toAbsoluteUrl('/media/avatars/300-1.jpg')})`,
								}}
							></span>
						</div>

						<span className='fs-3 text-gray-800 fw-bolder mb-3'>{item?.name}</span>

						<div className='mb-9'>
							<div className='badge badge-lg badge-light-primary d-inline'>{item?.name}</div>
						</div>
					</div>

					<div className='separator'></div>

					<div id='kt_user_view_details' className='collapse show'>
						<div className='pb-5 fs-6'>
							<div className='fw-bolder mt-5'>{translate('FORM.INPUT.PHONE')}</div>
							<div className='text-gray-600'>{item?.phone}</div>

							<div className='fw-bolder mt-5'>{translate('FORM.INPUT.EMAIL')}</div>
							<div className='text-gray-600'>
								<a href='#' className='text-gray-600 text-hover-primary'>
									{item.email}
								</a>
							</div>
							<div className='fw-bolder mt-5'>{translate('FORM.INPUT.FILIAL')}</div>
							<div className='text-gray-600'>{item?.courier_filial?.name}</div>
							<div className='fw-bolder mt-5'>Company</div>
							<div className='text-gray-600'>{item?.courier_filial?.delivery_company.name}</div>

							{/* <div className='fw-bolder mt-5'>Telefon Numarası</div>
            <div className='text-gray-600'>{item.phone}</div>

            <div className='fw-bolder mt-5'>Tedarikçi Firma Adres Bilgisi</div>
            <div className='text-gray-600'>{item.address}</div>

            <div className='fw-bolder mt-5'>Üretici Firma Ülke</div>
            <div className='text-gray-600'>{item.country.name}</div>

            <div className='fw-bolder mt-5'>Vergi Numarası </div>
            <div className='text-gray-600'>{item.tax_number}</div>
            {item?.irtibat_bilgisi != null && (
              <>
                <div className='fw-bolder mt-5'>İrtibat Bilgisi </div>
                <div className='text-gray-600'>{item.irtibat_bilgisi}</div>
              </>
            )} */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CourierInfo
